/* eslint-disable no-async-promise-executor */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import Grid from "@mui/material/Grid";
import clienteService from '../../../services/ClienteService'; 

export default function SearchAlunos({ isOpen, onClose, infoIdTitular, infoNameTitular }) {
  const autocompleteRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [callClienteLoad, setCallClienteLoad] = useState(false);

  const handleSearch = async () => {    
    //.replace(/[0-9-]/g, '')
    const teste = searchText.replace(/[0-9-]/g, '');
    console.log('teste ', teste.trim());    
    
    await clienteService.getByName(teste.trim()).then((resultClient) => {    
      console.log('resultClient ', resultClient);
                                        
      infoIdTitular(resultClient.data[0].id);              
      infoNameTitular(resultClient.data[0].nomeCliente);      
      onClose();
    })
  };


  const handleCallClientes = async () => {    
    return new Promise(async (resolve, reject) => {
      await clienteService.getAll().then((result) => {                          
        setData(result.data);      
        const arrayInfoTitular = [];
        result.data.map((result, index) => { 
          if (result.contato !== true) {
            //arrayInfoTitular.push(result.nomeCliente)             
            arrayInfoTitular.push(`${result.cpf.replace(/[.-]/g, '')} - ${result.nomeCliente}`)             
          }   
        })
        setCallClienteLoad(true);
        resolve(arrayInfoTitular);
      })      
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch((error) => {      
    })        
  }    

  useEffect(() => {
    let isMounted = true;
  
    if (autocompleteRef.current) {
      autocompleteRef.current.querySelector('#autocom').click();
    }
  
    handleCallClientes()
      .then((result: []) => {
        if (isMounted) {          
          setOptions(result);
          setShowInput(true);
        }
      })
      .catch(erro => {
        if (isMounted) {
          setShowInput(false);
        }
      });  
    
    return () => {
      isMounted = false;
    };
  }, []);

  function getInfoCombo(infoSelected){          
    return new Promise(async (resolve, reject) => {
      try {                       

        const parts = infoSelected.split('-');
        if (parts.length > 0) {
            const cpf = `${parts[0].trim()}-${parts[1].trim()}`;            
            resolve(cpf);
        }else{
          reject([]);
        }
      } catch (error) {
        reject([]);
      }
    })

    
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Consulta Alunos
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Filtre por cpf ou nome do Aluno(a)
        </DialogContentText>        
          <div style={{width: "500px", marginTop:"14px"}}>
          </div>
            {callClienteLoad && (
              <Autocomplete
                id='autocom'                
                ref={autocompleteRef}
                value={searchText}
                onChange={(event, newValue) => {setSearchText(newValue ? newValue : '')}}            
                options={options ? options : []}
                renderInput={(params) => <TextField {...params} label="Name" id="autocom" />}
              />
            )}                  
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSearch} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}