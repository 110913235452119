let sum;
let rest;
class CpfValidation {    
  GetValidationCpf = (number) =>
  {      
    sum = 0;
    if (        
      number != "00000000000" ||
      number != "11111111111" ||
      number != "22222222222" ||
      number != "33333333333" ||
      number != "44444444444" ||
      number != "55555555555" ||
      number != "66666666666" ||
      number != "77777777777" ||
      number != "88888888888" ||
      number != "99999999999" 
    ) 
    {
      if (number.length == 11){
        if (
          
          number == "00000000000" ||
          number == "11111111111" ||
          number == "22222222222" ||
          number == "33333333333" ||
          number == "44444444444" ||
          number == "55555555555" ||
          number == "66666666666" ||
          number == "77777777777" ||
          number == "88888888888" ||
          number == "99999999999" 
        ) 
        {
          return false
        } 
        else
        {
          for (var i=1; i<=9; i++) sum = sum + parseInt(number.substring(i-1, i)) * (11 - i);
          rest = (sum * 10) % 11;

          if ((rest == 10) || (rest == 11))  rest = 0;
          if (rest != parseInt(number.substring(9, 10))) return false;      

          sum = 0;
          for (var inc = 1; inc <= 10; inc++) sum = sum + parseInt(number.substring(inc-1, inc)) * (12 - inc);
          rest = (sum * 10) % 11;

          if ((rest == 10) || (rest == 11))  rest = 0;
          if (rest != parseInt(number.substring(10, 11) ) )  return false;

          return true;  
        }

      }else{
        return false
      }
    }else{
      return false;
    }  

  }
 
    
} 
export default CpfValidation;