import * as React from 'react';
import DashboardLayout from '../../../shared/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../shared/Navbars/DashboardNavbar';
import ContratacaoForm from './ContratacaoForm';

function ListScreen() {  
  return (    
    <DashboardLayout>
      <DashboardNavbar />     
      <ContratacaoForm />          
    </DashboardLayout>    
  );
}

export default ListScreen;