/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import React, { useEffect, useState } from "react";

import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import IconInativa from '@mui/icons-material/PersonRemove';
import IconEdit from '@mui/icons-material/Edit';
import clienteService from '../../../../../services/ClienteService';
import baseService from "services/BaseService";
import BaseService from "services/BaseService";
import { isExpired } from "react-jwt";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

export default function data(valueSearch, typeBotton, objFull, idCliente) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowsInfo, setRowsInfo] = useState([]);
  const [columns, setColummns] = useState([]);
  const [objListRender, setObjListRender] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [dataReturn, setDataReturn] = useState([]);
  
  

  const [teste, setTeste] = useState(valueSearch);
  
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Step 3
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

    
  const handleFilterData = (search, infoReturn) => {    
    let newInfoReturn = infoReturn.filter(x => x.nome.toLowerCase().includes(search.toLowerCase()));    
    
    objFull(newInfoReturn); 
    handlePrepareRows(newInfoReturn);
  }

  function handleFormatDataCadastro(value) {
    const [datePart] = value.split('T');
    const [year, month, day] = datePart.split('-');

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;

  }
  
  const handlePrepareRows = (infoReturn) => {
    let arrayRows = [];
    infoReturn.map((result) => {
      let itemObj = {
        nome:  (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.nome}
          </MDTypography>
        ),        
        
        dataCadastro: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {handleFormatDataCadastro(result.dataCadastro)}
          </MDTypography>
        ), 
        
        action: (
          <>
            {window.innerWidth < 768 ?
              (
                <MDBox>
                  <MDButton color={'info'} circular={true} size={'small'} iconOnly={true}
                    onClick={() => {typeBotton('Editar')}}
                  >                
                      <VisibilityIcon />
                  </MDButton>                              
                </MDBox> 
              ):(
                <MDBox>
                  <Tooltip title="Clique para visualizar" arrow placement="left">
                    <MDButton color={'info'} circular={true} size={'small'} 
                      onClick={() => {typeBotton('Editar')}}
                    >                
                        <VisibilityIcon />
                    </MDButton>            
                  </Tooltip>                  
                </MDBox> 
              )
            }
          </>
        )
        
      }

      arrayRows.push(itemObj);
    })    
    setRowsInfo(arrayRows);
  }

  const handlePrepareColumms = () => {
    var columns = [];
    
    columns = [
      { Header: "nome", accessor: "nome", width: "20%", align: "left" },                           
      { Header: "dataCadastro", accessor: "dataCadastro", align: "center" },
      { Header: "Ação", accessor: "action", align: "center" }
    ]        
   
    
    setColummns(columns);
  }

  useEffect(() => {       
    if (dataReturn.length > 0)  {      
      handlePrepareRows(dataReturn);
      handlePrepareColumms();
    }    
  }, [dataReturn])

  function updateTokenInHeaders(newToken) {
    baseService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  }  
  useEffect(async () => { 
    var isExpiredInfo = isExpired(localStorage.getItem('token'));
    if (!isExpiredInfo){
      await clienteService.getAnamneseClientById(idCliente).then((result) => {                     
        setDataReturn(result.data); 
        objFull(result.data);  
      }).catch((error) => {      

      })
    }       
  }, [])

  useEffect(() => {    
    setTimeout(() => {  
      if (valueSearch != null){
        handleFilterData(valueSearch, dataReturn);
      }             
    }, 2000);   
    
  }, [valueSearch])

  useEffect(() => { 
  }, [valueSearch])


  useEffect(() => {    
  }, [teste])

  return {
    columns: columns,

    rows: rowsInfo
  };
}
