/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate   } from "react-router-dom";
import Button from '@mui/material/Button';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "./data/PlanosTableData";
import projectsTableData from "./data/projectsTableData";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import "./index.css";
import { Box } from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import { decodeToken, isExpired } from "react-jwt";
import { Alert, Modal, Snackbar, Typography } from "@mui/material";
import { ReAuth } from "layouts/authentication/reAuth";
import { verifyRolesAcess } from "../../services/Utils/verifyRolesAcess";
import clienteService from "../../services/ClienteService";
import funcionarioService from "../../services/FuncionarioService";
import planoService from "../../services/PlanoService";

const useStyles = makeStyles((theme) => ({
  container: {    
    flexDirection: 'row',    
  },
  item: {
    width: 100,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignEndItem: {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  },
  boxFilter : {
    flexDirection: 'row', marginTop: 5, flex: 1, height: '20px'
  }
}));

const customInputStyle = {
  width: '100%',
  padding: 26,
  marginTop: -16 
};


function Planos() {    

  const [searchFilter, setSearchFilter] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);  
  const [typeBotton, setTypeBotton] = useState('');   
  const [objFull, setObjFull] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [invalidPermission, setInvalidPermission] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [idInativar, setIdInativar] = useState([]);
  const [alertError, setAlertError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 
  const handleTypeBotton = (rowIndex) => {
    setTypeBotton(rowIndex);
  };

  const handleObjFull = (obj) => {    
    setObjFull(obj);
  }  

  const navigate = useNavigate();
  const classes = useStyles();    
  const { columns, rows } = authorsTableData(searchFilter, handleTypeBotton, handleObjFull);                

  const handlePrepareData = (nameFilter) => {    
    setSearchFilter(nameFilter);    
  }    
  
  const handleFilterEditar = async () => {            
    var rangeInfo = currentPage * 5;    
    var newObj = [];
    
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    })             
    
    navigate(`/planos/cadPlanos/?id=${newObj[clickedRowIndex].id}`);            
  }  
  
  function formatDateInfos(inputDate){      
    
    var dateSplit = inputDate.split('/');    
    var formattedDate = new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1]);
    
    var year = formattedDate.getFullYear();
    var month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    var day = String(formattedDate.getDate()).padStart(2, '0');

    
    var dateFormat = year + '-' + month + '-' + day;
    return dateFormat;    
  } 

  const handleInativar = () => {
    var rangeInfo = currentPage * 5;    
    var newObj = [];
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    })   
    
    var dateObject = new Date(newObj[0].dataNascimento);
    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1;
    var year = dateObject.getFullYear();
    var formattedDate = day + "/" + month + "/" + year;    

    newObj[0].MensagemRetorno = "Formulario Planos";            
    newObj[0].ativo = false;               

    setIdInativar(newObj[clickedRowIndex].id);
    handleOpen();    
  }

  const handleRowClick = (rowIndex) => {
    setClickedRowIndex(rowIndex);
  };    

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {    
    if (typeBotton === 'Editar'){
      handleFilterEditar();      
      setTypeBotton('');
    }else if (typeBotton === 'Inativar'){
      handleInativar();
      setTypeBotton(''); 
    }
  }, [typeBotton]);    

  useEffect(async () => {    
    var invalidPermission = await verifyRolesAcess('funcionarios');        
    setInvalidPermission(!invalidPermission);

    var isExpiredInfo = isExpired(localStorage.getItem('token'));    
    setTokenExpired(isExpiredInfo); 
  }, [])

  const handleReAuthInfo = (value) => {
    var isExpiredInfo = isExpired(localStorage.getItem('token'))
    setTokenExpired(isExpiredInfo); 
    
    window.location.reload();
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FFF',
    border: '4px solid #8E3295',
    boxShadow: 24,
    p: 4,
  };


  const handleConfirmInativar = async () => {    
    const result = await planoService.putInativePlano(idInativar, idInativar.id)

    if (result.sucesso){      
      setOpen(false);
      setAlertSuccess(true);
      setMsgSuccess(result.data.mensagemRetorno)      
    }else{
      setOpen(false);
      setAlertError(true);
      setMsgError(result.data.mensagemRetorno);
    }
  }

  const handleCloseSnak = () => {
    setTimeout(() => {
      setAlertError(false);
      setAlertSuccess(false);
      window.location.reload();      
    }, 1500);
  }

  return (        
    <DashboardLayout>        
      {alertSuccess ? (
        <>
          <Snackbar open={{open}} autoHideDuration={1500} onClose={()=> {handleCloseSnak()}}>
            <Alert onClose={()=> {handleCloseSnak()}} severity="success" sx={{ width: '100%' }}>
              Funcionário inativado com sucesso!
            </Alert>
          </Snackbar>
        </>
      ):(
        <>
        {alertError && (
          <Snackbar open={{open}} autoHideDuration={1500} onClose={()=>{handleCloseSnak()}}>
            <Alert onClose={()=>{handleCloseSnak()}} severity="success" sx={{ width: '100%' }}>
              {msgError}
            </Alert>
          </Snackbar>
        )}
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Deseja inativar o Plano ?
          </MDTypography>
          <Button id="modal-modal-description" sx={{ mt: 2 }}
            onClick={() => {handleConfirmInativar()}}
          >
            Sim
          </Button>
          <Button id="modal-modal-description" sx={{ mt: 2 }}
             onClick={() => {handleClose()}}
          >
            Não
          </Button>
        </Box>
      </Modal>
       {tokenExpired ? (
        <>
          <ReAuth handleAuth={handleReAuthInfo} />
        </>
      ):(
        <>        
          {invalidPermission ? (
          <>
            <Alert severity="error">
              Você não tem permissão para acessar esse recurso.
            </Alert>
          </>
          ): (
            <>
              <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          mt={-3}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          flexDirection="row"
                        >
                          <Box> 
                            <MDTypography variant="h6" color={"white"} flexDirection={"column"} ml={"10px"}>
                              Planos
                            </MDTypography>                          
                          </Box>                 
                        </MDBox>
                        <div className={classes.boxFilter}>                  
                            <MDInput style={customInputStyle} onChange={(event) => {handlePrepareData(event.target.value)}}                 
                              placeholder="Filtrar"
                            >
                            </MDInput>                   
                        </div>                                                             
                        <Grid item xs={12} pl={2}>                               
                          <DataTable
                            table={{columns, rows}}
                            isSorted={false}
                            entriesPerPage={true}
                            showTotalEntries={false}
                            noEndBorder 
                            onRowClick={handleRowClick} 
                            pageNumber={handlePageChange}                                    
                          />                                
                        </Grid>
                        
                        <Box mt={0}>                                
                        </Box>
                        <Link to={"/planos/cadPlanos"} className={classes.alignEndItem}>
                          <Button style={{width: 50, height: 50, backgroundColor: '#FFF', borderRadius: 50}}>
                              <AddCircleRoundedIcon style={{width: 40, height: 40, color: "#ba79e1"}}/>                      
                          </Button>
                      </Link>
                      </Card>

                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                      
                      </Card>
                    </Grid>
                  </Grid>
              </MDBox>      
            </>
          )}
        </>  
      )}     
      
    </DashboardLayout>
  );
}

export default Planos;
