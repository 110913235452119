export const ConvertDataPadrao = (inputDate) => {
  const originalDate = new Date(inputDate);
  const day = originalDate.getDate();
  const month = originalDate.getMonth() + 1;
  const year = originalDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  console.log('formattedDate ', formattedDate);
  

  return formattedDate;
};