import * as React from 'react';
import DashboardLayout from '../../../shared/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../shared/Navbars/DashboardNavbar';
import TitleScreenCrud from '../../../shared/TitleScreenCrud';
import CadTurmaForm from './CadTurmaForm'
import AvisoSnack from '../../../../layouts/shared/AvisoSnack';
import { IResultDTO } from '../../../../layouts/turma/TurmaType';

function ListScreen() {  
  const[dataInfoCadTurma, setDataInfoCadTurma] = React.useState<IResultDTO>(null);  

  const handleCBSalvarTurma = (info : IResultDTO) => {    
    setDataInfoCadTurma(info);       
  }  
  
  return (    
    <DashboardLayout>
      <DashboardNavbar /> 
      <TitleScreenCrud title='Cadastro de Turma'>     
        <CadTurmaForm onRetornoSalvar={handleCBSalvarTurma} />
      </TitleScreenCrud>   
      {dataInfoCadTurma !== null && <AvisoSnack goBackScreen='turmas' sucesso={dataInfoCadTurma.sucesso} mensagem={dataInfoCadTurma.mensagem} />}         
    </DashboardLayout>    
  );
}

export default ListScreen;