import BaseService from '../../../infrastructure/services';
import { Planos, PlanosAtividades, PlanosNomeId } from './SearchPlanosType';

export const getAllPlanos = async (): Promise<PlanosAtividades[]> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Plano/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);
  const responseData: PlanosAtividades[] = response.data;

  return responseData;
}

export const getByNomePlano = async (nome: string): Promise<Planos[]> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Plano/ObterPorDescricao?descricao=${nome}`, customConfig);  
  
  const responseData: Planos[] = response.data;

  return responseData;
}
