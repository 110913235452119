
import { addMonths, format } from 'date-fns';

export const SomarMesesDataAtual = (qtdMeses) => {
  const dataSoma = addMonths(new Date(), qtdMeses);

  const ano = dataSoma.getFullYear();
  const mes = String(dataSoma.getMonth() + 1).padStart(2, '0');
  const dia = String(dataSoma.getDate()).padStart(2, '0');  
  return `${ano}-${mes}-${dia}`  
};