/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Link, useNavigate   } from 'react-router-dom';
import Button from '@mui/material/Button';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';

// Data
import clientesTableData from './data/clientesTableData';
import projectsTableData from './data/projectsTableData';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from 'components/MDButton';
import './index.css';
import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useEffect, useRef, useState } from 'react';
import MDBadge from 'components/MDBadge';
import MDInput from 'components/MDInput';
import { isExpired } from 'react-jwt';
import { ReAuth } from 'layouts/authentication/reAuth';
import { verifyRolesAcess } from '../../services/Utils/verifyRolesAcess';
import { Alert, Modal, Snackbar } from '@mui/material';
import clienteService from '../../services/ClienteService';

const useStyles = makeStyles((theme) => ({
  container: {    
    flexDirection: 'row',    
  },
  item: {
    width: 100,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignEndItem: {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  },
  boxFilter : {
    flexDirection: 'row', marginTop: 5, flex: 1, height: '20px'
  }
}));

const customInputStyle = {
  width: '100%',
  padding: 26,
  marginTop: -16 
};


function Clientes() {    
  const [searchFilter, setSearchFilter] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);  
  const [typeBotton, setTypeBotton] = useState('');   
  const [objFull, setObjFull] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [invalidPermission, setInvalidPermission] = useState(false);
  const [idInativar, setIdInativar] = useState([]);
  const [alertError, setAlertError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const queryParams = new URLSearchParams(location.search);
  const telefoneInfoSearch = queryParams.get('telefone');  

  const inputRef = useRef(null);

  const handleTypeBotton = (rowIndex) => {
    setTypeBotton(rowIndex);
  };

  const handleObjFull = (obj) => {    
    setObjFull(obj);
  };  

  const navigate = useNavigate();
  const classes = useStyles();    
  const { columns, rows } = clientesTableData(searchFilter, handleTypeBotton, handleObjFull);                

  const handlePrepareData = (nameFilter) => {    
    setSearchFilter(nameFilter);    
  };    
  
  const handleFilterEditar = async () => {            
    var rangeInfo = currentPage * 5;    
    var newObj = [];
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    });        
        
    navigate(`/clientes/cadClientes/?infoId=${JSON.stringify(newObj[clickedRowIndex].id)}`);            
  };   
  
  const handleInativar = () => {
    var rangeInfo = currentPage * 5;    
    var newObj = [];
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    });      

    newObj[clickedRowIndex].ativo = false;  
    newObj[clickedRowIndex].nome = newObj[clickedRowIndex].nomeCliente;  
    
    setIdInativar(newObj[clickedRowIndex]);
    handleOpen();    
  };

  const handleRowClick = (rowIndex) => {
    setClickedRowIndex(rowIndex);
  };    

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleReAuthInfo = (value) => {
    var isExpiredInfo = isExpired(localStorage.getItem('token'));
    setTokenExpired(isExpiredInfo);     
    
    window.location.reload();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FFF',
    border: '4px solid #8E3295',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {    
    if (typeBotton === 'Editar'){
      handleFilterEditar();      
      setTypeBotton('');
    }else if (typeBotton === 'Inativar'){
      handleInativar();
      setTypeBotton(''); 
    }
  }, [typeBotton]);     

  useEffect(async () => {       
    var invalidPermission = await verifyRolesAcess('clientes');    
    setInvalidPermission(!invalidPermission);

    var isExpiredInfo = isExpired(localStorage.getItem('token'));    
    setTokenExpired(isExpiredInfo);     
  }, []);


  const handleConfirmInativar = async () => {      
    const result = await clienteService.putCliente(idInativar, idInativar.id);    

    if (result.sucesso){
      setOpen(false);
      setAlertSuccess(true);
      setMsgSuccess(result.data.mensagemRetorno);      
    }else{
      setOpen(false);
      setAlertError(true);
      setMsgError(result.data.mensagemRetorno);
    }
  }; 
  
  const handleCloseSnak = () => {
    setTimeout(() => {
      setAlertError(false);
      setAlertSuccess(false);
      window.location.reload();      
    }, 1500);
  };

  return (        
    <DashboardLayout>  
      {alertSuccess ? (
        <>
          <Snackbar open={{}} autoHideDuration={1500} onClose={()=> {handleCloseSnak();}}>
            <Alert onClose={()=> {handleCloseSnak();}} severity="success" sx={{ width: '100%' }}>
              Cliente inativado com sucesso!
            </Alert>
          </Snackbar>
        </>
      ):(
        <>
          {alertError && (
            <Snackbar open={{ open }} autoHideDuration={1500} onClose={()=>{handleCloseSnak();}}>
              <Alert onClose={()=>{handleCloseSnak();}} severity="success" sx={{ width: '100%' }}>
                {msgError}
              </Alert>
            </Snackbar>
          )}
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
              Deseja inativar o Cliente ?
          </MDTypography>
          <Button id="modal-modal-description" sx={{ mt: 2 }}
            onClick={() => {handleConfirmInativar();}}
          >
              Sim
          </Button>
          <Button id="modal-modal-description" sx={{ mt: 2 }}
            onClick={() => {handleClose();}}
          >
              Não
          </Button>
        </Box>
      </Modal>   
      <DashboardNavbar />
      {tokenExpired ? (
        <>
          <ReAuth handleAuth={handleReAuthInfo} />
        </>
      ):(<>
        {invalidPermission ? (
          <>
            <Alert severity="error">
              Você não tem permissão para acessar esse recurso.
            </Alert>
          </> 
        ):(
          <>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      flexDirection="row"
                    >
                      <Box> 
                        <MDTypography variant="h6" color={'white'} flexDirection={'column'} ml={'10px'}>
                          Cadastro de Clientes
                        </MDTypography>                          
                      </Box>                 
                    </MDBox>
                    <div className={classes.boxFilter}>                  
                      <MDInput ref={inputRef} style={customInputStyle} onChange={(event) => {handlePrepareData(event.target.value);}}                 
                        placeholder="Filtrar"
                      >
                      </MDInput>                   
                    </div>                                                             
                    <Grid item xs={12} pl={2}>                               
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={true}
                        showTotalEntries={false}
                        noEndBorder 
                        onRowClick={handleRowClick} 
                        pageNumber={handlePageChange}                                    
                      />                                
                    </Grid>                                        
                    <Link to={'/clientes/cadClientes'} className={classes.alignEndItem}>
                      <Button style={{ width: 50, height: 50, backgroundColor: '#FFF', borderRadius: 50 }}>
                        <AddCircleRoundedIcon style={{ width: 40, height: 40, color: '#ba79e1' }}/>                      
                      </Button>
                    </Link>
                  </Card>

                </Grid>                
              </Grid>
            </MDBox>      
          </>
        )}
      </>)}
    </DashboardLayout>
  );
}

export default Clientes;
