/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "../../../shared/MDTypography";
import * as React from 'react';


import { getAll } from '../../TurmaService';
import { IResultDTO, ITurmaGetType } from "../../TurmaType";
import MDBox from '../../../shared/MDBox';
import MDButton from '../../../shared/MDButton';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Tooltip } from '@material-ui/core';
import IconInativa from '@mui/icons-material/PersonRemove';
import IconEdit from '@mui/icons-material/Edit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';''

export default function data(valueSearch, typeBotton, objFull) {  
  const [rowsInfo, setRowsInfo] = React.useState([]);
  const [columns, setColummns] = React.useState([]);
  const [dataReturn, setDataReturn] = React.useState<IResultDTO>(null);


  const handlePrepareRows = (infoReturn: IResultDTO) => {            
    const arrayRows = [];
    const arrayListaTurmas = [];
    
    if (infoReturn.data !== null){            
      infoReturn.data.map((result) => {               
        const descricao = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.descricao}          
          </MDTypography>
        );        
  
        const atividadeName = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="light">
            {result.atividadeName}
          </MDTypography>
        )
  
        const qtdAlunos = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.qtdAlunos}
          </MDTypography>
        )
        
        const status = (                                    
          <MDBox ml={-1}>
            {result.ativo ? (
              <PersonRoundedIcon fontSize="medium" style={{color: 'green'}}/>              
            ):(
              <PersonRoundedIcon fontSize="medium" style={{color: 'red'}}/>              
            )}            
          </MDBox>                                
        )

        const action = (
          <MDBox>
            <Tooltip title="Clique para editar" arrow placement="left">
              <MDButton color={'info'} circular={true} size={'small'} 
                onClick={() => {typeBotton('Editar')}}
              >                
                <IconEdit />          
              </MDButton>            
            </Tooltip>
            <div style={{marginTop: 5}} />
            <Tooltip title="Clique para inativar" arrow placement="left">
              <MDButton color={'info'} circular={true} size={'small'} ml={1}                  
                onClick={() => {typeBotton('Inativar')}}
              >                
                  <IconInativa />            
              </MDButton>
            </Tooltip>
            <div style={{marginTop: 5}} />
            <Tooltip title="Clique para Detalhar a turma" arrow placement="left">
              <MDButton color={'info'} circular={true} size={'small'} ml={1}                  
                onClick={() => {typeBotton('Detalhe')}}
              >                
                  <DragIndicatorIcon />            
              </MDButton>
            </Tooltip>            
          </MDBox> 
        )
      
        const itemObj = {          
          descricao: descricao,
          atividadeName: atividadeName,
          qtdAlunos: qtdAlunos,   
          status: status,   
          action: action,
        }                               
  
        arrayRows.push(itemObj);   
        arrayListaTurmas.push(result);                             
      });
      
      setRowsInfo(arrayRows);
      objFull(arrayListaTurmas);       
    }
  }

  const handlePrepareColumms = () => {
    let columns = [];    

    columns = [      
      { Header: "Nome Turma", accessor: "descricao" },      
      { Header: "Nome Atividade", accessor: "atividadeName" },      
      { Header: "Qtd. Alunos por horário", accessor: "qtdAlunos", align: 'center' },                  
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Ação", accessor: "action", align: "center" }
    ]            
    
    setColummns(columns);
  }

  
  const handleGetAll = async () => {
    try {
      const result = await getAll();
      const data = result;
      setDataReturn(data);
  
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }
  

  React.useEffect(() => {        
    handleGetAll();
  }, [])

  React.useEffect(() => {                  
    if (dataReturn !== null)  {                           
      handlePrepareRows(dataReturn);
      handlePrepareColumms();
    }    
  }, [dataReturn])

  return {    

    columns: columns,
    rows: rowsInfo
  };
}
