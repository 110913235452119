import Modal from '@mui/material/Modal';
import { styled } from "@mui/material/styles";

export default styled(Modal)(({ theme, ownerState }) => {
    const { color, variant, size, circular, iconOnly, darkMode } = ownerState;

    const defaultOnlyStyles = () => {
        let sizeValue = 400;

        return {
            width: sizeValue,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',            
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }                    
    }

    return {
        ...(variant === "contained" && defaultOnlyStyles()),
    }

})