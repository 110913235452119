import { Alert, ButtonBase, Grid, Snackbar, TextField } from '@mui/material';
import * as React from 'react';
import { Button, Card } from '@material-ui/core';
import SearchAtividades from '../../../../layouts/shared/SearchAtividades/SearchAtividades';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import TituloDivisor from '../../../../layouts/shared/TituloDivisor';
import MDBox from '../../../shared/MDBox';
import MDTypography from '../../../shared/MDTypography'
import { getTurmaById, Cadastrar, Atualizar, Deletar, getByIdAtividade } from '../../../../layouts/cadTurma/CadTurmaService';
import { IResultDTO, IAtividadesGetType, IHorariosAtividadeGetType, IHorariosTurmaGetType } from '../../../../layouts/cadTurma/CadTurmaType';
import { IAtividadesGetTypeSearch } from '../../../../layouts/shared/SearchAtividades/SearchAtividadesType';
import { useNavigate } from "react-router-dom";
import { Schedule } from '@material-ui/icons';
import GridAtividadesSelecao from '../../../shared/GridAtividadesSelecao';
import { IResultGridAtividadesSelecao } from '../../../../layouts/shared/GridAtividadesSelecao/GridAtividadesSelecaoType';

const handleSubmitContratacao = async (event) => {
  event.preventDefault();   
};

interface CadTurmaFormProps {
  onRetornoSalvar: (result : IResultDTO) => void;  
}

export default function CadTurmaForm({onRetornoSalvar} : CadTurmaFormProps) {      
  const [descricao, setDescricao] = React.useState('');
  const [atividadeName, setAtividadeName] = React.useState('');
  const [atividadeId, setAtividadeId] = React.useState('');
  const [qtdAlunos, setQtdAlunos] = React.useState('');
  const [atividadeSearchModalOpen, setAtividadeSearchModalOpen] = React.useState(false);  
  const [resultDTO, setResultDTO] = React.useState<IResultDTO>(null);  
  const [resultAtividade, setResultAtividade] = React.useState([]);
  const [horarioSelecionados, setHorariosSelecionados] = React.useState<IHorariosAtividadeGetType[]>(null);
  const [selectedTime, setSelectedTime] = React.useState('');
  const [selectedTime2, setSelectedTime2] = React.useState('');
  const [errorDescricao, setErrorDescricao] = React.useState(false);
  const [errorTimes, setErrorTimes] = React.useState(false);
  const [errorTimesBeginDiff, setErrorTimesBeginDiff] = React.useState(false);  
  const [errorEqualTime, setErrorEqualTime] = React.useState(false);
  
  const [resultDTOTurmaEdit, setResultDTOTurmaEdit] = React.useState<IResultDTO>();
  const [resultGridAtividadeData, setResultGridAtividadeData] = React.useState<IHorariosTurmaGetType[]>();
  const [idAtividades, setIdAtividades] = React.useState('');

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id'); 
  
    
    const atualizarResultGridAtividade = (value : IHorariosTurmaGetType[]) => {                   
      console.log('value result grid horarios', value);
      
      setResultGridAtividadeData(value);
    };

  const handleModalClose = () => {
    setAtividadeSearchModalOpen(false);
  };

  const handleCBInfos = (infoAtividade: IAtividadesGetTypeSearch[]): void => {     
    setIdAtividades(infoAtividade[0].id);      
    setAtividadeSearchModalOpen(false);
  };

  const handleDeleteAtividade = () => {       
    setAtividadeId('');
    setAtividadeName('');
    setResultAtividade([]);
    setAtividadeSearchModalOpen(false);                  
  }  

  const handleSubmitTurma = () => {   
    console.log('id handleSubmitTurma', id);    
      
    if (id !== null){                        
      const obj = {
        Id: id.replace(/['"]/g, ''),
        Descricao: descricao,
        AtividadeId: idAtividades.replace(/['"]/g, ''),
        QtdAlunos: parseInt(qtdAlunos, 10),             
        HorariosAtividade: resultGridAtividadeData,              
      }          
    
      Atualizar(obj)
        .then((result) => {
          onRetornoSalvar(result)  
      })
    } else {
      const horarioDe  = `${selectedTime}:00`
      const horarioAte = `${selectedTime2}:00`
    
      const obj = {
        Descricao: descricao,
        AtividadeId: idAtividades,
        QtdAlunos: parseInt(qtdAlunos, 10),
        HorariosAtividade: resultGridAtividadeData,              
      } 
      
      console.log('obj ', JSON.stringify(obj));
      
    
      Cadastrar(obj)
        .then((result) => {   
          onRetornoSalvar(result)
      })
    }
                 
  }

  const pesquisaAtividades = (statusModal: boolean) => {
    if (statusModal){
      return (
        <SearchAtividades
          isOpen={atividadeSearchModalOpen}
          onClose={handleModalClose}
          callBackInfos={handleCBInfos}
        />
      );
    } 
  }

  const renderSalvar = () => {        
    return (
      <Grid item xl={12} pt={4} pb={4}>                      
        <Button variant="contained" color="primary" fullWidth
          onClick={() => {handleSubmitTurma()}}
        >
          Salvar
        </Button> 
      </Grid>
    )
  } 

  async function processResults(resultDTO : IResultDTO) {
    await Promise.all(resultDTO.data.map(async (result) => {
      await Promise.all(result.horariosAtividade.map(async (result) => {
        setResultAtividade(existingList => {
          const horariosToAdd = result || [];
          return [...existingList, horariosToAdd];
        });
      }));             
      
      setAtividadeId(resultDTO.data[0].atividadeId);
      setIdAtividades(resultDTO.data[0].atividadeId);
      setDescricao(resultDTO.data[0].descricao);
      setAtividadeName(resultDTO.data[0].atividadeName);      
      setQtdAlunos(resultDTO.data[0].qtdAlunos.toString());
    }));      
  }
  
  React.useEffect(() => {       
    if (id != null && id != ''){           
      getTurmaById(id.replace(/"/g, ''))
        .then((result) => {  
          processResults(result);
          setResultDTOTurmaEdit(result);
        })      
    }    
  }, [])        
  
  const renderAtividades = (idAtividade: string) => {            
    if (idAtividade !== ''){      
      return (
         <GridAtividadesSelecao           
            id={id} 
            idAtividades={idAtividade}
            callBackAtividadesSelecionadas={atualizarResultGridAtividade}                     
            typeCadastroTurma={true}
         />   
      );
    } 
  }

  return(    
    <form onSubmit={(event) => {handleSubmitContratacao(event)}}>        
      <Grid container spacing={2} p={4}>       
        <Grid item xs={10} sm={10}>
          <TextField
            label="Nome da Turma"
            name="descricao"   
            fullWidth         
            value={descricao}  
            onChange={(event) => {setDescricao(event.target.value)}}            
          />
        </Grid>
 
        {pesquisaAtividades(atividadeSearchModalOpen)}                          
        <Grid item xs={6} sm={8}>
          <TextField
            label="Selecione a Atividade"
            name="Atividades"
            value={atividadeName}              
            fullWidth                              
            disabled
          />
        </Grid>
        <Grid item xs={3} sm={1} mt={0}>
          <Button
            variant="contained" color={'default'}
              onClick={() => {setAtividadeSearchModalOpen(!atividadeSearchModalOpen)}}
            >
            <SearchIcon fontSize="small"/>  
          </Button>                                
        </Grid>         
        <Grid item xs={3} sm={1} mt={0}>
          <Button
          variant="contained" color={'default'}
            onClick={handleDeleteAtividade}
          >
            <DeleteIcon fontSize="small"/>
          </Button>
        </Grid> 

        { renderAtividades(idAtividades) }

        <Grid item xs={12} sm={6} mt={2}>                                                          
          <TextField
            label="Quantidade de Alunos por Hora"
            name="qtdAlunos"
            value={qtdAlunos}
            onChange={(event) => {  
              const inputValue = event.target.value;
              // Verificar se o valor inserido é um número
              if (/^\d*$/.test(inputValue)) {
                setQtdAlunos(inputValue);
              }                                    
            }}
            required
            fullWidth   
            type="text"                           
            inputProps={{
              pattern: "[0-9]*",
            }}
          />          
        </Grid>                                         

        {renderSalvar()}           
      </Grid>             
    </form>                             
  )
}
