/* eslint-disable no-async-promise-executor */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import Grid from "@mui/material/Grid";
import clienteService from '../../services/ClienteService'; 

export function MDSearch({ isOpen, onClose, onSearch, infoIdTitular, infoNameTitular }) {
  const autocompleteRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [callClienteLoad, setCallClienteLoad] = useState(false);

  const handleSearch = () => {  
      console.log('entrouu');     
      getInfoCombo(searchText).then(async (result) => {
        console.log('result ', result);    
        await clienteService.getByCpf(result).then((resultClient) => {
          console.log('resultClient ', resultClient);      
          //onSearch(resultClient);               
          infoIdTitular(resultClient.data.id);        
          infoNameTitular(resultClient.data.nomeCliente);
          onClose();
        })                   
      })   
      /*getInfoCombo(searchText).then(async (result) =>{   
        console.log('result ', result);                       
      await clienteService.getByCpf(result).then((resultClient) => {      
        onSearch(resultClient);     
        console.log('resultClient.data.id ', resultClient.data.id);         
        infoIdTitular(resultClient.data.id);        
        infoNameTitular(resultClient.data.nomeCliente);
        onClose();
      })
    }).catch((error) => {
      setSearchText('');
      infoIdTitular('');
      onClose();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch((error) => {})*/
  };


  const handleCallClientes = async () => {    
    return new Promise(async (resolve, reject) => {
      await clienteService.getAll().then((result) => {  
        var resultFilterTest = result.data.filter(x => x.ativo === true);        
        setData(result.data);      
        var arrayInfoTitular = [];
        result.data.map((result, index) => {        
          arrayInfoTitular.push(`${result.cpf} - ${result.nomeCliente}`)
        })
        setCallClienteLoad(true);
        resolve(arrayInfoTitular);
      })      
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch((error) => {      
    })        
  }    

  useEffect(() => {    
    if (autocompleteRef.current) {
      autocompleteRef.current.querySelector('#autocom').click();
    }

    handleCallClientes().then((result) => {                
      const cpfRegex = /\d{3}\.\d{3}\.\d{3}-\d{2}/; // Regex para CPF

      const cpfs = result.filter(item => cpfRegex.test(item));
      setOptions(cpfs)
      setShowInput(true)
    }).catch((erro) => {      
      setShowInput(false);
    })
  }, []);

  function getInfoCombo(infoSelected, type){          
    return new Promise(async (resolve, reject) => {
      try {                       

        var parts = infoSelected.split('-');
        if (parts.length > 0) {
            var cpf = `${parts[0].trim()}-${parts[1].trim()}`;            
            resolve(cpf);
        }else{
          reject([]);
        }
          /*var objResult = [];          
          if (infoSelected != ''){            
            const regexLastInfo = /\[([^\]]+)\](?![^\[]*\[)/g;
        
            let matches = [];
            let match;
            while ((match = regexLastInfo.exec(infoSelected)) !== null) {
                matches.push(match[1]);
            }
        
            if (matches.length > 0) {
                const ultimoMatch = matches[matches.length - 1];                
                objResult.push(ultimoMatch);
            }      
            
            const regexInfoName = /\[[^\]]+\]/g;
      
            let matches2 = infoSelected.match(regexInfoName);
      
            if (matches2 && matches2.length >= 2) {
                const matchCpf = matches2[1].slice(1, -1); 
                objResult.push(matchCpf);
            }
          }
    
          resolve(objResult);*/
      } catch (error) {
        reject([]);
      }
    })

    
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Consulta Titular</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Filtre por código ou nome do Títular
        </DialogContentText>
        
          <div style={{width: "500px", marginTop:"14px"}}>
          </div>
            {callClienteLoad && (
              <Autocomplete
                id='autocom'
                key={(option) => option.index}
                ref={autocompleteRef}
                value={searchText}
                onChange={(event, newValue) => {setSearchText(newValue ? newValue : '')}}            
                options={options ? options : []}
                renderInput={(params) => <TextField {...params} label="Name" id="autocom" />}
              />
            )}
          
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSearch} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}