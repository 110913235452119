import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../shared/MDTypography";
import { IResultDTO, MovimentacaoTurmaViewModel } from '../DetalheType';
import Card from "@mui/material/Card";
import MDButton from "../../shared/MDButton";

interface IProps {  
  listaTurma: IResultDTO | null;
  qtdAlunos: number; 
  byId: boolean; 
  selecaoAluno: any;
}

export default function BoxDetalheTurma({listaTurma, qtdAlunos, byId, selecaoAluno} : IProps){  
  const [listAlunosSelecionados, setListAlunosSelecionados] = useState<MovimentacaoTurmaViewModel>(null);


  useEffect(() => {
    console.log('listAlunosSelecionados ', JSON.stringify(listAlunosSelecionados));
    if (listAlunosSelecionados !== null){
      selecaoAluno(listAlunosSelecionados)}    
    
  }, [listAlunosSelecionados])

  useEffect(() => {
   console.log('listaTurma ', listaTurma);
   
    
  }, [listaTurma])
  

  const handleSelecionaAluno = (idAluno) => {    
      const updatedList = listAlunosSelecionados?.AlunosMovimentacao || []; 
      if (updatedList.includes(idAluno)) {      
        setListAlunosSelecionados(prevState => ({
          ...prevState,
          AlunosMovimentacao: updatedList.filter(item => item !== idAluno)
        }));        
      } else {      
        setListAlunosSelecionados(prevState => ({
          ...prevState,
          AlunosMovimentacao: [...updatedList, idAluno]
        }));
      }
    
  }

  return (
    <>
      {byId ? (
        <Grid container p={4} xl={12}>
          <Grid item xl={12}>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >          
              <MDTypography variant="h6" color="white" flexDirection={"column"} style={{marginLeft: 8}} p={1}>
                {listaTurma?.data[0].nomeTurma} -      VAGAS: {qtdAlunos - listaTurma?.data.length}
              </MDTypography>                                   
            </MDBox>  
            {listaTurma?.data?.map((item)=>(
              <Grid item xl={12} key={item.id}>                
                <MDButton style={{marginTop: 8, backgroundColor: !listAlunosSelecionados?.AlunosMovimentacao.includes(item.clienteId) ? '#fff' : 'green', width: '100%'}}
                  onClick={() => handleSelecionaAluno(item.clienteId)}
                >
                    <MDTypography style={{marginLeft: 20, textAlign: 'start'}}>
                        {item.nomeCliente}
                    </MDTypography>
                </MDButton>                                           
            </Grid>
            ))}
            
          </Grid>            
        </Grid>
      ):(
        <Grid container p={4} lg={12}>
           {listaTurma?.data?.map((item)=>(
            <>
              <Grid item lg={5}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >          
                  <MDTypography variant="h6" color="white" flexDirection={"column"} style={{marginLeft: 8}} p={1}>
                    {item.nomeTurma}
                  </MDTypography>                                   
                </MDBox>                          
              </Grid>  
              <Grid item lg={1}/>                      
              <Grid item lg={5}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >          
                  <MDTypography variant="h6" color="white" flexDirection={"column"} style={{marginLeft: 8}} p={1}>
                    {item.nomeTurma}
                  </MDTypography>                                   
                </MDBox>                          
              </Grid>      
            </>
            ))}
        </Grid>
      )}
    </>
  )
}