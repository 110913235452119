/* eslint-disable no-constant-condition */
import { Button, ButtonBase, Card, Grid } from '@mui/material'
import * as React from 'react'
import {  } from './CadContratacao.styled';
import { ContainerResumoContrato, ContainerResumoContratoValor, 
  FieldTituloHora, FieldInfo, FieldInfoValor, FieldTitulo, FieldTituloValor, TituloResumoContratacao, TituloResumoGeralContratacao, ContainerResumoIconDetail, ContainerRow, TextResumoGeralContratacao, ContainerResumoChecked } from './CardResumoContratacao.styled'
import MDBox from '../../../shared/MDBox';
import EditIcon from '@mui/icons-material/Edit';
import ModalEdit from '../../../../layouts/shared/ModalEdit';
import { IResultDTOContratacao, ITotalizador } from '../../../../layouts/cadContratacao/CadContratacaoType';
import { Cadastrar } from '../../../../layouts/cadContratacao/CadContratacaoService';
import { IContratacaoGetType } from '../../../../layouts/contratacao/ContratacaoType';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DownloadingIcon from '@mui/icons-material/Downloading';

interface CardResumoContratacaoProps {
  objResumoContratacao: any[] 
  onRetornoSalvar: (info: IResultDTOContratacao) => void;
}

export default function CardResumoContratacao ({objResumoContratacao, onRetornoSalvar} : CardResumoContratacaoProps){
  const [openModalResumo, setOpenModalResumo] = React.useState(false);
  const [objTempValores, setObjTempValores] = React.useState([]);

  //seta totalizadores
  const [totalBruto, setTotalBruto] = React.useState(0);
  const [totalDesconto, setTotalDesconto] = React.useState(0);
  const [totalLiquido, setTotalLiquido] = React.useState(0);


  React.useEffect(() => {
    console.log('objResumoContratacao ', objResumoContratacao);        
    setObjTempValores(objResumoContratacao);

  }, [objResumoContratacao])

  React.useEffect(() => {
    let totalDesconto = 0;
    let totalLiquido = 0;
    let totalBruto = 0;

    objTempValores.forEach(item => {     
      if (item.checked){
        totalDesconto += item.totalDesconto * item.duracao;
        totalLiquido += item.totalLiquido * item.duracao;
        totalBruto += item.totalBruto * item.duracao;        
      } 
    })

    setTotalBruto(totalBruto);
    setTotalDesconto(totalDesconto);
    setTotalLiquido(totalLiquido);
  }, [objTempValores])


  const handleFinalizaContratacaoUnica = async (data, index) => {     
    console.log('obj a ser inserido ', JSON.stringify(data));
                          
    const result = await Cadastrar(data)
      .then((result) => {            
        objResumoContratacao[index].checked = true;    
        const newObjTempValores = [...objTempValores]; 
      
        newObjTempValores[index] = {
          ...newObjTempValores[index],
          checked: true
        };
    
        setObjTempValores(newObjTempValores);            
      })                              
  }

  const renderContainerResumoContratacao = () => {
    return (

      <>
        <Grid container spacing={2} p={4}>  
          <Grid item xl={12} xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
              <TituloResumoContratacao>
                Agrupador de Contratações (Confirme os valores e finalize os cards)
              </TituloResumoContratacao>          
            </Grid>                
          </Grid>


          {objTempValores?.map((result, index) => {
            return (
              <>                
                <Grid item xl={6} xs={6} sm={6} style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>        
                  <MDBox style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', marginTop: 8, borderRadius: 8, width: '90%' }}>                                                                        
                    <ContainerResumoIconDetail>
                      <ButtonBase
                          onClick={() => {handleFinalizaContratacaoUnica(result, index)}}
                        >
                          {result.checked ? (
                            <AssignmentTurnedInIcon fontSize='large' style={{color: 'green'}}/>
                          ):(
                            <DownloadingIcon  fontSize='large' />
                          )
                          }
                      </ButtonBase>
                    </ContainerResumoIconDetail>

                    
                    <ContainerResumoContrato>
                      <FieldTitulo>
                        Contratante :
                      </FieldTitulo>
                      <FieldInfo>          
                        {result.nomeContratante}
                      </FieldInfo>
                    </ContainerResumoContrato>
                    <ContainerResumoContrato>
                      <FieldTitulo>
                        Plano : 
                      </FieldTitulo>
                      <FieldInfo>          
                        {result.nomePlano}
                      </FieldInfo>
                    </ContainerResumoContrato>
                    <ContainerResumoContrato>
                      <FieldTitulo>
                        Aluno :
                      </FieldTitulo>
                      <FieldInfo>          
                        {result?.alunoInfo[0]?.nomeAluno}
                      </FieldInfo>
                    </ContainerResumoContrato>
                    {result.alunoInfo?.map((data) => {
                      return (
                        <>
                          {data.horarios?.map((horario) => {
                            return (
                              <>                  
                                <ContainerResumoContrato>
                                  <FieldTituloHora>
                                    {`${horario.horarioDe} - ${horario.horarioAte}`}
                                  </FieldTituloHora>
                                  <FieldInfo>          
                                    {horario.domingo && ' Domingo' } 
                                    {horario.segundaFeira && ' Segunda' } 
                                    {horario.tercaFeira && ' Terça' } 
                                    {horario.quartaFeira && ' Quarta' } 
                                    {horario.quintaFeira && ' Quinta' } 
                                    {horario.sextaFeira && ' Sexta' } 
                                    {horario.sabado && ' Sabado' } 
                                  </FieldInfo>
                                </ContainerResumoContrato>
                              </>
                            )
                          })}
                        </>
                      )
                    })}
                    <ContainerResumoContrato style={{flexDirection: 'row', marginLeft: 16, marginTop: 4, alignItems: "flex-start", justifyContent: 'flex-start', marginRight: 16, flex: 1}}>
                      <FieldTitulo>
                        Duração Plano :
                      </FieldTitulo>
                      <FieldInfo>          
                        {result.duracao}
                      </FieldInfo>
                    </ContainerResumoContrato>
                    <ContainerResumoContrato style={{flexDirection: 'row', marginLeft: 16, marginTop: 4, alignItems: "flex-end", justifyContent: 'flex-end', marginRight: 16, flex: 1}}>
                      <FieldTitulo>
                        Total Bruto Plano :
                      </FieldTitulo>
                      <FieldInfo>          
                        {result.totalBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </FieldInfo>
                    </ContainerResumoContrato>
                    <ContainerResumoContrato style={{flexDirection: 'row', marginLeft: 16, marginTop: 4, alignItems: "flex-end", justifyContent: 'flex-end', marginRight: 16, flex: 1}}>
                      <FieldTitulo>
                        Total Desconto Plano : 
                      </FieldTitulo>
                      <FieldInfo>          
                        {result.totalDesconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </FieldInfo>
                    </ContainerResumoContrato>
                    <ContainerResumoContrato style={{flexDirection: 'row', marginLeft: 16, marginTop: 4, alignItems: "flex-end", justifyContent: 'flex-end', marginRight: 16, flex: 1}}>
                      <FieldTitulo>
                        Total Liq. Plano :
                      </FieldTitulo>
                      <FieldInfo>          
                        {result?.totalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </FieldInfo>
                    </ContainerResumoContrato>                                                                   
                  </MDBox> 

       
                  
                </Grid>    
                   
              </>
            )
          })}
                
        </Grid> 
        <ContainerRow>
          <TituloResumoGeralContratacao>
            Total bruto Geral:     
          </TituloResumoGeralContratacao>    
          <TextResumoGeralContratacao>
            R$: { (((totalBruto))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </TextResumoGeralContratacao>    
        </ContainerRow>    
        <ContainerRow>
          <TituloResumoGeralContratacao>
            Total desconto Geral:     
          </TituloResumoGeralContratacao>  
          <TextResumoGeralContratacao>            
            R$: { (((totalDesconto))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </TextResumoGeralContratacao>    
        </ContainerRow>
        <ContainerRow>
          <TituloResumoGeralContratacao>
            Total contratação Geral:     
          </TituloResumoGeralContratacao>  
          <TextResumoGeralContratacao>
           R$: { (((totalLiquido))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </TextResumoGeralContratacao>            
        </ContainerRow>                
      </>
    )

  }

  /*
  <ContainerResumoContratoValor>
                    <FieldTituloValor>
                      Duração :
                    </FieldTituloValor>
                    <FieldInfoValor>          
                      {`${result.duracao}  Meses`}                      
                    </FieldInfoValor> 
                  </ContainerResumoContratoValor>   
                  <ContainerResumoContratoValor>
                    <FieldTituloValor>
                      Desconto :
                    </FieldTituloValor>                    
                    <FieldInfoValor>          
                      R$ {result.desconto.toFixed(2)}
                    </FieldInfoValor> 
                  </ContainerResumoContratoValor> 
                  <ContainerResumoContratoValor>
                    <FieldTituloValor>
                      Valor Mensal :
                    </FieldTituloValor>
                    <FieldInfoValor>          
                      R$ {((result.valor-result.desconto).toFixed(2))}
                    </FieldInfoValor> 
                  </ContainerResumoContratoValor>    
                  <ContainerResumoContratoValor>
                    <FieldTituloValor>
                      Total Desconto :
                    </FieldTituloValor>
                    <FieldInfoValor>          
                      R$ {((result.valor*result.duracao).toFixed(2))}
                    </FieldInfoValor> 
                  </ContainerResumoContratoValor>     
                  <ContainerResumoContratoValor>
                    <FieldTituloValor>
                      Total Plano :
                    </FieldTituloValor>
                    <FieldInfoValor>          
                      R$ {((result.valor*result.duracao).toFixed(2))}
                    </FieldInfoValor> 
                  </ContainerResumoContratoValor>                                        
                  */


  const renderContainerResumoContratacaoModalEdit = () => {
    return (

      <>
        <Grid container spacing={2} p={4}>  
          <Grid item xl={12} xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>        
            <MDBox style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', marginTop: 8, borderRadius: 8, width: '90%' }}>                                                    
              <ContainerResumoContrato>
                <FieldTitulo>
                  Contratante :
                </FieldTitulo>
                <FieldInfo>          
                  Marcos
                </FieldInfo>
              </ContainerResumoContrato>
              <ContainerResumoContrato>
                <FieldTitulo>
                  Plano :
                </FieldTitulo>
                <FieldInfo>          
                  Plano atividade txn
                </FieldInfo>
              </ContainerResumoContrato>
              <ContainerResumoContrato>
                <FieldTitulo>
                  Aluno :
                </FieldTitulo>
                <FieldInfo>          
                  Theo e Heitor
                </FieldInfo>
              </ContainerResumoContrato>
              <ContainerResumoContrato>
                <FieldTituloHora>
                  08:00 - 09:00 |
                </FieldTituloHora>
                <FieldInfo>          
                  - segunda - quarta
                </FieldInfo>
              </ContainerResumoContrato>
              <ContainerResumoContrato>
                <FieldTituloHora>
                  08:00 - 09:00 |
                </FieldTituloHora>
                <FieldInfo>          
                  - segunda - quarta
                </FieldInfo>
              </ContainerResumoContrato>
              <ContainerResumoContratoValor>
                <FieldTituloValor>
                  Duração :
                </FieldTituloValor>
                <FieldInfoValor>          
                  12 meses
                </FieldInfoValor> 
              </ContainerResumoContratoValor>   
              <ContainerResumoContratoValor>
                <FieldTituloValor>
                  Deconto :
                </FieldTituloValor>
                <FieldInfoValor>          
                  50.00
                </FieldInfoValor> 
              </ContainerResumoContratoValor> 
              <ContainerResumoContratoValor>
                <FieldTituloValor>
                  Valor :
                </FieldTituloValor>
                <FieldInfoValor>          
                  300.00
                </FieldInfoValor> 
              </ContainerResumoContratoValor>                                     
            </MDBox> 
          </Grid>                              
        </Grid>      
      </>
    )

  }

  const handleSubmitContratacao = async (objPost) => {    
      const arrayTypeAssertion: any = [{mensagem: 'Contratação cadastrada com sucesso!', sucesso: true, data: []}];
      onRetornoSalvar(arrayTypeAssertion);    
  }

  return(
    <Card style={{marginTop: 8, backgroundColor: 'silver'}}      
    >  
      { openModalResumo && <ModalEdit children={renderContainerResumoContratacaoModalEdit()} /> }           
      { renderContainerResumoContratacao() }                      
      <Grid item xl={12} pt={4} pb={4} mt={8}>                      
        <Button variant="contained" color="primary" fullWidth
          onClick={() => {handleSubmitContratacao(objResumoContratacao)}}
        >
          Fechar agrupador de contratação
        </Button> 
      </Grid>             
    </Card>                     
  )
}