/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import { getByDescricaoTurma, postMovimentacaoAlunosTurmas } from './SearchTurmasServices';
import { PlanosAtividades } from './SearchTurmasType';
import { Height } from '@material-ui/icons';
import  MDTypography  from '../../shared/MDTypography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IResultDTOMovContracao, MovimentacaoTurmaViewModel } from '../../../layouts/detalheTurma/DetalheType';
interface SearchTurmasProps {
  isOpen: boolean,
  onCloseScreen: any,
  arrAlunos: MovimentacaoTurmaViewModel,
  infoTurmaSelecionado: (infoPlano) => void,  
}

export default function SearchTurmas({ isOpen, onCloseScreen, arrAlunos, infoTurmaSelecionado}: SearchTurmasProps) {
  const autocompleteRef = useRef(null);

  const [searchText, setSearchText] = useState('');  
  const [options, setOptions] = useState([]);  
  const [callPlanoLoad, setCallPlanoLoad] = useState(false);
  const [allPlanos, setAllPlanos] = useState<PlanosAtividades[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [textMovTurma, setTextMovTurma] = useState('');
  const [turmaId, setTurmaId] = useState('');
  const [movRealizada, setMovRealizada] = useState(false);
  const [objMov, setObjMov] = useState<MovimentacaoTurmaViewModel>(null);

  const handleSearch = async () => {                  
    const TurmasByName = await getByDescricaoTurma(searchText);                  
    const objInfoPlano = {}                
    infoTurmaSelecionado(objInfoPlano);          
  };

  const handleCallTurmas = async () => {    
    await getByDescricaoTurma(searchText)
      .then((result) => {
        setCallPlanoLoad(true);        
        setOptions(result?.data)             
      })                                
  }  

  useEffect(() => {                  
    searchText.length >= 3 && handleCallTurmas();   
    searchText.length < 3 && setOptions([]);

  }, [searchText]);

  const handleVerificaMovTurma = (data) => {            
    setTextMovTurma(`${data?.descricao}`)
    setTurmaId(data?.id)
  }

  const handleRealizaMovTurma = async (turmaId) => {   
    console.log('new turma ', turmaId);
     
    /*setObjMov({
      ...arrAlunos,
      IdTurmaMovimentacao: turmaId
    })*/

    arrAlunos.IdTurmaMovimentacao = turmaId.replace(/"/g, '');
    console.log('arrAlunos ', JSON.stringify(arrAlunos));

    await postMovimentacaoAlunosTurmas(arrAlunos)
      .then((result: IResultDTOMovContracao) => {
        result.sucesso ? 
          setMovRealizada(true)
        :
          setMovRealizada(false);
      })        
  }

  const handleOnClose = () => {      
    onCloseScreen();
    setTextMovTurma('');
    setOptions([]);
    setMovRealizada(false);  
  }

  useEffect(() => {
    console.log('objMov ', objMov);    
  }, [objMov])


  return (
    <Dialog open={isOpen} onClose={onCloseScreen}>
      {textMovTurma == '' && (
        <>
          <DialogTitle>Consulta Turmas</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Filtre por descricão da turma
            </DialogContentText>
            
              <div style={{width: "500px", marginTop:"14px"}} />          
              
              <div style={{ border: '2px solid purple', borderRadius: 8, width: '100%', padding: 4 }}>
                <input
                  style={{
                    height: 32,
                    width: '100%',
                    border: 'none', 
                    outline: 'none',
                    background: 'transparent',
                    padding: '0 8px', 
                    fontSize: 16                              
                  }}
                  onChange={(event) => {setSearchText(event.target.value)}}
                />
              </div>
              {options.length > 0 && (            
                <div style={{
                  borderRadius: 8,
                  border: '1px solid #ccc',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#fff',
                  width: '500px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  padding: '8px'
                }}
                >
                  {options.length > 0 && (
                    options.map((data, index) => (
                      <Button
                        style={{ 
                          fontSize: 16, 
                          backgroundColor: hoveredIndex === index ? '#f0f0ff' : 'transparent', 
                          width: '100%' 
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() => {handleVerificaMovTurma(data)}}
                      >
                        <MDTypography style={{ alignSelf: 'flex-start' }}>
                          {data.descricao}
                        </MDTypography>
                      </Button>
                    ))
                  )
                  }
                </div>
              )}
            
          </DialogContent>
        </>
      )}
      {movRealizada && (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <MDTypography style={{ fontSize: 18, padding: 16, fontWeight: '700' }}>
              Movimentação realizada com sucesso!
            </MDTypography>
            <CheckCircleIcon fontSize='medium' style={{ color: 'green', marginRight: 16 }} />
          </div>
        </>        
      )}

      {(textMovTurma != '' && !movRealizada) && (
        <>
          <MDTypography style={{fontSize: 16, marginLeft: 16, marginTop: 16, marginRight: 16, fontWeight: '700'}}>
            Deseja realizar a movimentação de alunos para a turma a baixo ?
          </MDTypography>
          <MDTypography style={{padding: 32, fontSize: 16}}>
            {textMovTurma}
          </MDTypography>
          <DialogActions>
            <Button onClick={onCloseScreen} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => {handleRealizaMovTurma(turmaId)}} color="primary">
              Confirmar
            </Button>
          </DialogActions>        
        </>
      )}
      {movRealizada && (
        <DialogActions>
          <Button onClick={handleOnClose} color="primary">
            Fechar
          </Button>
        </DialogActions>        
      )}
      {textMovTurma == '' && (
        <DialogActions>
          <Button onClick={onCloseScreen} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      )} 
    </Dialog>
  );
}