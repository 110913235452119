/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import React, {useEffect, useState} from "react";

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import MDButtonRoot from "components/MDButton/MDButtonRoot";
import atividadeService from "../../../services/AtividadeService"; 
import { isExpired } from "react-jwt";
import IconEdit from '@mui/icons-material/Edit';


export default function data(valueSearch, typeBotton, objFull) {  
  const [rowsInfo, setRowsInfo] = useState([]);
  const [columns, setColummns] = useState([]);
  const [dataReturn, setDataReturn] = useState([]);    


  const handlePrepareRows = (infoReturn) => {    
    let arrayRows = [];
    let arrayListaHorarios = [];
    infoReturn.map((result, index) => {      

      result.horarios?.map((data) => {
        let desc = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.descricao}          
          </MDTypography>
        );        

        let horario = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="light">
            {data.horarioDe.slice(0, 5)}-{data.horarioAte.slice(0, 5)}
          </MDTypography>
        )

        let domingo = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.domingo ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let segunda = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.segundaFeira ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let terca = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.tercaFeira ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let quarta = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.quartaFeira ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let quinta = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.quintaFeira ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let sexta = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.sextaFeira ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let sabado = (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {data.sabado ? <DoneIcon fontSize="small" /> : ''}
          </MDTypography>
        )

        let action = (
          
            <MDButton color={'info'} circular={true} size={'small'} iconOnly={true}
              onClick={() => {typeBotton('Editar')}}
            >                
              <IconEdit />
            </MDButton>  
          
        )

        let itemObj = {
          descricao: desc,
          horario: horario,
          domingo: domingo,
          segunda: segunda,
          terca: terca,
          quarta: quarta,
          quinta: quinta,
          sexta: sexta,
          sabado: sabado, 
          action: action     
        }                               

        arrayRows.push(itemObj);       

        let objFullIndex = {
          id: result.id,
          descricao: result.descricao,
          horarios: [{
            atividadeId: data.atividadeId,
            domingo: data.domingo,
            segundaFeira: data.segundaFeira,
            tercaFeira: data.tercaFeira,
            quartaFeira: data.quartaFeira,
            quintaFeira: data.quintaFeira,
            sextaFeira: data.sextaFeira,
            sabado: data.sabado
          }]
          
        }

        arrayListaHorarios.push(objFullIndex);
      })            

      
    });
    
    setRowsInfo(arrayRows);
    objFull(arrayListaHorarios);       
  }

  const handlePrepareColumms = () => {
    var columns = [];    

    columns = [
      { Header: "Descricao", accessor: "descricao"},      
      { Header: "Horario", accessor: "horario", widthh: '2'},      
      { Header: "Segunda", accessor: "segunda", align: "center"},      
      { Header: "Terça", accessor: "terca", align: "center"},
      { Header: "Quarta", accessor: "quarta", align: "center"},
      { Header: "Quinta", accessor: "quinta", align: "center"},
      { Header: "Sexta", accessor: "sexta", align: "center"},
      { Header: "Sabado", accessor: "sabado", align: "center"},
      { Header: "Domingo", accessor: "domingo", align: "center"},
      { Header: "Ação", accessor: "action", left: -10},
    ]            
    
    setColummns(columns);
  }

  const handleFilterData = (search, infoReturn) => {        
    let newInfoReturn = infoReturn.filter(x => x.descricao.toLowerCase().includes(search.toLowerCase()));    

    const filteredInfoReturn = infoReturn.filter(item => {
      return item.horarios.some(schedule => schedule.horarioDe.slice(0, 5) === search);
    });    
   
    objFull(filteredInfoReturn.length > 0 ? filteredInfoReturn : newInfoReturn); 
    handlePrepareRows(filteredInfoReturn.length > 0 ? filteredInfoReturn : newInfoReturn);
  }

  useEffect(async () => {         
    await atividadeService.getAll().then((result) => {        
      setDataReturn(result.data);        
    }).catch((error) => {      

    })  
    
    
  }, [])

  useEffect(() => {          
    if (dataReturn.length > 0)  {      
      handlePrepareRows(dataReturn);
      handlePrepareColumms();
    }    
  }, [dataReturn])

  useEffect(() => {    
    setTimeout(() => {  
      if (valueSearch != null){
        dataReturn.length > 0 && handleFilterData(valueSearch, dataReturn);
      }             
    }, 2000);   
    
  }, [valueSearch])



  

  return {    

    columns: columns,
    rows: rowsInfo
  };
}
