import BaseService from '../../infrastructure/services';
import  { IResultDTO, ITurmaPostType, 
  ITurmaPutType, IAtividadesGetType, IHorariosTurmaGetType, IResultDTOHorarios, IResultDTOHorarioDisp  } from './CadTurmaType';

export const getTurmaById = async (id): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Turma/ObterPorId?id=${id}`, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}

export const Cadastrar = async (obj : ITurmaPostType): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.post(`/Turma/cadastrar`, obj, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}

export const Atualizar = async (obj : ITurmaPutType): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.put(`/Turma/Atualizar`, obj, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}

export const Deletar = async (turmaId: string): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.delete(`/Turma/ExcluirHorarioTurma?turmaId=${turmaId}`, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}

export const getByIdAtividade = async (id): Promise<IAtividadesGetType> => {          
  const customConfig = {            
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`, 
      },
  };  
  
  const newId = decodeURIComponent(id);      
  const response = await BaseService.get(`/Atividade/ObterPorId?id=${newId}`, customConfig);                    
  const responseData: IAtividadesGetType = response.data;
  return responseData;
}

export const VerificaHorariosDisponiveis = async (idAtividade: string) => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Turma/HorariosDisponiveisCadastroTurma?atividadeId=${idAtividade.replace(/"/g, '')}`, customConfig);
  const responseData = response.data;

  return responseData;
}

