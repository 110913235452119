import axios from 'axios';

const BaseService = axios.create({
  baseURL: 'https://api.brincaracademia.com.br/api',  
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }  
});


export default BaseService;