import BaseService from '../../infrastructure/services';
import  { IResultDTO } from './DetalheType';

export const getAlunosByTurmaId = async (id: string): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`Turma/ObterAlunosPorTurma?idTurma=${id}`, customConfig);  
  const responseData: IResultDTO = response.data;

  return responseData;
}

export const getQtdAlunoTurma = async (id: string) => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`Turma/ObterPorId?id=${id}`, customConfig);  
  return response.data;  
}

export const getObterTodosAlunosPorTurma = async (): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`Turma/ObterTodosAlunosPorTurma`, customConfig);  
  const responseData: IResultDTO = response.data;

  return responseData;
}



