import * as React from 'react';
import DashboardLayout from '../../../shared/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../shared/Navbars/DashboardNavbar';
import TurmaForm from './TurmaForm';


export default function ListScreen() {  
  return (    
    <DashboardLayout>
      <DashboardNavbar />     
      <TurmaForm />        
    </DashboardLayout>    
  );
}