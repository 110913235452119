/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import atividadesTableData from "./data/AtividadesTableData";
import authorsTableData from "./data/authorsTableData";
import projectsTableData from "./data/projectsTableData";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import { makeStyles } from '@mui/styles';
import "./index.css";
import { Box } from "@material-ui/core";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Button from '@mui/material/Button';
import {React, useEffect, useState } from "react";
import MDInput from "components/MDInput";
import { verifyRolesAcess } from "../../services/Utils/verifyRolesAcess";
import { Alert } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {    
    flexDirection: 'row',    
  },
  item: {
    width: 100,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',    
  },
  alignEndItem: {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  },

  
}));

function AtividadesNew() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);  

  const [searchFilter, setSearchFilter] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);  
  const [typeBotton, setTypeBotton] = useState('');   
  const [objFull, setObjFull] = useState([]);
  const [invalidPermission, setInvalidPermission] = useState(false);

  const handleRowClick = (rowIndex) => {
    setClickedRowIndex(rowIndex);
  };    

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTypeBotton = (rowIndex) => {
    setTypeBotton(rowIndex);
  };

  const handleObjFull = (obj) => {        
    setObjFull(obj);
  }  

  const customInputStyle = {
    width: '100%',
    padding: 26,
    marginTop: -16 
  };

  const { columns, rows } = atividadesTableData(searchFilter, handleTypeBotton, handleObjFull);  

  const handlePrepareData = (nameFilter) => {    
    setSearchFilter(nameFilter);    
  }    
  
  const handleFilterEditar = async () => {            
    var rangeInfo = currentPage * 5;    
    var newObj = [];
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    }) 
    
    navigate(`/atividadesNew/cadAtividadesNew/?id=${JSON.stringify(newObj[clickedRowIndex].id)}`);            
  }

  useEffect(() => {    
    if (typeBotton === 'Editar'){
      handleFilterEditar();      
      setTypeBotton('');
    }
  }, [typeBotton]);   

  useEffect(async () => {
    var invalidPermissionInfo = await verifyRolesAcess('atividades');    
    setInvalidPermission(!invalidPermissionInfo);
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {invalidPermission ? (
        <>
          <Alert severity="error">
            Você não tem permissão para acessar esse recurso.
          </Alert>
        </>
      ):(
        <>
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    flexDirection="row"
                  >
                    <Box> 
                    <MDTypography variant="h6" color={"white"} flexDirection={"column"} ml={"10px"}>
                      Cadastro de Atividades
                    </MDTypography>                 
                    </Box>
                    


                  </MDBox>
                  <Grid item xs={12} pl={2}>

                  </Grid>
                  
                  <div className={classes.boxFilter}>                  
                    <MDInput style={customInputStyle} onChange={(event) => {handlePrepareData(event.target.value)}}                 
                      placeholder="Filtrar"
                    >
                    </MDInput>                   
                  </div>
                  
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={true}
                      showTotalEntries={false}
                      noEndBorder
                      onRowClick={handleRowClick} 
                      pageNumber={handlePageChange}  
                    />
                  
                  <Link to={"/atividadesNew/cadAtividadesNew"} className={classes.alignEndItem}>
                    <Button style={{width: 50, height: 50, backgroundColor: '#FFF', borderRadius: 50}}>
                      <AddCircleRoundedIcon style={{width: 40, height: 40, color: '#ba79e1'}}/>                      
                    </Button>
                  </Link>                                                                                                                                                            
                    
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                
                </Card>
              </Grid>
            </Grid>
          </MDBox>      
        </>
      )}
    </DashboardLayout>
  );
}

export default AtividadesNew;
