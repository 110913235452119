import BaseService from '../../infrastructure/services';
import  { IResultDTO, ITurmaGetType } from './TurmaType';

export const getAll = async (): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Turma/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}
