/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate  } from "react-router-dom";
import Button from '@mui/material/Button';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import { TextField, Box, Checkbox } from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import { decodeToken, isExpired } from "react-jwt";
import { Alert, Modal, Snackbar, Typography } from "@mui/material";
import { ReAuth } from "layouts/authentication/reAuth";
import { verifyRolesAcess } from "../../../../services/Utils/verifyRolesAcess";
import funcionarioService from "../../../../services/FuncionarioService";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import HomeIcon from '@mui/icons-material/Home';
import imgLogo from '../../../../assets/images/logoAnamnese.png'
import clienteService from '../../../../services/ClienteService';



const useStyles = makeStyles((theme) => ({
  container: {    
    flexDirection: 'row',    
  },
  item: {
    width: 100,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignEndItem: {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  },
  boxFilter : {
    flexDirection: 'row', marginTop: 5, flex: 1, height: '20px'
  }
}));

const customInputStyle = {
  width: '100%',
  padding: 26,
  marginTop: -16 
};
 

function ListaAnamnese() {  

  const queryParams = new URLSearchParams(location.search);
  const idInfo = queryParams.get('idInfo'); 
  
  //Objetivos
  const [objetivoEmagrecimento, setObjetivoEmagrecimento] = useState(false);
  const [objetivoOcupacaoTempoLivre, setObjetivoOcupacaoTempoLivre] = useState(false);
  const [objetivoGastoEnergia, setObjetivoGastoEnergia] = useState(false);
  const [objetivoDesenvolvimentoPsicomotor, setObjetivoDesenvolvimentoPsicomotor] = useState(false);
  const [objetivoSaudePreventiva, setObjetivoSaudePreventiva] = useState(false);
  const [objetivoIndicacaoMedica, setObjetivoIndicacaoMedica] = useState(false);
  const [objetivoIncentivarPraticaAtividadeFisica, setObjetivoIncentivarPraticaAtividadeFisica] = useState(false);

  //InfoPeso e estatura
  const [peso, setPeso] = useState(10);
  const [estatura, setEstatura] = useState(0.00);

  //Data historico avaliacao medica
  const [historicoDataUltimaAvaliacaoMedica, setHistoricoDataUltimaAvaliacaoMedica] = useState('');

  
  //Intervencao cirurgica 
  const [historicoAlunoSofreuIntervencaoCirurgica, setHistoricoAlunoSofreuIntervencaoCirurgica] = useState(false);
  const [historicoQualIntervencaoCirurgica, setHistoricoQualIntervencaoCirurgica] = useState('');

  //Lesao Osteomuscular
  const [historicoAlunoSofreuLesaoOsteomuscular, setHistoricoAlunoSofreuLesaoOsteomuscular] = useState(false);
  const [historicoQualLesaoOsteomuscular , setHistoricoQualLesaoOsteomuscular ] = useState('');

  //Medicamentos Utilizados 
  const [historicoMedicamentosUtilizadosUltimosTempos, setHistoricoMedicamentosUtilizadosUltimosTempos] = useState('');

  //Diagnosticada
  const [diagnosticadaTratadaMedicoDiabetes, setDiagnosticadaTratadaMedicoDiabetes] = useState(false);
  const [diagnosticadaTratadaMedicoColesterolAlto, setDiagnosticadaTratadaMedicoColesterolAlto] = useState(false);
  const [diagnosticadaTratadaMedicoHipertensao, setDiagnosticadaTratadaMedicoHipertensao] = useState(false);
  const [diagnosticadaTratadaMedicoDoencaCardiorrespiratoria, setDiagnosticadaTratadaMedicoDoencaCardiorrespiratoria] = useState(false);
  const [diagnosticadaTratadaMedicoAsma, setDiagnosticadaTratadaMedicoAsma] = useState(false);
  const [diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica, setDiagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica] = useState(false);
  const [diagnosticadaTratadaMedicoObesidade, setDiagnosticadaTratadaMedicoObesidade] = useState(false);
  const [diagnosticadaTratadaMedicoAnemia, setDiagnosticadaTratadaMedicoAnemia] = useState(false); 
  const [diagnosticadaTratadaMedicoTDAH, setDiagnosticadaTratadaMedicoTDAH] = useState(false);
  const [diagnosticadaTratadaMedicoTEA, setDiagnosticadaTratadaMedicoTEA] = useState(false); 
  const [diagnosticadaTratadaMedicoTOD, setDiagnosticadaTratadaMedicoTOD] = useState(false);   
  const [diagnosticadaOutrasChk, setDiagnosticadaOutrasChk] = useState(false);  
  const [diagnosticadaTratadaMedicoOutras, setDiagnosticadaTratadaMedicoOutras] = useState('');

  //Informacoes Adicionais
  const [informacoesAdicionais, setInformacoesAdicionais] = useState('');

  //Informacoes cadastrais
  const [clienteId, setClienteId] = useState('');
  const [titularId, setTitularId] = useState('');
  const [nome, setNome] = useState('');
  const [genero, setGenero] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [nomeResponsavel, setNomeResponsavel1 ] = useState('');
  const [telefoneResponsavel1, setTelefoneResponsavel1] = useState('');
  const [emailResponsavel1, setEmailResponsavel1] = useState('');  

  //Required que não entraram
  const [possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude, setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude] = useState(false);
  const [senteDoresPeitoRealizaAtividadeFisica, setSenteDoresPeitoRealizaAtividadeFisica] = useState(false);
  const [apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia, setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia] = useState(false);
  const [possuiProblemaOsseoOuArticularPodePiorarAtividadeFisica, setPossuiProblemaOsseoOuArticularPodePiorarAtividadeFisica] = useState(false);
  const [tomaMedicamentoPressaoArterialOuCoracao, setTomaMedicamentoPressaoArterialOuCoracao] = useState(false);
  const [algumaOutraRazaoNaoPraticarAtividadeFisica, setAlgumaOutraRazaoNaoPraticarAtividadeFisica] = useState(false);
  const [outraRazaoNaoPraticarAtividadeFisica, setOutraRazaoNaoPraticarAtividadeFisica] = useState(false);
  const [termoResponsabilidadeParaPraticaAtividadeFisicaAssinado, setTermoResponsabilidadeParaPraticaAtividadeFisicaAssinado] = useState(false);
  const [assinaturaResponsavel , setAssinaturaResponsavel] = useState('');

  const [stepErrorEstatura, setStepErrorEstatura] = useState(false);
  const [stepErrorPeso, setStepErrorPeso] = useState(false);
  
  
  const [inProp, setInProp] = useState(false);

  const [onHoverBtContinue, setOnHoverBtContinue] = useState(false);
  const [onHoverBtGoBack, setOnHoverBtGoBack] = useState(false);

  const [step, setStep] = useState(0);
  const [activeAnimation, setActiveAnimation] = useState(false);

  const [invalidPermission, setInvalidPermission] = useState(false);
  

  const objData = queryParams.get('objData');  

  const nodeRef = useRef(null);
  const duration = 600;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  };

  

  const handlePesoChange = (event) => {
    let text = event.target.value
    let newText = text.replace(/,/g, '.'); 
    setPeso(newText);
  };

  const handleEstaturaChange = (value) => {
    let hasCompletDigit = /^\d+\.\d{3}$/.test(value)
    let newText = hasCompletDigit ? parseFloat(value).toFixed(2) : value
    setEstatura(newText.toString());   
  } 


  function formatDateInfos(inputDate){      
    
    var dateSplit = inputDate.split('/');    
    var formattedDate = new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1]);
    
    var year = formattedDate.getFullYear();
    var month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    var day = String(formattedDate.getDate()).padStart(2, '0');

    
    var dateFormat = year + '-' + month + '-' + day;
    return dateFormat;    
  }

  const handleGetInfoPaciente = async (value) => {    
    await clienteService.getByID(value.clienteId)
      .then((result) => {          
        setClienteId(result.data.id);
        setNome(result.data.nomeCliente);        
        setTitularId(result.data.titularId);
        setNomeResponsavel1(result.data.nomeTitular);
        setEmailResponsavel1(result.data.email);
        var data = new Date(result.data.dataNascimento);
        var day = data.getDate();
        var month = data.getMonth() + 1;
        var year = data.getFullYear();
        data = day + "/" + month + "/" + year;
        setDataNascimento(formatDateInfos(data));
      })
  }

  useEffect(() => {     
    handleGetInfoPaciente(JSON.parse(objData));    
    handleSetInfoAnamnese(JSON.parse(objData));    
          
  }, [])


  function formatDtNasc(dtNasc) {      
    var valueSetRg = '';        
    valueSetRg = dtNasc.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3');          
    
    return valueSetRg;
  }

  const handleCheckIntervencaoCirurgica = (value) => {
    if (value === 'sim'){
      setHistoricoAlunoSofreuIntervencaoCirurgica(true)
    }else{
      setHistoricoAlunoSofreuIntervencaoCirurgica(false);
    }                

  }

  const handleNextAndPriorSteps = (op) => { 
    setStepErrorEstatura(false);
    setStepErrorPeso(false);
    setActiveAnimation(false); 
    op === '+' && setStep(step+1);
    op === '-' && setStep(step-1);    
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();      
  } 

  const infoDadosPaciente = () => {    
    return (
      <div style={{marginTop: 0, display: 'flex', flexDirection: 'row'}}>
        <MDTypography style={{alignSelf: 'flex-end', fontWeigth: '700', color: '#000'}}>
          Nome Aluno: 
        </MDTypography>              
        <MDTypography style={{alignSelf: 'flex-end', marginLeft: 4}}>
          {nome} 
        </MDTypography>             
      </div>
    )

      /*setClienteId(result.data.id);
      setNome(result.data.nomeCliente);        
      setTitularId(result.data.titularId);
      setNomeResponsavel1(result.data.nomeTitular);
      setEmailResponsavel1(result.data.email);
      var data = new Date(result.data.dataNascimento);*/
    
    
  }

  const objetivosQuestion = () => {        
    return (
      <>
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Quais objetivos espera para seu filho na atividade matriculada ?
        </MDTypography>          
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoEmagrecimento}                          
              
              color="primary"              
            />
          }label="Emagrecimento"
        />           
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoOcupacaoTempoLivre}                          
              
              color="primary"
            />
          }label="Ocupação do tempo livre"
        />           
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoGastoEnergia}                          
              
              color="primary"
            />
          }label="Gasto de energia"
        />       
        {window.innerWidth < 768 ? (<>
          <FormControlLabel
              control={
                <Checkbox
                  checked={objetivoDesenvolvimentoPsicomotor}                          
                  
                  color="primary"
                />
              }label={
                <div style={{ width: '150px', top: '10px' }}>
                  <div style={{marginTop: '26px'}}>
                    "Desenvolvimento psicomotor"
                  </div>                    
                </div>
                }
            />   
        </>):(
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={objetivoDesenvolvimentoPsicomotor}                          
                  
                  color="primary"
                />
              }label="Desenvolvimento psicomotor"
            />   
          </>
        )}    
        

        {window.innerWidth < 768 ? (
          <>
            <Grid item xs={12} sm={2}>     
              <FormControlLabel
                control={
                  <Checkbox
                    checked={objetivoSaudePreventiva}                          
                    
                    color="primary"
                  />
                }label={
                  <div style={{ width: '150px', top: '10px' }}>
                    <div style={{marginTop: '26px'}}>
                      "Saúde preventiva(sem indicação médica)"
                    </div>                    
                  </div>
                  }
              />           
            </Grid>
          </>
        ):(
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={objetivoSaudePreventiva}                          
                  
                  color="primary"
                />
              }label="Saúde preventiva(sem indicação médica)"                                
            />   
          </>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoIndicacaoMedica}                          
              
              color="primary"
            />
          }label="Indicação médica"
        />           
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoIncentivarPraticaAtividadeFisica}                                        
              color="primary"
            />
          }label={
            <div style={{ width: '150px', top: '10px' }}>
              "Incentivar a prática de atividade física"
            </div>
            
          }
        />                     

      </div>

  
      </>
        
    )
  }

  const dataUltimaAvaliacaoMedica = () => {        
    return (
    
          <div style={{marginTop: 40}}>
            <MDTypography style={{alignSelf: 'flex-end'}}>
              Data da última avaliação médica
            </MDTypography>    
            <Grid item xs={12} sm={2}>
              <TextField
                label=""
                name="date"
                value={historicoDataUltimaAvaliacaoMedica}                
                placeholder="DD/MM/YYYY" 
                fullWidth                                                      
              />
            </Grid> 
          </div>
    )
  }  

  const intervencaoCirurgica = () => {    
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
         Já sofreu alguma intervenção cirurgica  ? 
        </MDTypography>
        <MDBox display={'flex'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={historicoAlunoSofreuIntervencaoCirurgica}                          
                
                color="primary"
              />
            }label="Sim"
          />             
          <FormControlLabel
            control={
              <Checkbox
                checked={!historicoAlunoSofreuIntervencaoCirurgica}                          
                
                color="primary"
              />
            }label="Não"
          />  
            {historicoAlunoSofreuIntervencaoCirurgica && (
              <TextField
                style={{marginTop: 20}}
                id="nomeField"
                focused={true}            
                label="Qual ?"
                name="historicoQualIntervencaoCirurgica"
                value={historicoQualIntervencaoCirurgica}
                
                fullWidth          
              />  
            )}
        </MDBox>
      </div>
    )
    
    
  }

  const lesaoOsteomuscular = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Já teve alguma lesão Osteomuscular ? 
        </MDTypography>
        <MDBox display={'flex'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={historicoAlunoSofreuLesaoOsteomuscular}                          
                
                color="primary"
              />
            }label="Sim"
          />             
          <FormControlLabel
            control={
              <Checkbox
                checked={!historicoAlunoSofreuLesaoOsteomuscular}                          
                
                color="primary"
              />
            }label="Não"
          />  
          {historicoAlunoSofreuLesaoOsteomuscular && (            
            <TextField
              style={{marginTop: 20}}
              id="nomeField"
              focused={true}            
              label="Qual ?"
              name="historicoQualLesaoOsteomuscular"
              value={historicoQualLesaoOsteomuscular}
              
              fullWidth          
            />  
          )}
        </MDBox>
      </div>
    )
  }

  const descricaoMedicamentoUtilizados = () => {
    return(
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Descreva o(s) medicamento(s) utilizado(s) nos últimos tempos: 
        </MDTypography>
        <TextField
          style={{marginTop: 20}}
          id="nomeField"
          focused={true}            
          label="Medicamentos: "
          name="name"
          value={historicoMedicamentosUtilizadosUltimosTempos}
          
          fullWidth          
         />  
      </div>
    )
  }

  const condicoesDiagnosticadas = () => {
    return(
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Marque a(s) condiçõe(s) abaixo que tenham sido diagnosticadas ou tratadas por um médico:
        </MDTypography>
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoDiabetes}                          
              
              color="primary"
            />
          }label="Diabetes"
        />  
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoColesterolAlto}                          
              
              color="primary"
            />
          }label="Colesterol Alto"
        />  
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoHipertensao}                          
              
              color="primary"
            />
          }label="Hipertenção"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoDoencaCardiorrespiratoria}                          
              
              color="primary"
            />
          }label="Doenças cardiorrespiratória"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoAsma}                          
              
              color="primary"
            />
          }label="Asma"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica}                          
              
              color="primary"
            />
          }label={
            <div style={{ width: '150px', top: '10px' }}>
              "Epilepsia ou outra condição neurológica"
            </div>                          
          }          
        />                        
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoObesidade}                          
              
              color="primary"
            />
          }label="Obesodade"          
        />                        
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoAnemia}                          
              
              color="primary"
            />
          }label="Anemia"          
        />              
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoTDAH}                          
              
              color="primary"
            />
          }label="TDAH"          
        />              
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoTEA}                          
              
              color="primary"
            />
          }label="TEA"          
        />                                 
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoTOD}                          
              
              color="primary"
            />
          }label="TOD"          
        />   
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaOutrasChk}                          
              
              color="primary"
            />
          }label="Outras"          
        />      
        {diagnosticadaOutrasChk && (
          <TextField
            style={{marginTop: 20}}
            id="diagnosticadaTratadaMedicoOutras"
            focused={true}            
            label="Por favor, descreva a outra condição diagnosticada."
            name="diagnosticadaTratadaMedicoOutras"
            value={diagnosticadaTratadaMedicoOutras}
            
            fullWidth          
          /> 
        )}                      
      </div>
    )
  }

  const infoPeso = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Qual o peso ?
        </MDTypography>
        <Grid item xs={12} sm={2}>        
          <TextField
            style={{marginTop: 0, marginLeft: 8}}
            id="nomeField"
            focused={true}      
            type='number'      
            label=""
            name="peso"
            value={peso}            
            fullWidth   
                   
          /> 
        </Grid>
      </div>
    )

  }

  const infoEstatura = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Qual a estatura ?
        </MDTypography>
        <Grid item xs={12} sm={2}>
          <TextField
            style={{marginTop: 0}}
            id="nomeField"
            focused={true}            
            label=""
            name="estatura"
            value={estatura}
            type='number'            
            fullWidth  
                    
          />  
        </Grid>
      </div>
    )
  }

  const infoAdicionais = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Informações Adicionais
        </MDTypography>
        <Grid item xs={12} sm={12}>
          <TextField
            style={{marginTop: 0}}
            id="informacoesAdicionais"
            focused={true}            
            label="Informações: "
            name="informacoesAdicionais"  
            value={informacoesAdicionais}            
            fullWidth  
                    
          />  
        </Grid>
      </div>
    )
  }

  const possuiProblemaCoracao = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Possui problema de coração ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude}                          
                  
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude}                          
                  
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
      )
  }

  const senteDoresPeito = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Sente dores no peito ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={senteDoresPeitoRealizaAtividadeFisica}                          
                  
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!senteDoresPeitoRealizaAtividadeFisica}                          
                  
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
      )
  }

  const desequilibrioPerdaConsciencia = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Apresenta desequilibrio devido a tontura ou perda de conciência ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia}                          
                  
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia}                          
                  
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
    )
  }

  const tomaMedicamentoPressaoArterial = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Toma medicamento de pressão arterial ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tomaMedicamentoPressaoArterialOuCoracao}                          
                  
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!tomaMedicamentoPressaoArterialOuCoracao}                          
                  
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
    )
  }  

  /**
  const [possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude, setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude] = useState(false);
  const [senteDoresPeitoRealizaAtividadeFisica, setSenteDoresPeitoRealizaAtividadeFisica] = useState(false);
  const [apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia, setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia] = useState(false);
  const [possuiProblemaOsseoOuArticularPodePiorarAtividadeFisica, setPossuiProblemaOsseoOuArticularPodePiorarAtividadeFisica] = useState(false);
  const [tomaMedicamentoPressaoArterialOuCoracao, TomaMedicamentoPressaoArterialOuCoracao] = useState(false);
  const [algumaOutraRazaoNaoPraticarAtividadeFisica, setAlgumaOutraRazaoNaoPraticarAtividadeFisica] = useState(false);
  const [termoResponsabilidadeParaPraticaAtividadeFisicaAssinado, setTermoResponsabilidadeParaPraticaAtividadeFisicaAssinado] = useState(false);
  const [assinaturaResponsavel , setAssinaturaResponsavel] = useState('');
  */


  const handleSetInfoAnamnese = async (data) => {        
    setClienteId(data.clienteId);    
    setTitularId(data.titularId);
    setNome(data.nome);
    setGenero(data.genero);
    setDataNascimento(data.dataNascimento);
    setNomeResponsavel1(data.nomeResponsavel);  
    setEmailResponsavel1(data.emailResponsavel1);      
    
    setObjetivoEmagrecimento(data.objetivoEmagrecimento);       
    setObjetivoOcupacaoTempoLivre(data.objetivoOcupacaoTempoLivre);
    setObjetivoGastoEnergia(data.objetivoGastoEnergia);       
    setObjetivoDesenvolvimentoPsicomotor(data.objetivoDesenvolvimentoPsicomotor);
    setObjetivoSaudePreventiva(data.objetivoSaudePreventiva);        
    setObjetivoIndicacaoMedica(data.objetivoIndicacaoMedica);        
    setObjetivoIncentivarPraticaAtividadeFisica(data.objetivoIncentivarPraticaAtividadeFisica);               

    setPeso(data.peso);                
    setEstatura(data.estatura);    

    var dataHistorico = new Date(data.historicoDataUltimaAvaliacaoMedica);
    var day = dataHistorico.getDate();
    var month = dataHistorico.getMonth() + 1;
    var year = dataHistorico.getFullYear();
    dataHistorico = month  + "/" + day + "/" + year;        
    
    setHistoricoDataUltimaAvaliacaoMedica(dataHistorico);                                   

    setHistoricoAlunoSofreuIntervencaoCirurgica(data.historicoAlunoSofreuIntervencaoCirurgica);
    setHistoricoQualIntervencaoCirurgica(data.historicoQualIntervencaoCirurgica);        
    setHistoricoAlunoSofreuLesaoOsteomuscular(data.historicoAlunoSofreuLesaoOsteomuscular);
    setHistoricoQualLesaoOsteomuscular(data.historicoQualLesaoOsteomuscular);
    setHistoricoMedicamentosUtilizadosUltimosTempos(data.historicoMedicamentosUtilizadosUltimosTempos);
    
    setDiagnosticadaTratadaMedicoDiabetes(data.diagnosticadaTratadaMedicoDiabetes);        
    setDiagnosticadaTratadaMedicoColesterolAlto(data.diagnosticadaTratadaMedicoColesterolAlto);        
    setDiagnosticadaTratadaMedicoHipertensao(data.diagnosticadaTratadaMedicoHipertensao);         
    setDiagnosticadaTratadaMedicoDoencaCardiorrespiratoria(data.diagnosticadaTratadaMedicoDoencaCardiorrespiratoria);          
    setDiagnosticadaTratadaMedicoAsma(data.diagnosticadaTratadaMedicoAsma);         
    setDiagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica(data.diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica);
    setDiagnosticadaTratadaMedicoObesidade(data.diagnosticadaTratadaMedicoObesidade);       
    setDiagnosticadaTratadaMedicoAnemia(data.diagnosticadaTratadaMedicoAnemia);        
    setDiagnosticadaTratadaMedicoTDAH(data.diagnosticadaTratadaMedicoTDAH);
    setDiagnosticadaTratadaMedicoTEA(data.diagnosticadaTratadaMedicoTEA);        
    setDiagnosticadaTratadaMedicoTOD(data.diagnosticadaTratadaMedicoTOD);
    setDiagnosticadaOutrasChk(data.diagnosticadaOutrasChk);
    setDiagnosticadaTratadaMedicoOutras(data.diagnosticadaTratadaMedicoOutras); 
    
    setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude(data.possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude);
    setSenteDoresPeitoRealizaAtividadeFisica(data.senteDoresPeitoRealizaAtividadeFisica);
    setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia(data.apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia);
    setPossuiProblemaOsseoOuArticularPodePiorarAtividadeFisica(data.possuiProblemaOsseoOuArticularPodePiorarAtividadeFisica);
    setTomaMedicamentoPressaoArterialOuCoracao(data.tomaMedicamentoPressaoArterialOuCoracao);
    
    setAlgumaOutraRazaoNaoPraticarAtividadeFisica(data.algumaOutraRazaoNaoPraticarAtividadeFisica);
    setOutraRazaoNaoPraticarAtividadeFisica(data.outraRazaoNaoPraticarAtividadeFisica);
    
    setTermoResponsabilidadeParaPraticaAtividadeFisicaAssinado(data.termoResponsabilidadeParaPraticaAtividadeFisicaAssinado);
    
    setInformacoesAdicionais(data.informacoesAdicionais);        
    setAssinaturaResponsavel('Assinado');
  }      

  return (        
    <MDBox padding={8}>
      <Grid item xs={12}>         
        <img src={imgLogo} style={{marginLeft: -16, width: 300, height: 120}}/>  
        <MDTypography style={{fontWeigth: '700', fontSize: 24}}>Questionário Anamnese</MDTypography>                              
        {infoDadosPaciente()}
        {objetivosQuestion()}
        {dataUltimaAvaliacaoMedica()}
        {infoPeso()}
        {infoEstatura()}
        {intervencaoCirurgica()}
        {lesaoOsteomuscular()}
        {descricaoMedicamentoUtilizados()}
        {condicoesDiagnosticadas()}  
        {possuiProblemaCoracao()}
        {senteDoresPeito()}
        {desequilibrioPerdaConsciencia()}
        {tomaMedicamentoPressaoArterial()}
        {infoAdicionais()}
       
      </Grid>              
    </MDBox>
  );
}

/**
          * {dataUltimaAvaliacaoMedica()}
          {infoPeso()}
          {infoEstatura()}
          {intervencaoCirurgica()}
          {lesaoOsteomuscular()}
          {descricaoMedicamentoUtilizados()}
          {condicoesDiagnosticadas()}
 */

export default ListaAnamnese;

