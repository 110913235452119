import BaseService from '../../../infrastructure/services';
import { IAtividadesGetTypeSearch } from './SearchAtividadesType';

export const getAllAtividades = async (): Promise<IAtividadesGetTypeSearch[]> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Atividade/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);
  const responseData: IAtividadesGetTypeSearch[] = response.data;

  return responseData;
}