import { Alert, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function TimeoutUserLogout() {
  const [userLoggedIn, setUserLoggedIn] = useState(true); // Estado que controla se o usuário está logado
  const logoutTimeInMilliseconds =  600000; //5minutos          //2 * 60 * 60 * 1000; // Duas horas em milissegundos

  const navigate = useNavigate();

  useEffect(() => {    
    let timeoutId;
    
    const logoutUser = () => {
      setUserLoggedIn(false);    
    };
    
    timeoutId = setTimeout(logoutUser, logoutTimeInMilliseconds);    
    
    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(logoutUser, logoutTimeInMilliseconds);
    };
    
    /**reseta timeout quando recebe atividade do mouse */
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);
    
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, []);

  const handleLogoutUser = () => {
    localStorage.clear();
    navigate('/authentication/sign-in');
  }

  useEffect(() => {
    userLoggedIn === false && handleLogoutUser();
  }, [userLoggedIn])

  return (
    <div>
      {userLoggedIn ? (
        <div>                    
        </div>
      ) : (
        <div>
            <Alert
                severity={"error"}
                action={<>                           
                    <Button color="inherit" size="small"                      
                      style={{marginRight: 20}}
                    >
                      Ok
                    </Button>                                    
                    </>

                }
            >
                O usuário foi desconectado devido à inatividade.
            </Alert>        
        </div>
      )}
    </div>
  );
}

export default TimeoutUserLogout;