import { decodeToken } from "react-jwt";
import BaseService from "services/BaseService";
export async function verifyRolesAcess(screen, role){
    var result = false;    
    var dToken = decodeToken(localStorage.getItem('token'));    

    if (screen === 'clientes'){
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  
        
        try {
            const response = await BaseService.get(`/Cliente/ObterTodos?pageNumber=1&pageSize=5`, customConfig);                              
            result = true
        } catch (error) {
          error.response?.status === 403 ? result=false : result=true;               
        }                    
    }else if (screen === 'funcionarios'){
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  
        
        try {
          const response = await BaseService.get(`/Funcionario/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);                                                
            result = true;
        } catch (error) {            
          error.response?.status === 403 ? result=false : result=true; 
        }
        
    }else if (screen === 'atividades'){
      const customConfig = {            
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        },
      };  
    
      try {
        const response = await BaseService.get(`/Atividade/ObterTodos?pageNumber=1&pageSize=50`, customConfig);                                                
          result = true;
      } catch (error) {                    
        error.response?.status === 403 ? result=false : result=true; 
      }

    }

    
    return result;
}