/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, NativeSelect} from '@material-ui/core';
import { MDSearch } from "../../../components/MDSearch";

import axios from 'axios';
import input from "assets/theme/components/form/input";
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { format } from 'date-fns';
import authService from "../../../services/authService";
import clienteService from "../../../services/ClienteService";
import { Alert, AlertTitle, Snackbar, Tooltip, Typography } from "@mui/material";
import { CallCepInfo } from "../../../services/Utils/callCepInfo";
import DeleteIcon from '@mui/icons-material/Delete';
import { ReAuth } from "../../authentication/reAuth";
import { isExpired } from "react-jwt";
import { verifyRolesAcess } from "services/Utils/verifyRolesAcess";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import CpfValidation from "../../../services/Utils/validationCpf";
import ShareIcon from '@mui/icons-material/Share';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ListAltIcon from '@mui/icons-material/ListAlt';
import config from "../../../Config/config";

function CadClientes() {
  const navigate = useNavigate();
  const focusRef = useRef(null); 
  const cpfValidation = new CpfValidation();   

  const [selectedVinculo, setSelectedVinculo] = useState('');
  const [checked, setChecked] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchResult, setSearchResult] = useState('');
  const [titular, setTitular] = useState('');

  //hook cad0
  const [tipoPessoaFisica, setTipoPessoaFisica] = useState(true);
  const [id, setId] = useState('');
  const [ativo, setAtivo] = useState(false);
  const [contato, setContato] = useState(false);  
  const [nome, setNome] = useState('');
  const [dtCadastro, setDtCadastro] = useState(new Date());
  const [dtNasc, setDtNasc] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('Bebedouro');
  const [uf, setUf] = useState('');
  const [referencia, setReferencia] = useState('');
  const [diaPag, setDiaPag] = useState(10);
  const [salario, setSalario] = useState(0.00);
  const [acessSoftware, setAcessSoftware] = useState(false);
  const [user, setUser] = useState('');
  const [senha, setSenha] = useState('');
  const [observacao, setObservacao] = useState('');  
  const [congAtividade, setCongAtividade] = useState(false);
  const [idTiular, setIdTitular] = useState('');
  const [alertError, setAlertError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [alertEditSuccess, setAlertEditSuccess] = useState(false);  
  const [alertInfo, setAlertInfo] = useState(false);
  const [msgInfo, setMsgInfo] = useState(false);   
  const [keyBackSpace, setKeyBackSpace] = useState(false); 
  const [tokenExpired, setTokenExpired] = useState(false);
  const [reAuthOk, setReAuthOk] = useState(false);
  const [invalidPermission, setInvalidPermission] = useState(false);
  const [loadCep, setLoadCep] = useState(false);
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [errorCallCep, setErrorCallCep] = useState(false);
  const [cepNotFound, setCepNotFound] = useState(false);
  const [urlSend, setUrlSend] = useState('');
  const [objDataClienteEdit, setObjDataClienteEdit] = useState(null);
  
 

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const infoId = queryParams.get('infoId');  

  function formatDDMMYYYY(data) {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
    const ano = data.getFullYear();  
    return `${dia}/${mes}/${ano}`;
  }

  function formatRG(rg) {   
    var valueSetRg = '';        
    rg = rg.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');  
    rg.length <= 12 ? valueSetRg=rg : valueSetRg=rg.slice(0, -1) ;
    return valueSetRg;
  }

  function formatCPF(cpf) {                      
    var valueSetCpf = '';
    cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');      
    cpf.length <= 14 ? valueSetCpf=cpf : valueSetCpf=cpf.slice(0, -1);    
    
    return valueSetCpf;
  }

  function formatPhoneNumber(phoneNumber) {              
    phoneNumber = phoneNumber.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  
    return phoneNumber;
  }


  const handleIdTitular = (infoIdTitular) => {         
    setIdTitular(infoIdTitular);    
  };  

  const handleNameTitular = (infoNameTitular) => {        
    setTitular(infoNameTitular);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const citys = [
    { id: 1, name: 'São Paulo' },
    { id: 2, name: 'Bebedouro' },
    { id: 3, name: 'Ribeirão Preto' },    
    { id: 3, name: 'Araraquara' },  
  ];

  const arrDiasPag = [    
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },    
    { id: 4, name: '4' },  
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },    
    { id: 8, name: '8' },  
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
    { id: 24, name: '24' },
    { id: 25, name: '25' },
    { id: 26, name: '26' },
    { id: 27, name: '27' },
    { id: 28, name: '28' },
    { id: 29, name: '29' },
    { id: 30, name: '30' },
    { id: 31, name: '31' },       
  ];  

  function formatDateInfos(inputDate){              
    var dateSplit = inputDate.split('/');    
    var formattedDate = new Date(dateSplit[2], dateSplit[0], dateSplit[1]);
    
    var year = dateSplit[2]
    var month = dateSplit[1]
    var day = dateSplit[0]    

    var newDayDate = ''
    var newMonthDate = ''

    if (!day.startsWith("0") && day < 10){
      newDayDate = '0'+day
    }

    if (!month.startsWith("0") && month < 10){
      newMonthDate = '0'+month
    }        
    
    var dateFormat = year + '-' + (newMonthDate === '' ? month : newMonthDate)  + '-' + (newDayDate === '' ? day : newDayDate)    
    return dateFormat;    
  }

  const handleCallCepInfo  = async (cep) => {
    setLoadCep(true);
    try {      
      var resultInfoCep = await CallCepInfo(cep);      
      if (resultInfoCep.erro){            
        setErrorCallCep(false);        
        setCepNotFound(true);
        setEndereco('');
        setBairro('');
        setCidade('');
        setUf(''); 
        setLoadCep(false);
      }else{            
        setErrorCallCep(false);
        setCepNotFound(false);
        setEndereco(resultInfoCep.logradouro);
        setBairro(resultInfoCep.bairro);
        setCidade(resultInfoCep.localidade);
        setUf(resultInfoCep.uf); 
        setLoadCep(false);
      }       
    } catch (error) {
      setCepNotFound(false);
      setErrorCallCep(true);
    }
  }

  function adicionarZeroEsquerda(valor) {
    return String(valor).padStart(2, '0');
  }
  
  function formatarData(dataOriginal) {
    const partes = dataOriginal.split('/');
    if (partes.length === 3) {
      const dia = adicionarZeroEsquerda(partes[0]);
      const mes = adicionarZeroEsquerda(partes[1]);
      const ano = partes[2];
      return `${dia}/${mes}/${ano}`;
    }
    return dataOriginal; // Se a data não tiver o formato esperado, retorna como está
  }

  const handleSubmitCliente = async (event) => {
    event.preventDefault(); 

    if (invalidEmail && invalidCpf)
    {
      setAlertError(true);
      setMsgError('CPF e E-mail digitado são inválidos');   
    }else if (invalidEmail) {
      setAlertError(true);
      setMsgError('E-mail digitado é inválido');   
    }else if (invalidCpf){
      setAlertError(true);
      setMsgError('CPF digitado é inválido');   
    }else
    {
      var isExpiredInfo = isExpired(localStorage.getItem('token'));    
      setTokenExpired(isExpiredInfo);
      

      let dtNascimentoFormat = formatDateInfos(dtNasc);
  
      let objCliente = {            
        nome: nome,
        telefoneCelular: telefone,
        dataNascimento: dtNascimentoFormat,
        telefoneFixo: telefone2,
        rg: rg,
        cpf: cpf,
        cnpj: cpf,
        pessoaFisica: tipoPessoaFisica,
        email: email,
        logradouro: endereco,
        numero: numero,
        bairro: bairro,
        cidade: cidade,
        cep: cep,
        referencia: referencia,
        uf: uf,
        observacoes: observacao,      
        clienteDesde: formatDateInfos(dtCadastro),
        melhorDiaPagamento: diaPag,        
        congelarAtividades: congAtividade,
        ativo: ativo,  
        contato: contato,     
        titularId: idTiular === '' ? null : idTiular
      }
  
      let objPutCliente = {  
        id: id,          
        nome: nome,
        telefoneCelular: telefone,
        dataNascimento: dtNascimentoFormat,
        telefoneFixo: telefone2,
        rg: rg,
        cpf: cpf,
        //cnpj: cpf,
        pessoaFisica: tipoPessoaFisica,
        email: email,
        logradouro: endereco,
        numero: numero,
        bairro: bairro,
        cidade: cidade,
        cep: cep,
        referencia: referencia,
        uf: uf,
        observacoes: observacao,      
        clienteDesde: formatDateInfos(dtCadastro),
        melhorDiaPagamento: diaPag,        
        congelarAtividades: congAtividade,
        ativo: ativo,   
        contato: contato,    
        titularId: idTiular === '' ? null : idTiular,
        mensagemRetorno: ''
      }                       
      
      var result = {};    
      if (infoId !== null){  //Editar                                    
          await clienteService.putCliente(objPutCliente, objPutCliente.id).then(() => {
            setAlertEditSuccess(true);
            setMsgSuccess('Cliente Atualizado com sucesso !');                  
          }).catch((error) => {
            console.error('error ', JSON.parse(error));
            setAlertError(true);
            setMsgError(error.message);        
          })
  
      }else{                             
        result = await clienteService.postCliente(objCliente).then(() => {            
            if (result){
              setAlertSuccess(true);

              setMsgSuccess('Cliente Inserido com sucesso !')      
            }else{
              setAlertSuccess(false);
              setMsgSuccess('Cliente Inserido com sucesso !')      
            }
          }).catch((error) => {
            console.error('error ', error);
            //console.error('error ', JSON.parse(error.config.data.errors));
            setAlertError(true);
            setMsgError(error.response.data.mensagemRetorno);
          }
        )
        
      }               
    }


  }  
  const handlePrepareInputEditar = (filterNew) => {        

    //var filterNew =  JSON.parse(filterData);        
    setId(filterNew.id);
    setAtivo(filterNew.ativo);
    setContato(filterNew.contato);
    setBairro(filterNew.bairro);
    setCep(filterNew.cep);
    setCidade('Bebedouro');
    var dataCad = new Date(filterNew.clienteDesde);
    var day = dataCad.getDate();
    var month = dataCad.getMonth() + 1;
    var year = dataCad.getFullYear();
    dataCad = day + "/" + month + "/" + year;
    setDtCadastro(dataCad);
    //setCnpj(); criar
    setCongAtividade(filterNew.congelarAtividades === "" ? 
                      false 
                    : filterNew.congelarAtividades === "T" ? true : false
                    );
    setCpf(filterNew.cpf);
    const originalDate = filterNew.dataNascimento;
    var dateObject = new Date(filterNew.dataNascimento);
    var dayOriginalDate = dateObject.getDate();
    var monthOriginalDate = dateObject.getMonth() + 1;
    var yearOriginalDate = dateObject.getFullYear();
    var formattedDate =  dayOriginalDate + "/" + monthOriginalDate + "/" + yearOriginalDate;    
    setDtNasc(formattedDate);        
    setEmail(filterNew.email);
    setEndereco(filterNew.logradouro);
    setDiaPag(filterNew.melhorDiaPagamento);        
    setNome(filterNew.nomeCliente);
    setNumero(filterNew.numero);
    setObservacao(filterNew.observacoes);
    setTipoPessoaFisica(filterNew.pessoaFisica ? true : false);
    setReferencia(filterNew.referencia);
    setRg(formatRG(filterNew.rg));
    setTelefone(filterNew.telefoneCelular);
    setTelefone2(filterNew.telefoneFixo);
    setUf(filterNew.uf);
    setTitular(filterNew.nomeTitular);
    setIdTitular(filterNew.titularId);
  }

  const customButtonStyle = {
    backgroundColor: '#7b0b99'    
  };

  const handleBackSpaceKeyPress = (event) => {
    if (event.keyCode === 8) {
      setKeyBackSpace(true);      
    }else{
      setKeyBackSpace(false);
    }
  }


  const handleSetDataNasc = (value) => {     
      var newValue = ''
      let lastCharBar = false;
      let lastChar = value.charAt(value.length - 1);

      if (lastChar === '/' && keyBackSpace){        
        lastCharBar = true;
      }else{        
        if(value.length === 2){   
          newValue = '/'       
        }else if (value.length === 5){          
          newValue = '/'       
        }
      }              
    
      lastCharBar ? setDtNasc(value.substring(0, value.length - 1)) : setDtNasc(value+newValue);
  }

  const handleDeleteTitular = () => {    
    setIdTitular('');
    setTitular('');
  }

  useEffect(async () => {       
    
    cpf.length === 14 && handleValidationCpf();

  }, [cpf])

  useEffect(async () => {         
    if ((errorCallCep || cepNotFound) && (cep.length === 0)){
      setErrorCallCep(false);
      setCepNotFound(false); 
      setLoadCep(false);      
    }
  }, [cep])

  useEffect(async () => {   
    var invalidPermissionInfo = await verifyRolesAcess('clientes');    
    setInvalidPermission(!invalidPermissionInfo);

    if (infoId != null){            
      
      var result = await clienteService.getByID(infoId.replace(/"/g, ''));
      console.log('filterData ', JSON.stringify(result.data));

      handlePrepareInputEditar(result.data)      
    }else{
      setDtCadastro(formatDDMMYYYY(new Date()));
      setAtivo(true);
    }

    var isExpiredInfo = isExpired(localStorage.getItem('token'));    
    setTokenExpired(isExpiredInfo);
  }, [])

  const handleReAuthInfo = (value) => {
    var isExpiredInfo = isExpired(localStorage.getItem('token'))
    setTokenExpired(isExpiredInfo);         
  }

  function handleClose (option){
    navigate(`/Clientes/?telefone=${telefone}`);        
  }

  const handleValidationCpf = () => {    
    var validation = cpfValidation.GetValidationCpf(cpf.replace(/[.-]/g, ''));    
    validation ? setInvalidCpf(false) : setInvalidCpf(true);
  }

  const handleValidationEmail = () => {    
    if (email != ''){
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      emailRegex.test(email) ? setInvalidEmail(false) : setInvalidEmail(true);
    }
  }

  const handlePreencherAnamnese = () => {
    navigate(`/anamnese/?idInfo=${id}`)    
  }

  const handleWhatsAppClick = async () => {   
    var phoneSendAnamnese = '';    
    var dtNascimento = formatDateInfos(dtNasc);
    const currentDate = new Date();

    const data = new Date(currentDate);
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // +1 porque janeiro é 0
    const dia = String(data.getDate()).padStart(2, '0');

    const dataAtualFormatada = `${ano}-${mes}-${dia}`;              
    const dataAtual = new Date(dataAtualFormatada);          
    const dataNascimento = new Date(dtNascimento);            
    const diferencaEmMilissegundos = dataAtual - dataNascimento;

    const milissegundosPorAno = 1000 * 60 * 60 * 24 * 365.25;
    const idade = Math.floor(diferencaEmMilissegundos / milissegundosPorAno);                   

    if (idTiular === '00000000-0000-0000-0000-000000000000' && (idade <= 18)){      
      setAlertError(true);
      setMsgError('Informe o titular.')
    }else{
      if (idTiular != '00000000-0000-0000-0000-000000000000'){
  
        await clienteService.getByID(idTiular)
          .then((result) => {
            if (idade <= 18){            
              if (!result.data.nomeCliente){
                setAlertError(true);
                setMsgError('Não foi possivel compartilhar Anamnese, Titular sem nome cadastrado.')
              }else if (!result.data.telefoneCelular){
                setAlertError(true);
                setMsgError('Não foi possivel compartilhar Anamnese, Titular sem telefone cadastrado.')
              }else if (!result.data.email){
                setAlertError(true);
                setMsgError('Não foi possivel compartilhar Anamnese, Titular sem e-mail cadastrado.')
              }else{
                phoneSendAnamnese = result.data.telefoneCelular;          
                window.open(`https://web.whatsapp.com/send/?phone=${phoneSendAnamnese}&text=${config.params.urlBase}/anamnese/?idInfo=${id}`);    
              }
            }else{            
              phoneSendAnamnese = result.data.telefoneCelular;          
              window.open(`https://web.whatsapp.com/send/?phone=${phoneSendAnamnese}&text=${config.params.urlBase}/anamnese/?idInfo=${id}`);
            }
          })
  
      }else{
        phoneSendAnamnese = telefone;
        const url = `https://web.whatsapp.com/send/?phone=${phoneSendAnamnese}&text=${config.params.urlBase}/anamnese/?idInfo=${id}`;     
        window.open(url);
      }
    }

    

  };

  const handleCallSendUrlWhats = () => {
    window.open(urlSend);
  }

  useEffect(() => {
    if (urlSend != ''){
      handleCallSendUrlWhats();
    }
 
  }, [urlSend])

  const handleListAnamnese = () => {
    navigate(`/clientes/cadClientes/anamnese/?idInfo=${id}&nameInfo=${nome}`)    
  }

  return (
    <>    
    <DashboardLayout>
      <DashboardNavbar />
      {alertSuccess ? (
        <>
          <Snackbar open={{}} autoHideDuration={1500} onClose={()=> {handleClose()}}>
            <Alert onClose={()=> {handleClose()}} severity="success" sx={{ width: '100%' }}>
              Cadastro inserido com sucesso !
            </Alert>
          </Snackbar>
        </>
      ):(
        <>
        {alertEditSuccess && (
          <Snackbar open={{open}} autoHideDuration={1500} onClose={()=>{handleClose()}}>
            <Alert onClose={()=>{handleClose()}} severity="success" sx={{ width: '100%' }}>
              Atualização realizada com sucesso !
            </Alert>
          </Snackbar>
        )}
        </>
      )}
          
      {tokenExpired ? (
        <>
          <ReAuth handleAuth={handleReAuthInfo} />
        </>
      ):(
        <>
          {invalidPermission ? (
            <>
              <Alert severity="error">
                Você não tem permissão para acessar esse recurso.
              </Alert>
            </>
          ):(
            <>
              <MDBox pt={6} pb={3}>                
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white" flexDirection={"column"}>
                          Cadastro de clientes                                                                        
                        </MDTypography>                
                      </MDBox>                                                    
                      
                      <form onSubmit={(event) => {handleSubmitCliente(event)}}>                            
                        <Grid container spacing={2} p={4}>
                          <Grid item xs={12} sm={7} md={12} lg={8} xl={8}>
                            <TextField
                              id="nomeField"
                              focused={true}
                              ref={focusRef}
                              label="Nome"
                              name="name"
                              value={nome}
                              onChange={(event) => {setNome(event.target.value)}}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="Data Nascimento"
                              name="date"
                              value={dtNasc}
                              onChange={(event) => {handleSetDataNasc(event.target.value)}}
                              placeholder="DD/MM/YYYY" 
                              fullWidth                      
                              onKeyDown={(event) => {handleBackSpaceKeyPress(event)}}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={2} >
                            <TextField
                              label="Data Cadastro"
                              name="date"
                              value={dtCadastro}                              
                              fullWidth
                            />
                          </Grid>
                          <Grid item  xs={12} sm={2}>
                            <TextField
                              label="Rg"
                              name="rg"
                              value={rg}
                              onChange={(event) => {setRg(formatRG(event.target.value))}}
                              fullWidth                              
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="CPF"
                              name="cpf"
                              value={cpf}
                              onChange={(event) => {setCpf(formatCPF(event.target.value))}}                              
                              fullWidth                           
                            />
                            {invalidCpf && <div style={{color: 'red', fontSize: 10}}>O CPF digitado não existe.</div>}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Email"
                              name="email"
                              value={email}
                              onChange={(event) => {setEmail(event.target.value)}}
                              onBlur={handleValidationEmail}
                              fullWidth                        
                            />
                            {invalidEmail && <div style={{color: 'red', fontSize: 10}}>E-mail digitado é inválido</div>}
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="Telefone"
                              name="phone"
                              value={telefone}
                              onChange={(event) => {setTelefone(formatPhoneNumber(event.target.value))}}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="Telefone 2"
                              name="phone"
                              value={telefone2}
                              onChange={(event) => {setTelefone2(formatPhoneNumber(event.target.value))}}
                              fullWidth                              
                            />
                          </Grid>
                          <Grid item xs={11} sm={2}>
                            <TextField
                              label="Cep"
                              name="cep"
                              value={cep}
                              onChange={(event) => {setCep(event.target.value)}}                      
                              onBlur={() => {handleCallCepInfo(cep)}}
                              fullWidth                              
                            />        
                            {errorCallCep && <div style={{color: 'red', fontSize: 10}}>Falha ao buscar CEP, tente novamente.</div>}                        
                            {cepNotFound && <div style={{color: 'red', fontSize: 10}}>CEP não encontrado.</div>}                        
                          </Grid>
                          <Grid item xs={1} sm={1}>
                            {!loadCep ? (
                              <>
                              <Tooltip title="Digite o CEP para o preenchimento dos campos: .Endereço | .Bairro | .Cidade | .Uf" arrow placement="left">                                
                                  <InfoIcon style={{marginTop: 26}}/>  
                                </Tooltip>                                
                              </>
                            ):(
                              <>
                                <CircularProgress fontSize={"small"} style={{marginTop: 16}}/>
                              </>
                            )}
                          </Grid>
                          
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Endereço"
                              name="address"
                              value={endereco}
                              onChange={(event) => {setEndereco(event.target.value)}}
                              fullWidth   

                            />
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <TextField
                              label="Numero"
                              name="numero"
                              value={numero}
                              onChange={(event) => {setNumero(event.target.value)}}
                              fullWidth  
                                                          
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="Bairro"
                              name="bairro"
                              value={bairro}
                              onChange={(event) => {setBairro(event.target.value)}}
                              fullWidth                                                 
                            />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <TextField
                              label="Cidade"
                              name="Cidade"
                              value={cidade}
                              onChange={(event) => {setCidade(event.target.value)}}
                              fullWidth                                                            
                            />
                          </Grid>
                          <Grid item xs={2} sm={1}>
                            <TextField
                              label="UF"
                              name="uf"
                              value={uf}
                              onChange={(event) => {setUf(event.target.value)}}
                              fullWidth                                                        
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Referencia"
                              name="referencia"
                              value={referencia}
                              onChange={(event) => {setReferencia(event.target.value)}}
                              fullWidth                              
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel id="city-label">Melhor Dia de Pagamento</InputLabel>
                              <Select
                                labelId="city-label"
                                id="diaPag"
                                name="diaPag"
                                value={diaPag}
                                onChange={(event) => {setDiaPag(event.target.value)}}
                              >
                                {arrDiasPag.map((dp) => (
                                  <MenuItem key={dp.id} value={dp.name}>
                                    {dp.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={congAtividade}
                                  onChange={() => {setCongAtividade(!congAtividade)}}
                                  color="primary"
                                />
                              }
                              label="Congelar atividades"
                            />               
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={ativo}
                                  onChange={() => {setAtivo(!ativo)}}
                                  color="primary"
                                />
                              }
                              label="Ativo"
                            />               
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={contato}
                                  onChange={() => {setContato(!contato)}}
                                  color="primary"
                                />
                              }
                              label="Contato - Aula experimental"
                            />               
                          </Grid>

                          <Grid item xs={6} sm={8}>
                            <TextField
                              label="Titular"
                              name="Titular"
                              value={titular}
                              onChange={(event) => {setTitular(event.target.value)}}
                              fullWidth                              
                              disabled
                            />
                          </Grid>  
                          <Grid item xs={3} sm={1} mt={2}>
                            <Button
                            variant="contained" color={'info'}
                              onClick={handleDeleteTitular}
                            >
                              <DeleteIcon fontSize="small"/>
                            </Button>
                          </Grid>  
                          <Grid item xs={3} sm={3} mt={2} >
                          <Button
                            variant="contained" color={'info'}
                              onClick={() => {setModalOpen(!isModalOpen)}}
                            >
                            <SearchIcon fontSize="small"/>  
                            </Button>                                
                          </Grid>                                   
                          
                          <Grid item xs={12}>
                            <TextField                      
                              label="Observação"
                              variant="outlined"
                              value={observacao}                      
                              onChange={(event) => {setObservacao(event.target.value)}}
                              fullWidth
                            /> 
                          </Grid> 

                          {id !== '' && (
                            <>
                              <Grid item xs={11} sm={3} mt={4}>
                                <InputLabel id="">Preencher Anamnese</InputLabel>
                                <Button
                                  variant="contained" color={'info'}
                                  onClick={() => {handlePreencherAnamnese()}}
                                  style={{marginTop: 8}}
                                >                              
                                  <AddLinkIcon fontSize="small" />
                                </Button>
                              </Grid>  
                              <Grid item xs={11} sm={3} mt={4} >
                                <InputLabel id="city-label">Compartilhar Anamnese</InputLabel>
                                <Button
                                  variant="contained" color={'info'}
                                  onClick={() => {handleWhatsAppClick()}}
                                  style={{marginTop: 8}}
                                >
                                  
                                  <ShareIcon fontSize="small"/>  
                                </Button>                                
                              </Grid> 
                              {true && (
                                <Grid item xs={11} sm={3} mt={4} >
                                  <InputLabel id="city-label">Visualizar Anamnese(s)</InputLabel>
                                  <Button
                                    variant="contained" color={'info'}
                                    onClick={() => {handleListAnamnese()}}
                                    style={{marginTop: 8}}
                                  >
                                    
                                    <ListAltIcon fontSize="small"/>  
                                  </Button>                                
                                </Grid> 
                              )}

                                                   
                            </>
                          )}

                        </Grid>                  
                          {alertError ? (
                            <>
                              <Alert
                                severity={alertError ? "error" : "success"}
                                action={
                                  <>                                                        
                                      <Button color="inherit" size="small"
                                        onClick={() => {setAlertError(false), setMsgError('')}}
                                        style={{marginRight: 60}}
                                      >
                                        OK
                                      </Button>
                                  </>
                                  }                                                                                  
                              >
                                {alertError ? msgError : msgSuccess}
                              </Alert>
                            </>
                          ):(
                            <>
                                <Grid item xs={12} p={4}>
                                  <Button variant="contained" color="primary" type="submit" fullWidth                                                                  
                                  >
                                    Salvar
                                  </Button>
                                </Grid>  
                              </>
                          )}             
                          
                          {!invalidPermission && (
                            <MDSearch
                              isOpen={isModalOpen}
                              onClose={handleModalClose}                              
                              infoIdTitular={handleIdTitular}
                              infoNameTitular={handleNameTitular}
                            />              
                          )}
                          
                      </form>                                                                        
                    </Card>           

                  </Grid>              
                </Grid>
              </MDBox>        
            </>
          )}
        </>
      )}
    </DashboardLayout>
    </>
  );
}

export default CadClientes;
