import TitleScreenCrud from '../../../shared/TitleScreenCrud';
import MDInput from '../../../shared/MDInput';

import * as React from 'react';
import TurmaTableData from './TurmaTableData';
import DataTable from '../../../shared/Tables/DataTable';
import { Button } from '@mui/material';
import { Link, useNavigate   } from 'react-router-dom';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { ContainerGoCadastro } from './TurmaForm.styled'

export default function ContratacaoForm(){
  const navigate = useNavigate();

  const [clickedRowIndex, setClickedRowIndex] = React.useState(null); 
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchFilter, setSearchFilter] = React.useState(null);  
  const [typeBotton, setTypeBotton] = React.useState('');   
  const [objFull, setObjFull] = React.useState([]);

  const handleTypeBotton = (rowIndex) => {
    setTypeBotton(rowIndex);
  };

  const handleObjFull = (obj) => {            
    setObjFull(obj);
  }  

  const { columns, rows } = TurmaTableData(searchFilter, handleTypeBotton, handleObjFull);  

  const customInputStyle = {
    width: '100%',
    padding: 26,
    marginTop: -16 
  };

  const alignEndItem = {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  };  

  const handleRowClick = (rowIndex) => {
    setClickedRowIndex(rowIndex);
  };    

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrepareData = (nameFilter) => {    
    setSearchFilter(nameFilter);    
  }    
  
  const handleFilterEditar = async () => {            
    const rangeInfo = currentPage * 5;    
    const newObj = [];
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    })     
    navigate(`/Cadturmas/?id=${JSON.stringify(newObj[clickedRowIndex].id)}`);                    
  }

  const handleCallDetalheTurma = () =>{      
    const rangeInfo = currentPage * 5;    
    const newObj = [];
    objFull.map((result, index) => {      
      if (index+1 > (rangeInfo-5) && index < (rangeInfo)){
        newObj.push(result);
      }
    })                 

    navigate(`/DetalhesTurma/?id=${JSON.stringify(newObj[clickedRowIndex].id)}`);                    
  }

  React.useEffect(() => {
    console.log('typeBotton ', typeBotton);
    
    if (typeBotton === 'Editar'){
      handleFilterEditar();      
      setTypeBotton('');
    } 

    if (typeBotton === 'Detalhe'){
      handleCallDetalheTurma();
    }
  }, [typeBotton]);   
  

  return(
    <TitleScreenCrud title={'Turmas'}>  
      <MDInput style={customInputStyle} onChange={(event) => {handlePrepareData(event.target.value)}}                 
        placeholder="Filtrar"
      >
      </MDInput>  
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={true}
        showTotalEntries={false}
        noEndBorder
        onRowClick={handleRowClick} 
        pageNumber={handlePageChange}
      />
      <ContainerGoCadastro>        
        <Link to={'/Cadturmas'} style={alignEndItem}>
          <Button style={{ width: 50, height: 50, backgroundColor: '#FFF', borderRadius: 50 }}>
            <AddCircleRoundedIcon style={{ width: 40, height: 40, color: '#ba79e1' }}/>                      
          </Button>
        </Link>        
      </ContainerGoCadastro>
          
    </TitleScreenCrud> 
  )
}