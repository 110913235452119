/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import atividadeTableData from "./data/AtividadesTableData";
import projectsTableData from "./data/projectsTableData";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import React, { useEffect , useRef, useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import { MDSearch } from "components/MDSearch";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { Box } from "@material-ui/core";
import { Typography } from '@material-ui/core';
import { Event, Schedule } from '@material-ui/icons';
import { makeStyles } from '@mui/styles';
import { Alert, ButtonBase } from "@mui/material";
import atividadeService from "../../../services/AtividadeService";
import Snackbar from "@mui/material/Snackbar";
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { FlashOffRounded } from "@mui/icons-material";
import MDModalQuestion from "components/MDModalQuestion";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {    
    flex: 1      
  },    
  boxCenter: {
    alignSelf: 'center'
  }      
}));

function CadAtividadesNew() {  
  const navigate = useNavigate();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [selectedDay, setSelectedDay] = useState(" ");
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedTime2, setSelectedTime2] = useState('');
  const [countDias, setCountDias] = useState([]);
  const [mapDias, setMapDias] = useState([]);
  const [checkedDomingo, setCheckedDomingo] = useState(false);
  const [checkedSegunda, setCheckedSegunda] = useState(false);
  const [checkedTerca, setCheckedTerca] = useState(false);
  const [checkedQuarta, setCheckedQuarta] = useState(false);
  const [checkedQuinta, setCheckedQuinta] = useState(false);
  const [checkedSexta, setCheckedSexta] = useState(false);
  const [checkedSabado, setCheckedSabado] = useState(false);
  const [arrayPostAtivit, setArrayPostAtivit] = useState([]);
  const [descricao, setDescricao] = useState('');

  const [alertError, setAlertError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);  

  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorTimes, setErrorTimes] = useState(false);
  const [errorTimesBeginDiff, setErrorTimesBeginDiff] = useState(false);
  const [errorContainTimeCad, setErrorContainTimeCad] = useState(false);  
  const [msgContainTimeCad, setMsgContainTimeCad] = useState('');  
  const [errorEqualTime, setErrorEqualTime] = useState(false); 
  const [errorDias, setErrorDias] = useState(false);
  const [idAtividade, setIdAtividade] = useState('');  
  const [mouseOnDelete, setMouseOnDelete] = useState(false);
  const [indexOnDelete, setIndexOnDelete] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);

  const [infoModalHorario, setInfoModalHorario] = useState('');    
  const [infoModalDias, setinfoModalDias] = useState('');
  const [infoIdModal, setInfoIdModal] = useState('');
  const [renderListarrDiasHorasAdd, setRenderListarrDiasHorasAdd] = useState(false);

  const [showSaveEdit, setShowSaveEdit] = useState(false);

  const [arrDiasHorasAdd, setArrDiasHorasAdd] = useState([]);     

  const inputRef = useRef(null);  
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');  

  const handleRowClick = (rowIndex) => {
    setClickedRowIndex(rowIndex);
  };    

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const arrayDays = 
  ['Segunda', 'Terca', 'Quarta', 'Quinta', 'Sexta'];  

  var arrayDiasSelected = [];  
  const classes = useStyles();

  async function validationInfos(){
    let validationStatus = true;

    if (descricao === ''){
      setErrorDescricao(true);      
      validationStatus = false;
    }else{
      setErrorDescricao(false);      
    }

    const horaAtualDate = new Date(`2023-01-01T${selectedTime}`);
    const horaAtualDate2 = new Date(`2023-01-01T${selectedTime2}`);
    
    const existDateEquals = arrDiasHorasAdd.some(x => {
      return ((x.HorarioAte === selectedTime2+':00') && (x.HorarioDe === selectedTime+':00'));
    });

    const verifyHourData = arrDiasHorasAdd.filter(x => {
      return ((x.HorarioAte === selectedTime2+':00') && (x.HorarioDe === selectedTime+':00'));
    });

    const ExistDateAndHourEquals = verifyHourData.some(x => {
      return ((x.SegundaFeira === checkedSegunda) && (x.TercaFeira === checkedTerca) 
              && (x.QuartaFeira === checkedQuarta ) && (x.QuintaFeira === checkedQuinta)
              && (x.SextaFeira === checkedSexta && x.Sabado === checkedSabado)
              && (x.Domingo === checkedDomingo));
    });  
    
    function ExistHorasInDay(){
      var result = '';

      if (checkedSegunda){
        const segundaEqual = verifyHourData.some(x => {
          return ((x.SegundaFeira === checkedSegunda));
        });

        if (segundaEqual){
          result = 'Já existe esse horário cadastrado para Segunda'
        }
      }

      if (checkedTerca){
        const tercaEqual = verifyHourData.some(x => {
          return ((x.TercaFeira === checkedTerca));
        });

        if (tercaEqual){
          result = 'Já existe esse horário cadastrado para Terça'
        }
      }

      if (checkedQuarta){
        const quartaEqual = verifyHourData.some(x => {
          return ((x.QuartaFeira === checkedQuarta));
        });

        if (quartaEqual){
          result = 'Já existe esse horário cadastrado para Quarta'
        }
      }

      if (checkedQuinta){
        const quintaEqual = verifyHourData.some(x => {
          return ((x.QuintaFeira === checkedQuinta));
        });

        if (quintaEqual){
          result = 'Já existe esse horário cadastrado para Quinta'
        }
      }

      if (checkedSexta){
        const sextaEqual = verifyHourData.some(x => {
          return ((x.SextaFeira === checkedSexta));
        });

        if (sextaEqual){
          result = 'Já existe esse horário cadastrado para Sexta'
        }
      }

      if (checkedSabado){
        const sabadoEqual = verifyHourData.some(x => {
          return ((x.Sabado === checkedSabado));
        });

        if (sabadoEqual){
          result = 'Já existe esse horário cadastrado para Sabado'
        }
      }

      if (checkedDomingo){
        const domingoEqual = verifyHourData.some(x => {
          return ((x.Domingo === checkedDomingo));
        });

        if (domingoEqual){
          result = 'Já existe esse horário cadastrado para Domingo'
        }
      }

      return result;
    }
    
    if (existDateEquals){       
      var existHorasInDay = ExistHorasInDay();
      if (existHorasInDay != ''){
        setErrorContainTimeCad(true);
        setMsgContainTimeCad(existHorasInDay)
        validationStatus = false;
      }            
    }else{
      setErrorContainTimeCad(false);
      setMsgContainTimeCad('');
    }
    
    if (selectedTime === selectedTime2){
      setErrorEqualTime(true);
      validationStatus = false;
    }else{
      setErrorTimesBeginDiff(false);
    }

    if (horaAtualDate > horaAtualDate2) {
      setErrorTimesBeginDiff(true);
      validationStatus = false;
    }else{
      setErrorTimesBeginDiff(false);
    }    

    if (selectedTime === '' || selectedTime2 === ''){
     setErrorTimes(true)
     validationStatus = false;
    }else{
     setErrorTimes(false);      
    }  

    if (!checkedDomingo && !checkedSegunda && !checkedTerca && !checkedQuarta && 
    !checkedQuinta && !checkedSexta && !checkedSabado) {      
     setErrorDias(true);
     validationStatus = false;
    }else{
     setErrorDias(false);
    }
    
    return validationStatus;
  }  

  const handleAddHorario = () => {
    validationInfos().then(async (result) => {
      if (result){
        setShowSaveEdit(true);
    
        
        const durationString  = `${selectedTime}:00`
        const durationString2 = `${selectedTime2}:00`
    
    
        if (id != null && id != ''){
          var objDataHorariosPut = {            
            AtividadeId: id.replace(/"/g, ''),        
            Domingo: checkedDomingo,             
            SegundaFeira: checkedSegunda,            
            TercaFeira: checkedTerca,            
            QuartaFeira: checkedQuarta,            
            QuintaFeira: checkedQuinta,           
            SextaFeira: checkedSexta,           
            Sabado: checkedSabado,            
            HorarioDe: durationString,
            HorarioAte: durationString2,                           
            saved: false,
          }    
          
          setArrDiasHorasAdd([...arrDiasHorasAdd, objDataHorariosPut]);  
          setSelectedTime('');
          setSelectedTime2('');
          setCheckedDomingo(false);
          setCheckedSegunda(false);
          setCheckedTerca(false);
          setCheckedQuarta(false);
          setCheckedQuinta(false);
          setCheckedSexta(false);
          setCheckedSabado(false);
        }else{
          var objDataHorariosPost = {            
            Domingo: checkedDomingo,             
            SegundaFeira: checkedSegunda,            
            TercaFeira: checkedTerca,            
            QuartaFeira: checkedQuarta,            
            QuintaFeira: checkedQuinta,           
            SextaFeira: checkedSexta,           
            Sabado: checkedSabado,            
            HorarioDe: durationString,
            HorarioAte: durationString2,        
            saved: false,
          }

          setArrDiasHorasAdd([...arrDiasHorasAdd, objDataHorariosPost]);  
          setSelectedTime('');
          setSelectedTime2('');
          setCheckedDomingo(false);
          setCheckedSegunda(false);
          setCheckedTerca(false);
          setCheckedQuarta(false);
          setCheckedQuinta(false);
          setCheckedSexta(false);
          setCheckedSabado(false);     
        }
    
      }

    })
  }

  const handleAddHorarioEdit = (data) => {        
    setDescricao(data.descricao);
    let arrDiasHoras = [];
    data.horarios.map((result, index) => {
      var objDataHorarios = {    
        Id: result.id,     
        AtividadeId: result.atividadeId,   
        Domingo: result.domingo,             
        SegundaFeira: result.segundaFeira,            
        TercaFeira: result.tercaFeira,            
        QuartaFeira: result.quartaFeira,            
        QuintaFeira: result.quintaFeira,           
        SextaFeira: result.sextaFeira,           
        Sabado: result.sabado,            
        HorarioDe: result.horarioDe,
        HorarioAte: result.horarioAte,
        saved: true                
      }  
      
      arrDiasHoras.push(objDataHorarios)
    })

    setArrDiasHorasAdd(arrDiasHoras);      
    
    /*const durationString  = `${selectedTime}:00`
    const durationString2 = `${selectedTime2}:00`

    var objDataHorarios = {            
      Domingo: checkedDomingo,             
      SegundaFeira: checkedSegunda,            
      TercaFeira: checkedTerca,            
      QuartaFeira: checkedQuarta,            
      QuintaFeira: checkedQuinta,           
      SextaFeira: checkedSexta,           
      Sabado: checkedSabado,            
      HorarioDe: durationString,
      HorarioAte: durationString2                
    }

    setArrDiasHorasAdd([...arrDiasHorasAdd, objDataHorarios]);  
    setSelectedTime('');
    setSelectedTime2('');
    setCheckedDomingo(false);
    setCheckedSegunda(false);
    setCheckedTerca(false);
    setCheckedQuarta(false);
    setCheckedQuinta(false);
    setCheckedSexta(false);
    setCheckedSabado(false);*/     
  }
  

  const handleSubmit = async (event) => {    
    //validationInfos().then(async (result) => {
      if (descricao === ''){
        setErrorDescricao(true);              
      }else{                                     
          if (id != null && id != ''){
            var objDataAtividadesPut = {
              Id: id.replace(/"/g, ''),
              Ativo: true,
              Descricao : descricao,              
              Horarios: arrDiasHorasAdd
            } 

            await atividadeService.putAtividade(objDataAtividadesPut, id.replace(/"/g, ''))
            .then((result) => {               
              setAlertSuccess(true);
              setMsgSuccess(result.data.mensagemRetorno);
              setIdAtividade(result.data.id);
            })  
            .catch((error) => {                    
              setAlertError(true);
              try {
                if (!error.response.data.mensagemRetorno){
                  setMsgError('Ocorreu um erro inesperado.');    
                }else{
                  setMsgError(error.response.data.mensagemRetorno);  
                }
                console.log('erro ', error);
              } catch (error2) {
                console.log('erro ', error2);
              }
              
              console.log('erro ', error);
            })
          }else{
            var objDataAtividadesPost = {    
              Ativo: true,          
              Descricao : descricao,
              Horarios: arrDiasHorasAdd
            } 

            await atividadeService.postAtividade(objDataAtividadesPost)
            .then((result) => {           
              setAlertSuccess(true);
              setMsgSuccess(result.data.mensagemRetorno);
              setIdAtividade(result.data.id);
            })  
            .catch((error) => {                    
              setAlertError(true);
              try {
                setMsgError(error.response.data.mensagemRetorno);  
              } catch (error2) {
                console.log('erro ', error2);
              }
              
              console.log('erro ', error);
            })
          }     
        //}
      }
    //})
  };
  

  const handleTimeChange = (event) => {    
    setErrorContainTimeCad(false);
    setErrorEqualTime(false);
    setErrorTimes(false);
    setErrorTimesBeginDiff(false);
    setSelectedTime(event.target.value);
  };

  const handleTimeChange2 = (event) => {
    setErrorContainTimeCad(false);
    setErrorEqualTime(false);
    setErrorTimes(false);
    setErrorTimesBeginDiff(false);
    setSelectedTime2(event.target.value);
  };

  const handleSubmitCountDias = (day) =>{                      
    
    var domBool = false;
    var segBool = false;
    var terBool = false;
    var quarBool = false;
    var quiBool = false;
    var sexBool = false;
    var sabBool = false;

    var objGroupDaysBools = [];
    var promises = countDias.map((data) => {
      return new Promise((resolve) => {        
        data.day === 'Domingo' ? domBool = true : segBool = false;
        data.day === 'Segunda' ? segBool = true : segBool = false;
        data.day === 'Terca' ? terBool = true : terBool = false;
        data.day === 'Quarta' ? quarBool = true : quarBool = false;
        data.day === 'Quinta' ? quiBool = true : quiBool = false;
        data.day === 'Sexta' ? sexBool = true : sexBool = false;
        data.day === 'Sabado' ? sabBool = true : sexBool = false;

        let objResult = {
          segBool: segBool,
          terBool: terBool,
          quarBool: quarBool,
          quiBool: quiBool,
          sexBool: sexBool
        }

        resolve(objResult)
      })
    })

    Promise.all(promises).then(result => {      
      const objGroup = [       
        { 
          descricao: 'Judo infantil',
          horas: []        
        },   
      ];
  
      const objDayHour = 
        {
          de: selectedTime,
          ate: selectedTime2,
          segunda: result.filter(data => data.segBool === true).length > 0 ? true : false,
          terca: result.filter(data => data.terBool === true).length > 0 ? true : false,
          quarta: result.filter(data => data.quarBool === true).length > 0 ? true : false,
          quinta: result.filter(data => data.quiBool === true).length > 0 ? true : false,
          sexta: result.filter(data => data.sexBool === true).length > 0 ? true : false
        }
      
  
      objGroup[0].horas.push(objDayHour);
   
      setArrayPostAtivit((dataPost) => [...dataPost, ... objGroup]);  
    }) 


  } 

  useEffect(() => {
    setErrorContainTimeCad(false);
    setErrorEqualTime(false);
    setErrorTimes(false);
    setErrorTimesBeginDiff(false); 
  }, [selectedTime, selectedTime2])

  useEffect(() => {
    setShowSaveEdit(true);
  }, [descricao])  

  useEffect(() => {       
    arrDiasHorasAdd.length > 0 ? setRenderListarrDiasHorasAdd(true) : setRenderListarrDiasHorasAdd(false);
  }, [arrDiasHorasAdd])     

  useEffect(() => {       
    if (id != null && id != ''){      
      atividadeService.getByID(id.replace(/"/g, ''))
        .then((result) => {
          handleAddHorarioEdit(result.data);
        })      
    }    
  }, [])   
  
  

  
  const handleChangeCheck = (event, propValue) => {   
    const objGroup = [
      { day: propValue },   
    ];

    setErrorDias(false);    

    if (propValue === 'Domingo') {
      setCheckedDomingo(event.target.checked);

      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var result  = countDias.filter(data => data.day != 'Domingo')        
        setCountDias(result);
      }
    } 
    if (propValue === 'Segunda') {
      setCheckedSegunda(event.target.checked);

      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var resultCountSegunda  = countDias.filter(data => data.day != 'Segunda')        
        setCountDias(resultCountSegunda);
      }
    } 
    if (propValue === 'Terca'){
      setCheckedTerca(event.target.checked);

      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var resultCountTerca = countDias.filter(data => data.day != 'Terca')        
        setCountDias(resultCountTerca);
      }
    } 

    if (propValue === 'Quarta') {

      setCheckedQuarta(event.target.checked);

      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var resultCountQuarta  = countDias.filter(data => data.day != 'Quarta')
      
        setCountDias(resultCountQuarta);
      }
    } 
    if (propValue === 'Quinta'){
      setCheckedQuinta(event.target.checked);

      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var resultCountQuinta  = countDias.filter(data => data.day != 'Quinta')
        
        setCountDias(resultCountQuinta);
      }
    }
    if (propValue === 'Sexta') {
      setCheckedSexta(event.target.checked);
      
      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var resultCountSexta  = countDias.filter(data => data.day != 'Sexta')        
        setCountDias(resultCountSexta);
      }

    } 
    if (propValue === 'Sabado') {
      setCheckedSabado(event.target.checked);

      if (event.target.checked){
        setCountDias((prevArray) => [...prevArray, ... objGroup]);
      }else{
        var resultCountSabado  = countDias.filter(data => data.day != 'Sabado')        
        setCountDias(resultCountSabado);
      }
    } 
  };

  const handleMouveEnterDelete = (index) => {
    setMouseOnDelete(true);
    setIndexOnDelete(index);
  }

  const handleMouveLeaveDelete = () => {
    setMouseOnDelete(false);
  }

  const handleCloseSnak = () => {
    setTimeout(() => {
      setDescricao('');
      setAlertError(false);
      setAlertSuccess(false);
      setCheckedDomingo(false);
      setCheckedSegunda(false);
      setCheckedTerca(false);
      setCheckedQuarta(false);
      setCheckedQuinta(false);
      setCheckedSexta(false);
      setCheckedSabado(false);    
      navigate(`/atividadesNew`);          
    }, 1200);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleCallDeleteHorarioAtividade = async (obj) => {    
    var infoHorarioModal = `${obj.HorarioDe.slice(0, 5)} - ${obj.HorarioAte.slice(0, 5)}` 
    var infoDiasModal = `${obj.Domingo ? 'Domingo' : ''}, ${obj.SegundaFeira ? 'Segunda' : ''}, ${obj.TercaFeira ? 'Terça' : ''}, 
                         ${obj.QuartaFeira ? 'Quarta' : ''}, ${obj.QuintaFeira ? 'Quinta' : ''}, ${obj.SextaFeira ? 'Sexta' : ''}, 
                         ${obj.Sabado ? 'Sábado' : ''}`
    setInfoModalHorario(infoHorarioModal);
    setinfoModalDias(infoDiasModal);
    setInfoIdModal(obj.Id);
    
    setModalOpen(true);        
  }

  const handleDeleteHorarioAtividade = async (idHorario) => { 
    var atividadeSaved = arrDiasHorasAdd.filter(x => x.Id === idHorario) 
    if (atividadeSaved[0].saved){
      var newObj = arrDiasHorasAdd.filter(x => x.Id != idHorario)    
      await atividadeService.deleteAtividade(idHorario)
        .then((result) => {          
          if (result.status === 200){
            setArrDiasHorasAdd(newObj);                
            handleCloseModal();
          }               
        })
    }else{
      var newObjNotSaved = arrDiasHorasAdd.filter(x => x.Id != idHorario) 
      setArrDiasHorasAdd(newObjNotSaved);   
      handleCloseModal();             
    }
  }

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',   
    width: 400,    
    boxShadow: 24,
    padding: 16,
    backgroundColor: '#FFF',
    borderRadius: 8
  };


  /*
   <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Dias: {infoModalDias}
          </MDTypography>
  */

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styleModal}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Deseja Excluir o horário da atividade ?
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Horario: {infoModalHorario}
          </MDTypography>         
          <MDBox style={{display: 'flex', flexDirection: 'row', marginTop: 16, alignItens: 'center', justifyItems: 'center'}}>
            <Button style={{alignSelf: 'center'}} onClick={() => {handleDeleteHorarioAtividade(infoIdModal)}}>
              Sim
            </Button>
            <Button onClick={() => {handleCloseModal()}}>
              Não
            </Button>
          </MDBox>
        </Box>
      </Modal>
      
      {alertSuccess && (
        <>
          <Snackbar open={{}} autoHideDuration={1500} onClose={()=> {handleCloseSnak()}}>
            <Alert onClose={()=> {handleCloseSnak()}} severity="success" sx={{ width: '100%' }}>
              {msgSuccess}
            </Alert>
          </Snackbar>
        </>
      )}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white" flexDirection={"column"}>
                  Cadastro de Atividades                                                                        
                </MDTypography>                
              </MDBox> 
              
              <Grid container p={4}>  
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    label="Descricao"
                    name="Descricao"
                    value={descricao}
                    onChange={(event) => {setDescricao(event.target.value)}}
                    fullWidth                      
                    required
                  />
                </Grid>
                {errorDescricao && (
                  <>
                    <Alert severity="error">
                      Informe a descrição da atividade
                    </Alert>                  
                  </>
                )}  

              </Grid>  

              <Grid container p={4} xl={12}>

                <Grid container xl={12} mb={6} alignSelf={'center'}>    
                  <Grid item xl={1}  alignSelf={'center'}>                                                             
                    <Schedule fontSize="large" />                    
                  </Grid>   
                  <Grid item xl={11}  alignSelf={'center'}>                                                           
                    <TextField
                      ref={inputRef}
                      id="time"
                      type="time"
                      value={selectedTime}
                      onChange={handleTimeChange}
                      variant="outlined"
                    />                                            
                    <TextField
                      id="time"
                      type="time"
                      value={selectedTime2}
                      onChange={handleTimeChange2}
                      variant="outlined"
                      style={{marginLeft: 16}}
                    />                                                              
                  </Grid>                                                        
                  {errorTimes && (
                  <>
                    <Alert severity="error"
                      style={{marginTop: 8}}
                    >
                      Informe os períodos completos. 
                    </Alert>                  
                  </>
                  )}  
                  {errorTimesBeginDiff && (
                  <>
                    <Alert severity="error"
                      style={{marginTop: 8}}
                    >
                      Hora inicial nao pode ser maior que hora final.
                    </Alert>                  
                  </>
                  )} 
                  {errorContainTimeCad && (
                  <>
                    <Alert severity="error"
                      style={{marginTop: 8}}
                    >
                      {msgContainTimeCad}
                    </Alert>                  
                  </>
                  )} 
                  {errorEqualTime && (
                  <>
                    <Alert severity="error"
                      style={{marginTop: 8}}
                    >
                      Horários iguais não permitido.
                    </Alert>                  
                  </>
                  )} 


                </Grid> 
                <Grid container p={0} flexDirection={'row'} xl={12} style={{}}>
                  <Grid item xl={1}>
                    <Event fontSize="large" />  
                  </Grid>
                  <Grid item xl={1.6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedDomingo}
                          onChange={(event) => handleChangeCheck(event, 'Domingo')}
                          color="primary"
                        />
                      }
                      label="Domingo"
                    />  
                  </Grid> 
                  <Grid item xl={1.7}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedSegunda}
                          onChange={(event) => handleChangeCheck(event, 'Segunda')}
                          color="primary"
                        />
                      }
                      label="Segunda"
                    />  
                  </Grid> 
                  <Grid item xl={1.6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTerca}
                          onChange={(event) => handleChangeCheck(event, 'Terca')}
                          color="primary"
                        />
                      }
                      label="Terça"
                    /> 
                  </Grid> 
                  <Grid item xl={1.6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedQuarta}
                          onChange={(event) => handleChangeCheck(event, 'Quarta')}
                          color="primary"
                        />
                      }
                      label="Quarta"
                    /> 
                  </Grid> 
    
                  <Grid item xl={1.6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedQuinta}
                          onChange={(event) => handleChangeCheck(event, 'Quinta')}
                          color="primary"
                        />
                      }
                      label="Quinta"
                    /> 
                  </Grid> 

                  <Grid item xl={1.7}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedSexta}
                          onChange={(event) => handleChangeCheck(event, 'Sexta')}
                          color="primary"
                        />
                      }
                      label="Sexta"
                    /> 
                  </Grid> 
                  <Grid item xl={1.2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedSabado}
                          onChange={(event) => handleChangeCheck(event, 'Sabado')}
                          color="primary"
                        />
                      }
                      label="Sábado"
                    /> 
                  </Grid>
                  {errorDias && (
                  <>
                    <Alert severity="error">
                      Informe ao menos um dia.
                    </Alert>                  
                  </>
                  )} 
                  <Grid item xl={12} pt={4} pb={4}>                      
                    <Button variant="contained" color="primary" fullWidth
                      onClick={() => {handleAddHorario()}}
                    >
                      Adicionar
                    </Button> 
                  </Grid>
                </Grid>
                <Grid item xl={12}>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" flexDirection={"column"} style={{marginLeft: 8}} p={1}>
                      Horas/Dias adicionadas                                                                       
                    </MDTypography>                
                  </MDBox>  
                </Grid>
                <Grid item xl={12} >
                  
                  {renderListarrDiasHorasAdd && (
                    <>
                      {arrDiasHorasAdd.map((data, index) => {
                        return (   
                          <Card style={{marginTop: 8, backgroundColor: data.saved ? 'silver' : '#fff'}}
                            key={index}>                                         
                            <Grid item xl={12} xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
                              <Grid item xs={12} sm={6} md={2} lg={2} xl={4} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16, marginRigth: 16}}>{data.HorarioDe} - {data.HorarioAte}</MDTypography>                      
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 32}}>Domingo</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.Domingo ? "large" : "small"} style={{color: data.Domingo || checkedDomingo ? 'green' : 'gray'}}/>
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16}}>Segunda</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.SegundaFeira ? "large" : "small"} style={{color: data.SegundaFeira || checkedSegunda ? 'green' : 'gray'}} />
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16}}>Terça</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.TercaFeira ? "large" : "small"} style={{color: data.TercaFeira || checkedTerca ? 'green' : 'gray'}}/>
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16}}>Quarta</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.QuartaFeira ? "large" : "small"} style={{color: data.QuartaFeira || checkedQuarta ? 'green' : 'gray'}} />
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16}}>Quinta</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.QuintaFeira ? "large" : "small"} style={{color: data.QuintaFeira || checkedQuinta ? 'green' : 'gray'}}/>
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16}}>Sexta</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.SextaFeira ? "large" : "small"} style={{color: data.SextaFeira || checkedSexta ? 'green' : 'gray'}} />
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                <MDTypography style={{marginLeft: 16}}>Sábado</MDTypography>
                                <ButtonBase>
                                  <DoneIcon fontSize={data.Sabado ? "large" : "small"} style={{color: data.Sabado || checkedSabado ? 'green' : 'gray'}}/>
                                </ButtonBase>
                              </Grid>
                              
                              <MDBox style={{alignItens: 'center', display: 'flex', marginLeft: 24, cursor: 'pointer'}}
                                onClick={() => {handleCallDeleteHorarioAtividade(data)}}
                              >
                                <DeleteIcon 
                                  onMouseEnter={(event) => {handleMouveEnterDelete(index)}}
                                  onMouseLeave={handleMouveLeaveDelete}
                                  style={{color: mouseOnDelete && index === indexOnDelete ? 'red' : 'gray', marginRight: 8 }} />                                                                                                                                                                                                
                              </MDBox> 
                              
                            </Grid> 
                          </Card>                     
                        )
                      })}  
                    </>
                  )}                  
                </Grid>
                {((arrDiasHorasAdd.length > 0) && (showSaveEdit)) && (
                  <>
                    <Grid item xl={12} pt={4} pb={4}>                      
                      <Button variant="contained" color="primary" type="submit" fullWidth
                        onClick={() => {handleSubmit()}}
                      >
                        Salvar
                      </Button> 
                    </Grid>
                  </>
                )}
                {alertError && (
                  <>                            
                    <Alert onClose={()=> {handleCloseSnak()}} severity="error" sx={{ width: '100%' }}
                      action={
                        <>                                                        
                            <Button color="inherit" size="small"
                              onClick={() => {setAlertError(false), setMsgError('')}}
                              style={{marginRight: 60}}
                            >
                              OK
                            </Button>
                        </>
                        } 
                    >
                      {msgError}
                    </Alert>                            
                  </>
                )}               
              </Grid>              
            </Card>  
          </Grid>
        </Grid>
      </MDBox>      
    </DashboardLayout>
  );
}

export default CadAtividadesNew;
