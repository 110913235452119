import { Alert, Button, Snackbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AvisoSnackProps {
  sucesso: boolean;
  mensagem: string;
  goBackScreen: string;  
}

export default function AvisoSnack({sucesso, mensagem, goBackScreen}: AvisoSnackProps) {
  const navigate = useNavigate();
  const [alertVisivel, setAlertVisivel] = React.useState(true);
  
  const handleCloseSnak = () => {
    setTimeout(() => {      
      navigate(`/${goBackScreen}`);          
    }, 1200);
  }

  const snackSucesso = (status: boolean) => {    
    console.log("Renderizando snackSucesso", status);
    return (    
      <>
        {status ? (
          <Snackbar open={true} autoHideDuration={1500}>
            <Alert onClose={() => {handleCloseSnak()}} severity={status ? "success" : "error"} sx={{ width: '100%' }}>
              {mensagem}
            </Alert>
          </Snackbar>
        ):(
          <>
            {alertVisivel && (
              <Alert              
                severity={"error"}
                action={
                  <>                                                        
                    <Button color="inherit" size="small"
                      onClick={() => {setAlertVisivel(false)}}
                        style={{marginRight: 60}}
                    >
                        OK
                      </Button>
                  </>
                  }                                                                                  
              >
                {mensagem}
              </Alert>
            )}
          </>
        )}
      </>  
    );
  }

  console.log("Renderizando AvisoSnack", sucesso);

  React.useEffect(() => {
    if (sucesso) {
      const timeoutId = setTimeout(() => {
        handleCloseSnak();
      }, 1500);

      return () => clearTimeout(timeoutId);
    }
  }, [sucesso]);

  return (
    <>
      {snackSucesso(sucesso)}
    </>
    
  )
}
