import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate  } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from 'axios';

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
//import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/images/physiotherapy-g492524094_1280.jpg"
import authService from "services/authService";
import BaseService from "../../../services/BaseService";
import TimeoutUserLogout from "services/Utils/TimeoutUserLogout";
import { Alert } from "@mui/material";

export const ReAuth = ({handleAuth}) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loginInvalid, setLoginInvalid] = useState(false);

    const handleLogin = async () => {            
        try {
          const auth = await authService.login(login, password);      
          var resultLogin = auth;
          if (resultLogin){            
            handleAuth(true);                 
          }else{   
            handleAuth(false);             
          }
    
          var token = await authService.getToken();            
        } catch (error) {                 
          alert('login inválido');
        }
    }
    
      
    const customButtonStyle = {
      width: '100%'      
    };

    const handleLoginChange = (event) => {
      setLogin(event.target.value);
    };

    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };        

    return (
        <Card>                
            <Card style={{marginBottom: 40}}>          
            </Card>          
            <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            >          
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                TXN Academy
            </MDTypography>
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                    <FacebookIcon color="inherit" />
                </MDTypography>
                </Grid>
                <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                    <GitHubIcon color="inherit" />
                </MDTypography>
                </Grid>
                <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                    <GoogleIcon color="inherit" />
                </MDTypography>
                </Grid>
            </Grid>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
                <MDBox mb={2}>
                <MDInput type="email" label="Email" fullWidth 
                    id="login"
                    value={login}
                    onChange={handleLoginChange}
                />
                </MDBox>
                <MDBox mb={2}>
                <MDInput type="password" label="Password" fullWidth                 
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>

                </MDBox>
                <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info"
                    style={customButtonStyle}
                    onClick={handleLogin}
                >
                <MDTypography                                                                                                                           
                    color="white"               
                    >
                    Entrar
                </MDTypography>
                </MDButton>
                
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                <Alert
                 severity={"error"}
                 >
                   Sua sessão expirou, efetue o login novamente.    
                 </Alert>                                
                </MDBox>
            </MDBox>
            </MDBox>
         </Card>      
    )
}