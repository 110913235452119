import { MovimentacaoTurmaViewModel, IResultDTOMovContracao } from '../../../layouts/detalheTurma/DetalheType';
import BaseService from '../../../infrastructure/services';

export const getByDescricaoTurma = async (nome: string) => {
  const customConfig = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };
  
  const response = await BaseService.get(`/Turma/ObterPorDescricao?descricao=${nome}`, customConfig);      
  return response;
}

export const postMovimentacaoAlunosTurmas = async (obj : MovimentacaoTurmaViewModel): Promise<IResultDTOMovContracao> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
    body: obj
  };  
  
  const response = await BaseService.post(`/Turma/MovimentaAlunosTurma`, obj, customConfig);  
  const responseData: IResultDTOMovContracao = response.data;

  return responseData;
}

