/* eslint-disable @typescript-eslint/no-empty-function */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// react-router-dom components
import { Link, useNavigate  } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from 'axios';

// Authentication layout components
import BasicLayout from "../../../layouts/authentication/components/BasicLayout";

// Images
//import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/images/bgLogin.png"
import authService from "../../../services/authService";
import BaseService from "../../../services/BaseService";
import TimeoutUserLogout from "services/Utils/TimeoutUserLogout";
import { Button } from "@mui/material";
import funcionarioService from "../../../services/FuncionarioService";



function Basic() {  
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [rememberMe, setRememberMe] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginInvalid, setLoginInvalid] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  
  const handleLogin = async () => {    

    try {
      const auth = await authService.login(login, password);            
      var resultLogin = auth;
      if (resultLogin){              
        navigate(`/Dashboard`);                     
      }else{         
        alert('login inválido');
      }
      
    } catch (error) {                  
      alert('login inválido');
    }
  }

  
  const customButtonStyle = {
    width: '100%'      
  };

  const handleLoginChange = (event) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };  

  const handleEsqueciMinhaSenha = async () => {
    if (login.length <= 0){
      inputRef.current.focus();
    }else{
      await funcionarioService.sendEmail(login)
        .then((result) => {          
        })
    } 
  }

  return (
    <BasicLayout image={bgImage}>      
      
      <Card>                
        <Card style={{marginBottom: 40}}>          
        </Card>          
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >          
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Brincar Academia
          </MDTypography>
          <Grid item xs={12} mb={2}>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth 
                id="login"
                value={login}
                onChange={handleLoginChange}
                inputRef={inputRef}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth                 
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
              {false && (
                <>
                  <Button style={{marginTop: -12, marginLeft: -20}}
                    onClick={() => {handleEsqueciMinhaSenha()}}
                  >
                    <h6>Esqueceu sua senha ? </h6>
                  </Button>
                </>
              )}
            </MDBox>
    
            <MDBox display="flex" alignItems="center" ml={-1}>

            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info"
                style={customButtonStyle}
                onClick={handleLogin}
              >
              <MDTypography                                                                                                                           
                  color="white"               
                >
                Entrar
               </MDTypography>
              </MDButton>
              
            </MDBox>
           
          </MDBox>
        </MDBox>
      </Card>      
      
    </BasicLayout>
  );
}

export default Basic;


/* Icones rede sociais

 <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>

          */

          /** esqueci minha senha
           *  <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {`Não tem uma conta ? `}
                <MDTypography
                  component={Link}
                  to="/dashboard"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Cadastre-se
                </MDTypography>
              </MDTypography>
            </MDBox>
           */