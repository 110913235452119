import { TrySharp } from "@mui/icons-material";
import baseService from "./BaseService";
const authService = {
    login: async (username, password) => {      
      try {        
        const response = await baseService.post(`/Funcionario/Login?email=${username}&senha=${password}`);        
        const token = response.data.token;          
        localStorage.setItem('token', token);                    
        return token;
      } catch (error) {            
        return null;
      }

      
    },
    logout: () => {      
      localStorage.removeItem('token');
    },
    getToken: () => {      
      return localStorage.getItem('token');
    },
  };
  
  export default authService;
  