/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel } from '@material-ui/core';
import { MDSearch } from "components/MDSearch";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { Box } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import funcionarioService from "../../../services/FuncionarioService";
import { isExpired } from "react-jwt";
import { CallCepInfo } from "services/Utils/callCepInfo";
import { Alert, Snackbar, Tooltip } from "@mui/material";
import { ReAuth } from "layouts/authentication/reAuth";
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import CpfValidation from "../../../services/Utils/validationCpf";
import { verifyRolesAcess } from "../../../services/Utils/verifyRolesAcess" ;

function CadFuncionarios() {  

  const [selectedVinculo, setSelectedVinculo] = useState('');  
  const [checkedAcessoSoft, setCheckedAcessoSoft] = useState(true);
  const [checkedAtivo, setCheckedAtivo] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchResult, setSearchResult] = useState('');
  const [titular, setTitular] = useState('');
  const [visibleSalario, setVisibleSalario] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfPass, setVisibleConfPass] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [clickedRowIndex, setClickedRowIndex] = useState(null); 
  
  const [tipoPessoaFisica, setTipoPessoaFisica] = useState(true);
  const [id, setId] = useState('');
  const [ativo, setAtivo] = useState(false);
  const [nome, setNome] = useState('');
  const [dtCadastro, setDtCadastro] = useState(new Date());
  const [dtNasc, setDtNasc] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('Bebedouro');
  const [uf, setUf] = useState('');
  const [referencia, setReferencia] = useState('');
  const [diaPag, setDiaPag] = useState('10');
  const [salario, setSalario] = useState();
  const [acessSoftware, setAcessSoftware] = useState(false);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [observacao, setObservacao] = useState('');
  const [descIndividual, setDescIndividual] = useState(0);
  const [congAtividade, setCongAtividade] = useState(false);
  const [idTiular, setIdTitular] = useState('');
  const [alertError, setAlertError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [alertEditSuccess, setAlertEditSuccess] = useState(false);  
  const [alertInfo, setAlertInfo] = useState(false);
  const [msgInfo, setMsgInfo] = useState(false);   
  const [keyBackSpace, setKeyBackSpace] = useState(false); 
  const [tokenExpired, setTokenExpired] = useState(false);
  const [reAuthOk, setReAuthOk] = useState(false);
  const [loadCep, setLoadCep] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailUserAcess, setInvalidEmailUserAcess] = useState(false);
  
  const [errorCallCep, setErrorCallCep] = useState(false);
  const [cepNotFound, setCepNotFound] = useState(false);
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidPermission, setInvalidPermission] = useState(false);
  const [confPassword, setConfPassword] = useState('');
  const [invalidConfPass, setInvalidConfPass] = useState(false);
  
  const [rolesCheckAdministrador, setRolesCheckAdministrador ] = useState(false);
  const [rolesCheckGerente, setRolesCheckGerente] = useState(false);
  const [rolesCheckGestaoCliente, setRolesCheckGestaoCliente] = useState(false);
  const [rolesCheckGestaoContratacao, setRolesCheckGestaoContratacao] = useState(false);
  const [rolesCheckFinanceiroGeral, setRolesCheckFinanceiroGeral] = useState(false);
  const [rolesCheckFinanceiroClientes, setRolesCheckFinanceiroClientes] = useState(false);
  const [rolesCheckFinanceiroFinanceiroDespesas, setRolesCheckFinanceiroDespesas] = useState(false);
  
  const navigate = useNavigate();
  const focusRef = useRef(null); 
  const cpfValidation = new CpfValidation(); 

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const objData = queryParams.get('objData');  

  function formatDDMMYYYY(data) {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
    const ano = data.getFullYear();  
    return `${dia}/${mes}/${ano}`;
  }

  function formatRG(rg) {   
    var valueSetRg = '';        
    rg = rg.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');  
    rg.length <= 12 ? valueSetRg=rg : valueSetRg=rg.slice(0, -1) ;
    return valueSetRg;
  }

  function formatCPF(cpf) {                  
    var valueSetCpf = '';
    cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');      
    cpf.length <= 14 ? valueSetCpf=cpf : valueSetCpf=cpf.slice(0, -1);
    return valueSetCpf;
  }

  function formatPhoneNumber(phoneNumber) {              
    phoneNumber = phoneNumber.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  
    return phoneNumber;
  }  

  function formatDateInfos(inputDate){      
    
    var dateSplit = inputDate.split('/');    
    var formattedDate = new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1]);
    
    var year = formattedDate.getFullYear();
    var month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    var day = String(formattedDate.getDate()).padStart(2, '0');

    
    var dateFormat = year + '-' + month + '-' + day;
    return dateFormat;    
  }  

  const handleCallCepInfo  = async (cep) => {
    setLoadCep(true);
    try {      
      var resultInfoCep = await CallCepInfo(cep);      
      if (resultInfoCep.erro){            
        setErrorCallCep(false);        
        setCepNotFound(true);
        setEndereco('');
        setBairro('');
        setCidade('');
        setUf(''); 
        setLoadCep(false);
      }else{            
        setErrorCallCep(false);
        setCepNotFound(false);
        setEndereco(resultInfoCep.logradouro);
        setBairro(resultInfoCep.bairro);
        setCidade(resultInfoCep.localidade);
        setUf(resultInfoCep.uf); 
        setLoadCep(false);
      }       
    } catch (error) {
      setCepNotFound(false);
      setErrorCallCep(true);
    }
  }


  const handleSubmitFuncionario = async (event) => {
    event.preventDefault();  


    var hasCheckRoles = handleVerifyRoles();
    if (checkedAcessoSoft && hasCheckRoles === ''){
      setAlertError(true);
      setMsgError('Obrigatório informar um nível de acesso');         
    }else if (invalidEmail && invalidEmailUserAcess && invalidCpf){
      setAlertError(true);
      setMsgError('CPF, E-mail e Usuário E-mail digitado são inválidos');         
    }
    else if (invalidEmail && invalidCpf)
    {
      setAlertError(true);
      setMsgError('CPF e E-mail digitado são inválidos');   
    }else if (invalidEmail) {
      setAlertError(true);
      setMsgError('E-mail digitado é inválido');   
    }else if (invalidCpf){
      setAlertError(true);
      setMsgError('CPF digitado é inválido');   
    }else if (invalidConfPass){
      setAlertError(true);
      setMsgError('As senhas do usuário não conferem');   
    }else
    {
      let objFuncionarios = {            
        nome: nome,
        telefoneCelular: telefone,
        dataNascimento: formatDateInfos(dtNasc),
        telefoneFixo: telefone2,
        rg: rg,
        cpf: cpf,
        cnpj: cpf,      
        email: email,
        logradouro: endereco,
        numero: numero,
        bairro: bairro,
        cidade: cidade,
        cep: cep,
        referencia: referencia,
        uf: uf,
        observacoes: observacao,            
        diaPagamento: diaPag,            
        ativo: ativo,      
        usuarioAcesso: user,
        senhaAcesso: password,
        salario: salario,
        acessaSoftware: checkedAcessoSoft,      
        MensagemRetorno: 'Formulario funcionário',
        role: handleVerifyRoles()
      }
  
      let objPutFuncionarios = {  
        id: id,          
        nome: nome,
        telefoneCelular: telefone,
        dataNascimento: formatDateInfos(dtNasc),
        telefoneFixo: telefone2,
        rg: rg,
        cpf: cpf,
        cnpj: cpf,      
        email: email,
        logradouro: endereco,
        numero: numero,
        bairro: bairro,
        cidade: cidade,
        cep: cep,
        referencia: referencia,
        uf: uf,
        observacoes: observacao,            
        diaPagamento: diaPag,            
        ativo: ativo,
        acessaSoftware: checkedAcessoSoft,
        salario: salario,
        usuarioAcesso: user,
        senhaAcesso: password,
        MensagemRetorno: 'Formulario funcionário',
        role: handleVerifyRoles()
      }        
     
      var result = {};
      if (objData != null){                           
          await funcionarioService.putFuncionario(objPutFuncionarios, objPutFuncionarios.id).then((result) => {                    
            setAlertEditSuccess(true);
            setMsgSuccess('Funcionario Atualizado com sucesso !')                  
          }).catch((error) => {          
            setAlertError(true);
            setMsgError(error.response.data.mensagemRetorno);
          })        
  
      }else{   
        try {          
          await funcionarioService.postFuncionario(objFuncionarios)
            .then((result) => {
              try {
                if (result.response.data.sucesso === false){
                  setAlertError(true);
                  setMsgError(result.response.data.mensagemRetorno);
                }
              } catch (error) {
                try {
                  if (result.data.sucesso === true){
                    setAlertSuccess(true);
                    setMsgSuccess('Funcionario Inserido com sucesso !')
                  }else{
                    setAlertSuccess(false);
                    setMsgSuccess(result.data.mensagemRetorno) 
                  }
                } catch (error) {
                  setAlertSuccess(false);
                  setMsgSuccess('Ocorreu um erro inesperado.') 
                }
      
              }                     
            })
        } catch (error) {
          console.log('erro', error);
          setAlertError(true);
          setMsgError(error);                    
        }               
          
        
      }       
      /*
        setAlertSuccess(true);
        setMsgSuccess('Funcionario Inserido com sucesso !')      

        setAlertError(true);
        setMsgError(result.data.mensagemRetorno);            

        setAlertError(true);
        setMsgError(errorParse.mensagemRetorno);

      */        
    }

  }

  const handlePrepareInputEditar = (filterData) => {
    var filterNew =  JSON.parse(filterData);
    
    setId(filterNew.id);
    setAtivo(filterNew.ativo);
    setBairro(filterNew.bairro);
    setCep(filterNew.cep);
    setCidade('Bebedouro');
    var dataCad = new Date(filterNew.clienteDesde);
    var day = dataCad.getDate();
    var month = dataCad.getMonth() + 1;
    var year = dataCad.getFullYear();
    dataCad = day + "/" + month + "/" + year;
    setDtCadastro(dataCad);        
    setCpf(filterNew.cpf);
    const originalDate = filterNew.dataNascimento;
    var dateObject = new Date(filterNew.dataNascimento);
    var dayOriginal = dateObject.getDate();
    var monthOriginal = dateObject.getMonth() + 1;
    var yearOriginal = dateObject.getFullYear();
    var formattedDate = dayOriginal + "/" + monthOriginal + "/" + yearOriginal;    
    setDtNasc(formattedDate);    
    setEmail(filterNew.email);
    setEndereco(filterNew.logradouro);
    //setDiaPag(filterNew.melhorDiaPagamento);        
    setNome(filterNew.nome);
    setNumero(filterNew.numero);
    setObservacao(filterNew.observacoes);    
    setReferencia(filterNew.referencia);
    setRg(filterNew.rg);
    setTelefone(filterNew.telefoneCelular);
    setTelefone2(filterNew.telefoneFixo);
    setUf(filterNew.uf); 
    setUser(filterNew.usuarioAcesso);
    setPassword(filterNew.senhaAcesso);
    setConfPassword(filterNew.senhaAcesso);
    setDiaPag(filterNew.diaPagamento)    
    setSalario(filterNew.salario)
    handleVerifyRolesCheckEdit(filterNew.role)
  }

  const handleCleanForm = () => {        
    setId('');
    setAtivo(true);
    setBairro('');
    setCep('');
    setCidade('');    
    setDtCadastro('');
    setCnpj();    
    setCpf('');    
    setDtNasc('');    
    setEmail('');
    setEndereco('');
    setDiaPag(0);        
    setNome('');
    setNumero('');
    setObservacao('');    
    setReferencia('');
    setRg('');
    setTelefone('');
    setTelefone2('');
    setUf('');        
    setAlertSuccess(false);
    setMsgSuccess('');
  }

  function handleClose (option){
    navigate(`/funcionarios/`);    
  }

  const handleBackSpaceKeyPress = (event) => {
    if (event.keyCode === 8) {
      setKeyBackSpace(true);      
    }else{
      setKeyBackSpace(false);
    }
  }

  const handleSetDataNasc = (value) => {     
    var newValue = ''
    let lastCharBar = false;
    let lastChar = value.charAt(value.length - 1);

    if (lastChar === '/' && keyBackSpace){        
      lastCharBar = true;
    }else{        
      if(value.length === 2){   
        newValue = '/'       
      }else if (value.length === 5){          
        newValue = '/'       
      }
    }              
  
    lastCharBar ? setDtNasc(value.substring(0, value.length - 1)) : setDtNasc(value+newValue);
  }

  useEffect(async () => {   
    var invalidPermissionInfo = await verifyRolesAcess('funcionarios');        
    setInvalidPermission(!invalidPermissionInfo);

    if (objData != null){      
      var filterData = objData;      
      handlePrepareInputEditar(filterData)      
    }else{
      setDtCadastro(formatDDMMYYYY(new Date()));
      setAtivo(true);
    }    

    var isExpiredInfo = isExpired(localStorage.getItem('token'));    
    setTokenExpired(isExpiredInfo);
  }, [])

  const cities = [
    { id: 1, name: 'São Paulo' },
    { id: 2, name: 'Bebedouro' },
    { id: 3, name: 'Ribeirão Preto' },    
  ];

  const alunos = [
    { id: 1, name: 'aluno 1' },
    { id: 2, name: 'aluno 2' },
    { id: 3, name: 'aluno 3' },
    { id: 4, name: 'aluno 4' },
    { id: 5, name: 'aluno 5' },
  ];

  const arrDiasPag = [    
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },    
    { id: 4, name: '4' },  
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },    
    { id: 8, name: '8' },  
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
    { id: 24, name: '24' },
    { id: 25, name: '25' },
    { id: 26, name: '26' },
    { id: 27, name: '27' },
    { id: 28, name: '28' },
    { id: 29, name: '29' },
    { id: 30, name: '30' },
    { id: 31, name: '31' },       
  ];


  const handleChecksRoles = (rolesCheck) => {
    if (rolesCheck === 'rolesCheckAdministrador'){
      setRolesCheckAdministrador(!rolesCheckAdministrador);
      setRolesCheckGerente(false);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoContratacao(false);
      setRolesCheckFinanceiroGeral(false);
      setRolesCheckFinanceiroClientes(false);
      setRolesCheckFinanceiroDespesas(false);
    }

    if (rolesCheck === 'rolesCheckGerente'){
      setRolesCheckAdministrador(false);
      setRolesCheckGerente(!rolesCheckGerente);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoContratacao(false);
      setRolesCheckFinanceiroGeral(false);
      setRolesCheckFinanceiroClientes(false);
      setRolesCheckFinanceiroDespesas(false);
    }

    if (rolesCheck === 'rolesCheckGestaoCliente'){
      setRolesCheckAdministrador(false);
      setRolesCheckGerente(false);      
      setRolesCheckGestaoCliente(!rolesCheckGestaoCliente);
      setRolesCheckGestaoContratacao(false);
      setRolesCheckFinanceiroGeral(false);
      setRolesCheckFinanceiroClientes(false);
      setRolesCheckFinanceiroDespesas(false);
    }    

    if (rolesCheck === 'rolesCheckGestaoContratacao'){
      setRolesCheckAdministrador(false);
      setRolesCheckGerente(false);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoContratacao(!rolesCheckGestaoContratacao);
      setRolesCheckFinanceiroGeral(false);
      setRolesCheckFinanceiroClientes(false);
      setRolesCheckFinanceiroDespesas(false);
    }

    if (rolesCheck === 'rolesCheckFinanceiroGeral'){
      setRolesCheckAdministrador(false);
      setRolesCheckGerente(false);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoContratacao(false);
      setRolesCheckFinanceiroGeral(!rolesCheckFinanceiroGeral);
      setRolesCheckFinanceiroClientes(false);
      setRolesCheckFinanceiroDespesas(false);
    }

    if (rolesCheck === 'rolesCheckFinanceiroClientes'){
      setRolesCheckAdministrador(false);
      setRolesCheckGerente(false);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoContratacao(false);
      setRolesCheckFinanceiroGeral(false);
      setRolesCheckFinanceiroClientes(!rolesCheckFinanceiroClientes);
      setRolesCheckFinanceiroDespesas(false);
    }

    if (rolesCheck === 'rolesCheckFinanceiroFinanceiroDespesas'){
      setRolesCheckAdministrador(false);
      setRolesCheckGerente(false);
      setRolesCheckGestaoCliente(false);
      setRolesCheckGestaoContratacao(false);
      setRolesCheckFinanceiroGeral(false);
      setRolesCheckFinanceiroClientes(false);
      setRolesCheckFinanceiroDespesas(!rolesCheckFinanceiroFinanceiroDespesas);
    }
  }

  const handleVerifyRolesCheckEdit = (value) => {    
    value === 'Administrador' ? 
      setRolesCheckAdministrador(true) : 
      value === 'Gerente' ? 
        setRolesCheckGerente(true) :
        value === 'GestaoCliente' ?
          setRolesCheckGestaoCliente(true) :
          value === 'GestaoCliente' ?
            setRolesCheckGestaoCliente(true) :
            value === 'GestaoContratacao' ?
              setRolesCheckGestaoContratacao(true) :
              value === 'FinanceiroGeral' ?
                setRolesCheckFinanceiroGeral(true) :
                value === 'FinanceiroCliente' ?
                setRolesCheckFinanceiroClientes(true) :
                  value === 'FinanceiroDespesa' ?
                    setRolesCheckFinanceiroDespesas(true) : ''                 
  }

  function handleVerifyRoles (value) {
    var rolesInsert = '';
    rolesCheckAdministrador ? 
      rolesInsert='Administrador' 
    : rolesCheckGerente ? 
      rolesInsert='Gerente' : rolesCheckGestaoCliente ?
        rolesInsert='GestaoCliente' : rolesCheckGestaoContratacao ?
          rolesInsert='GestaoContratacao' : rolesCheckFinanceiroGeral ?
            rolesInsert='FinanceiroGeral' : rolesCheckFinanceiroClientes ?
              rolesInsert='FinanceiroCliente' : rolesCheckFinanceiroFinanceiroDespesas ?
                rolesInsert='FinanceiroDespesa' : '';                

    return rolesInsert;            
  }

  const handleReAuthInfo = (value) => {
    var isExpiredInfo = isExpired(localStorage.getItem('token'))
    setTokenExpired(isExpiredInfo);         
  }

  const handleValidationCpf = () => {    
    var validation = cpfValidation.GetValidationCpf(cpf.replace(/[.-]/g, ''));    
    validation ? setInvalidCpf(false) : setInvalidCpf(true);
  }

  const handleValidationEmail = () => {    
    if (email != ''){
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      emailRegex.test(email) ? setInvalidEmail(false) : setInvalidEmail(true);
    }
  }

  const handleValidationEmailUserAcess = () => {    
    if (user != ''){
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      emailRegex.test(user) ? setInvalidEmailUserAcess(false) : setInvalidEmailUserAcess(true);
    }
  }

  useEffect(async () => {           
    cpf.length === 14 && handleValidationCpf();

  }, [cpf])

  const handleConfPass = () => {
    password != confPassword ? setInvalidConfPass(true) : setInvalidConfPass(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar /> 
      {tokenExpired ? (
        <>                
          <ReAuth handleAuth={handleReAuthInfo} />        
        </>
      ):(                        
        <>
          {invalidPermission ? (
            <>
              <Alert severity="error">
                Você não tem permissão para acessar esse recurso.
              </Alert>
            </>
          ):(
            <>
              {alertSuccess ? (
                <>
                  <Snackbar open={{}} autoHideDuration={1500} onClose={()=>{handleClose()}}>
                    <Alert onClose={()=> {handleClose()}} severity="success" sx={{ width: '100%' }}>
                      Cadastro inserido com sucesso !
                    </Alert>
                  </Snackbar>
                </>
              ):(
                <>
                  {alertEditSuccess && (
                    <Snackbar open={{}} autoHideDuration={1500} onClose={()=>{handleClose()}}>
                      <Alert onClose={()=>{handleClose()}} severity="success" sx={{ width: '100%' }}>
                        Atualização realizada com sucesso !
                      </Alert>
                    </Snackbar>
                  )}
                </>
              )}              
              <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white" flexDirection={"column"}>
                          Cadastro de Funcionarios                                                                        
                        </MDTypography>                
                      </MDBox>  

                      <form onSubmit={(event) => {handleSubmitFuncionario(event)}}>
                        <Grid container spacing={2} p={4}>                  
                          <Grid item xs={6} sm={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={ativo}
                                  onChange={() => {setAtivo(!ativo)}}
                                  color="primary"
                                />
                              }
                              label="Ativo"
                            />               
                          </Grid>   
                          <Grid item xs={12} sm={9} md={12} lg={8} xl={10}>
                            <TextField
                              id="nomeField"
                              focused={true}
                              ref={focusRef}
                              label="Nome"
                              name="name"
                              value={nome}
                              onChange={(event) => {setNome(event.target.value)}}
                              fullWidth
                              required
                            />                        
                          </Grid>                  
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="Data Nascimento"
                              name="date"
                              value={dtNasc}
                              onChange={(event) => {handleSetDataNasc(event.target.value)}}
                              placeholder="DD/MM/YYYY" 
                              fullWidth     
                              required                 
                              onKeyDown={(event) => {handleBackSpaceKeyPress(event)}}
                            />
                          </Grid>                  
                          <Grid item  xs={12} sm={3}>
                            <TextField
                              label="Rg"
                              name="rg"
                              value={rg}
                              onChange={(event) => {setRg(formatRG(event.target.value))}}
                              fullWidth                              
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                          <TextField
                            label="CPF"
                            name="cpf"
                            value={cpf}
                            onChange={(event) => {setCpf(formatCPF(event.target.value))}}
                            fullWidth                            
                          />
                          {invalidCpf && <div style={{color: 'red', fontSize: 10}}>O CPF digitado não existe.</div>}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Email"
                              name="email"
                              value={email}
                              onChange={(event) => {setEmail(event.target.value)}}
                              onBlur={handleValidationEmail}
                              fullWidth
                              required={checkedAcessoSoft ? true : false}
                            />
                            {invalidEmail && <div style={{color: 'red', fontSize: 10}}>E-mail digitado é inválido</div>}
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label="Telefone"
                              name="phone"
                              value={telefone}
                              onChange={(event) => {setTelefone(formatPhoneNumber(event.target.value))}}
                              fullWidth                              
                            />
                          </Grid>                 

                          <Grid item xs={11} sm={3}>
                            <TextField
                              label="Cep"
                              name="cep"
                              value={cep}
                              onChange={(event) => {setCep(event.target.value)}}                      
                              onBlur={() => {handleCallCepInfo(cep)}}
                              fullWidth                              
                            />     
                                {errorCallCep && <div style={{color: 'red', fontSize: 10}}>Falha ao buscar CEP, tente novamente.</div>}                        
                                {cepNotFound && <div style={{color: 'red', fontSize: 10}}>CEP não encontrado.</div>}                                                   
                          </Grid>
                          <Grid item xs={1} sm={1}>
                            {!loadCep ? (
                              <>
                              <Tooltip title="Digite o CEP para o preenchimento dos campos: .Endereço | .Bairro | .Cidade | .Uf" arrow placement="left">                                
                                <InfoIcon style={{marginTop: 26}}/>  
                              </Tooltip>                                
                              </>
                            ):(
                              <>
                                <CircularProgress fontSize={"small"} style={{marginTop: 16}}/>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <TextField
                              label="Endereço"
                              name="address"
                              value={endereco}
                              onChange={(event) => {setEndereco(event.target.value)}}
                              fullWidth                              
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <TextField
                              label="Numero"
                              name="numero"
                              value={numero}
                              onChange={(event) => {setNumero(event.target.value)}}
                              fullWidth                              
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Bairro"
                              name="bairro"
                              value={bairro}
                              onChange={(event) => {setBairro(event.target.value)}}
                              fullWidth                              
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Cidade"
                              name="Cidade"
                              value={cidade}
                              onChange={(event) => {setCidade(event.target.value)}}
                              fullWidth                              
                              disabled
                            />
                          </Grid>  
                          <Grid item xs={1}>
                            <TextField
                              label="UF"
                              name="uf"
                              value={uf}
                              onChange={(event) => {setUf(event.target.value)}}
                              fullWidth                              
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              label="Referencia"
                              name="referencia"
                              value={referencia}
                              onChange={(event) => {setReferencia(event.target.value)}}
                              fullWidth                          
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                              <FormControl fullWidth>
                                <InputLabel id="city-label">Dia Pagamento</InputLabel>
                                <Select
                                  labelId="city-label"
                                  id="diaPag"
                                  name="diaPag"
                                  value={diaPag}
                                  onChange={(event) => {setDiaPag(event.target.value)}}                                  
                                >
                                  {arrDiasPag.map((dp) => (
                                    <MenuItem key={dp.id} value={dp.name}>
                                      {dp.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                          </Grid>
                          <Grid item xs={10} sm={2}>                    
                              <TextField
                                label="Salário"
                                name="salario"
                                value={salario}
                                onChange={(event) => {setSalario(event.target.value)}}
                                fullWidth                                
                                type={visibleSalario ? "text" : "password"}
                              />                    
                          </Grid>                                    
                          <Grid item xs={1} sm={1} style={{flexDirection: 'row', justifyContent: 'flex-end'}}>                    
                            <Button style={{marginLeft: -26, marginTop: 16}}
                              onClick={() => {setVisibleSalario(!visibleSalario)}}
                            >
                              {visibleSalario ? (
                                <VisibilityRoundedIcon style={{width: 20, height: 20}} />
                              ): (
                                <VisibilityOffRoundedIcon style={{width: 20, height: 20}} />                        
                              )}
                              
                            </Button> 
                          </Grid>                                    

                          <Grid item xs={12} sm={7} mt={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkedAcessoSoft}                          
                                  onChange={() => {setCheckedAcessoSoft(!checkedAcessoSoft)}}
                                  color="primary"
                                />
                              }
                              label="Acesso ao Software"
                            />               
                          </Grid>
                          
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="E-mail acesso"
                              name="acesso"
                              value={user}
                              onChange={(event) => {setUser(event.target.value)}}
                              onBlur={handleValidationEmailUserAcess}
                              fullWidth
                              required={checkedAcessoSoft ? true : false}
                            />
                            {invalidEmailUserAcess && <div style={{color: 'red', fontSize: 10}}>E-mail digitado é inválido</div>}                                                
                          </Grid>                                                  
                          <Grid item xs={11} sm={2}>
                            <TextField
                              label="Senha"
                              name="senha"
                              value={password}
                              onChange={(event) => {setPassword(event.target.value)}}
                              fullWidth
                              required={checkedAcessoSoft ? true : false}
                              type={!visiblePassword ? "password" : "text"}
                            />
                            {invalidConfPass && <div style={{color: 'red', fontSize: 10}}>As senhas não conferem.</div>}
                          </Grid>     
                          <Grid item xs={1} sm={1} style={{flexDirection: 'row', justifyContent: 'flex-end'}}>                    
                            <Button style={{marginLeft: -26, marginTop: 16}}
                              onClick={() => {setVisiblePassword(!visiblePassword)}}
                            >
                              {visiblePassword ? (
                                <VisibilityRoundedIcon style={{width: 20, height: 20}} />
                              ): (
                                <VisibilityOffRoundedIcon style={{width: 20, height: 20}} />                        
                              )}
                              
                            </Button> 
                          </Grid>
                          <Grid item xs={11} sm={2} ml={-4}>
                            <TextField
                              style={{color: 'red'}}
                              label="Confirmar senha"
                              name="confSenha"
                              value={confPassword}
                              onChange={(event) => {setConfPassword(event.target.value)}}
                              onBlur={handleConfPass}
                              fullWidth
                              required={checkedAcessoSoft ? true : false}
                              type={!visibleConfPass ? "password" : "text"}                              
                            />
                            {invalidConfPass && <div style={{color: 'red', fontSize: 10}}>As senhas não conferem.</div>}
                          </Grid> 
                          <Grid item xs={1} sm={1} style={{flexDirection: 'row', justifyContent: 'flex-end'}}>                    
                            <Button style={{marginLeft: -26, marginTop: 16}}
                              onClick={() => {setVisibleConfPass(!visibleConfPass)}}
                            >
                              {visibleConfPass ? (
                                <VisibilityRoundedIcon style={{width: 20, height: 20}} />
                              ): (
                                <VisibilityOffRoundedIcon style={{width: 20, height: 20}} />                        
                              )}
                              
                            </Button> 
                          </Grid>         

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckAdministrador}                          
                                  onChange={() => {handleChecksRoles('rolesCheckAdministrador')}}
                                  color="primary"
                                />
                              }
                              label="Administrador"
                            /> 
                          </Grid>      

                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Acesso total ao sistema.
                            </MDTypography>
                          </Grid>      

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckGerente}                          
                                  onChange={() => {handleChecksRoles('rolesCheckGerente')}}
                                  color="primary"
                                />
                              }
                              label="Gerente"
                            /> 
                          </Grid>   
                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Acesso a todo sistema, menos ao cadastro de funcionários.
                            </MDTypography>
                          </Grid>         

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckGestaoCliente}                          
                                  onChange={() => {handleChecksRoles('rolesCheckGestaoCliente')}}
                                  color="primary"
                                />
                              }
                              label="Gestão de Cliente "
                            /> 
                          </Grid>     

                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Acesso ao cadastro de clientes e rotinas de contratação, carga horária e turma, relatórios pertinentes e visualização na tela inicial.
                            </MDTypography>
                          </Grid>

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckGestaoContratacao}                          
                                  onChange={() => {handleChecksRoles('rolesCheckGestaoContratacao')}}
                                  color="primary"
                                />
                              }
                              label="Gestão de Contratação "
                            /> 
                          </Grid>     

                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Rotinas de contratação, carga horária e turma, relatórios pertinentes e visualização na tela inicial.
                            </MDTypography>
                          </Grid>

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckFinanceiroGeral}                          
                                  onChange={() => {handleChecksRoles('rolesCheckFinanceiroGeral')}}
                                  color="primary"
                                />
                              }
                              label="Financeiro Geral"
                            /> 
                          </Grid> 

                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Acesso ao contas a pagar, fornecedores, mensalidades, relatórios pertinentes e visualização na tela inicial.
                            </MDTypography>
                          </Grid>
                          
                            

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckFinanceiroClientes}                          
                                  onChange={() => {handleChecksRoles('rolesCheckFinanceiroClientes')}}
                                  color="primary"
                                />
                              }
                              label="Financeiro de Clientes"
                            /> 
                          </Grid>    
                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Acesso às mensalidades, relatórios pertinentes e visualização na tela inicial.
                            </MDTypography>
                          </Grid>

                          <Grid item xs={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rolesCheckFinanceiroFinanceiroDespesas}                          
                                  onChange={() => {handleChecksRoles('rolesCheckFinanceiroFinanceiroDespesas')}}
                                  color="primary"
                                />
                              }
                              label="Financeiro para Despesas"
                            /> 
                          </Grid>  

                          <Grid item xs={6} sm={12} mt={-2}>
                            <MDTypography style={{fontSize: 12 }}>
                              Acesso ao contas a pagar, fornecedores, relatórios pertinentes e visualização na tela inicial.
                            </MDTypography>
                          </Grid>                      

                          <Grid item xs={12}>
                            <TextField                      
                              label="Observação"
                              variant="outlined"
                              value={observacao}                      
                              onChange={(event) => {setObservacao(event.target.value)}}
                              fullWidth
                            /> 
                          </Grid>                          

                          </Grid>                  
                          {alertError ? (
                              <>
                                <Alert
                                  severity={alertError ? "error" : "success"}
                                  action={
                                    <>                                                        
                                        <Button color="inherit" size="small"
                                          onClick={() => {setAlertError(false), setMsgError('')}}
                                          style={{marginRight: 60}}
                                        >
                                          OK
                                        </Button>
                                    </>
                                    }                                                                                  
                                >
                                  {alertError ? msgError : msgSuccess}
                                </Alert>
                              </>
                            ):(
                              <>
                                  <Grid item xs={12} p={4}>
                                    <Button variant="contained" color="primary" type="submit" fullWidth                                                                
                                    >
                                      Salvar
                                    </Button>
                                  </Grid>  
                                </>
                            )}             
                      </form>            
                    </Card>           

                  </Grid>

                  

                </Grid>
              </MDBox>      
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default CadFuncionarios;
