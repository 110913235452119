/* eslint-disable @typescript-eslint/no-empty-function */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate  } from "react-router-dom";
import Button from '@mui/material/Button';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MDButton from "components/MDButton";
import { TextField, Box, Checkbox } from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import { decodeToken, isExpired } from "react-jwt";
import { Alert, Modal, Snackbar, Typography } from "@mui/material";
import { ReAuth } from "layouts/authentication/reAuth";
import { verifyRolesAcess } from "../../services/Utils/verifyRolesAcess";
import clienteService from "../../services/ClienteService";
import funcionarioService from "../../services/FuncionarioService";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import HomeIcon from '@mui/icons-material/Home';
import imgLogo from '../../assets/images/logoAnamnese.png'



const useStyles = makeStyles((theme) => ({
  container: {    
    flexDirection: 'row',    
  },
  item: {
    width: 100,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignEndItem: {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  },
  boxFilter : {
    flexDirection: 'row', marginTop: 5, flex: 1, height: '20px'
  }
}));

const customInputStyle = {
  width: '100%',
  padding: 26,
  marginTop: -16 
};
 

function Anamenese() {  

  const queryParams = new URLSearchParams(location.search);
  const idInfo = queryParams.get('idInfo'); 
  
  //Objetivos
  const [objetivoEmagrecimento, setObjetivoEmagrecimento] = useState(false);
  const [objetivoOcupacaoTempoLivre, setObjetivoOcupacaoTempoLivre] = useState(false);
  const [objetivoGastoEnergia, setObjetivoGastoEnergia] = useState(false);
  const [objetivoDesenvolvimentoPsicomotor, setObjetivoDesenvolvimentoPsicomotor] = useState(false);
  const [objetivoSaudePreventiva, setObjetivoSaudePreventiva] = useState(false);
  const [objetivoIndicacaoMedica, setObjetivoIndicacaoMedica] = useState(false);
  const [objetivoIncentivarPraticaAtividadeFisica, setObjetivoIncentivarPraticaAtividadeFisica] = useState(false);

  //InfoPeso e estatura
  const [peso, setPeso] = useState(10);
  const [estatura, setEstatura] = useState(0.00);

  //Data historico avaliacao medica
  const [historicoDataUltimaAvaliacaoMedica, setHistoricoDataUltimaAvaliacaoMedica] = useState('');

  
  //Intervencao cirurgica 
  const [historicoAlunoSofreuIntervencaoCirurgica, setHistoricoAlunoSofreuIntervencaoCirurgica] = useState(false);
  const [historicoQualIntervencaoCirurgica, setHistoricoQualIntervencaoCirurgica] = useState('');

  //Lesao Osteomuscular
  const [historicoAlunoSofreuLesaoOsteomuscular, setHistoricoAlunoSofreuLesaoOsteomuscular] = useState(false);
  const [historicoQualLesaoOsteomuscular , setHistoricoQualLesaoOsteomuscular ] = useState('');

  //Medicamentos Utilizados 
  const [historicoMedicamentosUtilizadosUltimosTempos, setHistoricoMedicamentosUtilizadosUltimosTempos] = useState('');

  //Diagnosticada
  const [diagnosticadaTratadaMedicoDiabetes, setDiagnosticadaTratadaMedicoDiabetes] = useState(false);
  const [diagnosticadaTratadaMedicoColesterolAlto, setDiagnosticadaTratadaMedicoColesterolAlto] = useState(false);
  const [diagnosticadaTratadaMedicoHipertensao, setDiagnosticadaTratadaMedicoHipertensao] = useState(false);
  const [diagnosticadaTratadaMedicoDoencaCardiorrespiratoria, setDiagnosticadaTratadaMedicoDoencaCardiorrespiratoria] = useState(false);
  const [diagnosticadaTratadaMedicoAsma, setDiagnosticadaTratadaMedicoAsma] = useState(false);
  const [diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica, setDiagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica] = useState(false);
  const [diagnosticadaTratadaMedicoObesidade, setDiagnosticadaTratadaMedicoObesidade] = useState(false);
  const [diagnosticadaTratadaMedicoAnemia, setDiagnosticadaTratadaMedicoAnemia] = useState(false); 
  const [diagnosticadaTratadaMedicoTDAH, setDiagnosticadaTratadaMedicoTDAH] = useState(false);
  const [diagnosticadaTratadaMedicoTEA, setDiagnosticadaTratadaMedicoTEA] = useState(false); 
  const [diagnosticadaTratadaMedicoTOD, setDiagnosticadaTratadaMedicoTOD] = useState(false);   
  const [diagnosticadaOutrasChk, setDiagnosticadaOutrasChk] = useState(false);  
  const [diagnosticadaTratadaMedicoOutras, setDiagnosticadaTratadaMedicoOutras] = useState('');

  //Informacoes Adicionais
  const [informacoesAdicionais, setInformacoesAdicionais] = useState('');

  //Informacoes cadastrais
  const [clienteId, setClienteId] = useState('');
  const [titularId, setTitularId] = useState('');
  const [nome, setNome] = useState('');
  const [genero, setGenero] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [nomeResponsavel, setNomeResponsavel1 ] = useState('');
  const [telefoneResponsavel1, setTelefoneResponsavel1] = useState('');
  const [emailResponsavel1, setEmailResponsavel1] = useState('');  

  //Required que não entraram
  const [possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude, setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude] = useState(false);
  const [senteDoresPeitoRealizaAtividadeFisica, setSenteDoresPeitoRealizaAtividadeFisica] = useState(false);
  const [apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia, setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia] = useState(false);
  const [possuiProblemaOsseoOuArticularPodePiorarAtividadeFisica, setPossuiProblemaOsseoOuArticularPodePiorarAtividadeFisica] = useState(false);
  const [tomaMedicamentoPressaoArterialOuCoracao, setTomaMedicamentoPressaoArterialOuCoracao] = useState(false);
  const [algumaOutraRazaoNaoPraticarAtividadeFisica, setAlgumaOutraRazaoNaoPraticarAtividadeFisica] = useState(false);
  const [termoResponsabilidadeParaPraticaAtividadeFisicaAssinado, setTermoResponsabilidadeParaPraticaAtividadeFisicaAssinado] = useState(false);
  const [assinaturaResponsavel , setAssinaturaResponsavel] = useState('');

  const [stepErrorEstatura, setStepErrorEstatura] = useState(false);
  const [stepErrorPeso, setStepErrorPeso] = useState(false);
  
  
  const [inProp, setInProp] = useState(false);

  const [onHoverBtContinue, setOnHoverBtContinue] = useState(false);
  const [onHoverBtGoBack, setOnHoverBtGoBack] = useState(false);

  const [step, setStep] = useState(0);
  const [activeAnimation, setActiveAnimation] = useState(false);

  const [invalidPermission, setInvalidPermission] = useState(false);
  

  const nodeRef = useRef(null);
  const duration = 600;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  };

  

  const handlePesoChange = (event) => {
    let text = event.target.value
    let newText = text.replace(/,/g, '.'); 
    setPeso(newText);
  };

  const handleEstaturaChange = (value) => {
    let hasCompletDigit = /^\d+\.\d{3}$/.test(value)
    let newText = hasCompletDigit ? parseFloat(value).toFixed(2) : value
    setEstatura(newText.toString());   
  } 

  const incPeso = () => {     
    setPeso((prevPeso) => String(Number(prevPeso) + 1));
  };

  const decPeso = () => {    
    setPeso((prevPeso) => String(Number(prevPeso) - 1));
  };

  function formatDateInfos(inputDate){      
    
    var dateSplit = inputDate.split('/');    
    var formattedDate = new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1]);
    
    var year = formattedDate.getFullYear();
    var month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    var day = String(formattedDate.getDate()).padStart(2, '0');

    
    var dateFormat = year + '-' + month + '-' + day;
    return dateFormat;    
  }

  const handleGetInfoPaciente = async (id) => {
    await clienteService.getByID(id)
      .then(async (result) => {
        await clienteService.getByID(result.data.titularId)
          .then((dataTitular) => {            
            setClienteId(result.data.id);
            setNome(result.data.nomeCliente);        
            setTitularId(result.data.titularId);
            setNomeResponsavel1(result.data.nomeTitular);        
            setEmailResponsavel1(dataTitular.data.email);
            setTelefoneResponsavel1(dataTitular.data.telefoneCelular)
            var data = new Date(dataTitular.data.dataNascimento);
            var day = data.getDate();
            var month = data.getMonth() + 1;
            var year = data.getFullYear();
            data = day + "/" + month + "/" + year;
            setDataNascimento(formatDateInfos(data));
          })        
      })
      .catch((error) => {       
      })
  }

  useEffect(() => {     
    handleGetInfoPaciente(idInfo);
    
    setActiveAnimation(false);
    setStep(1);        
  }, [])

  useEffect(() => { 
    peso < 0 && setPeso(0);    
  }, [peso])

  useEffect(() => {    
    setActiveAnimation(true);

    if (step === 5){     
      if (estatura === 0 ) {
        setStep(4);
        setStepErrorEstatura(true);
      } 
    }

    if (step === 4){     
      if (peso === 0 ) {
        setStep(3);
        setStepErrorPeso(true);
      } 
    }
  }, [step])  

  function formatDtNasc(dtNasc) {      
    var valueSetRg = '';        
    valueSetRg = dtNasc.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3');          
    
    return valueSetRg;
  }

  const handleCheckIntervencaoCirurgica = (value) => {
    if (value === 'sim'){
      setHistoricoAlunoSofreuIntervencaoCirurgica(true)
    }else{
      setHistoricoAlunoSofreuIntervencaoCirurgica(false);
    }                

  }

  const handleNextAndPriorSteps = (op) => { 
    setStepErrorEstatura(false);
    setStepErrorPeso(false);
    setActiveAnimation(false); 
    op === '+' && setStep(step+1);
    op === '-' && setStep(step-1);    
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();      
  }

  const objetivosQuestion = () => {        
    return (
      <>
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Quais objetivos espera para seu filho na atividade matriculada ?
        </MDTypography>          
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoEmagrecimento}                          
              onChange={(event) => {setObjetivoEmagrecimento(!objetivoEmagrecimento)}}
              color="primary"
            />
          }label="Emagrecimento"
        />           
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoOcupacaoTempoLivre}                          
              onChange={() => {setObjetivoOcupacaoTempoLivre(!objetivoOcupacaoTempoLivre)}}
              color="primary"
            />
          }label="Ocupação do tempo livre"
        />           
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoGastoEnergia}                          
              onChange={(event) => {setObjetivoGastoEnergia(!objetivoGastoEnergia)}}
              color="primary"
            />
          }label="Gasto de energia"
        />       
        {window.innerWidth < 768 ? (<>
          <FormControlLabel
              control={
                <Checkbox
                  checked={objetivoDesenvolvimentoPsicomotor}                          
                  onChange={() => {setObjetivoDesenvolvimentoPsicomotor(!objetivoDesenvolvimentoPsicomotor)}}
                  color="primary"
                />
              }label={
                <div style={{ width: '150px', top: '10px' }}>
                  <div style={{marginTop: '26px'}}>
                    "Desenvolvimento psicomotor"
                  </div>                    
                </div>
                }
            />   
        </>):(
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={objetivoDesenvolvimentoPsicomotor}                          
                  onChange={() => {setObjetivoDesenvolvimentoPsicomotor(!objetivoDesenvolvimentoPsicomotor)}}
                  color="primary"
                />
              }label="Desenvolvimento psicomotor"
            />   
          </>
        )}    
        

        {window.innerWidth < 768 ? (
          <>
            <Grid item xs={12} sm={2}>     
              <FormControlLabel
                control={
                  <Checkbox
                    checked={objetivoSaudePreventiva}                          
                    onChange={() => {setObjetivoSaudePreventiva(!objetivoSaudePreventiva)}}
                    color="primary"
                  />
                }label={
                  <div style={{ width: '150px', top: '10px' }}>
                    <div style={{marginTop: '26px'}}>
                      "Saúde preventiva(sem indicação médica)"
                    </div>                    
                  </div>
                  }
              />           
            </Grid>
          </>
        ):(
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={objetivoSaudePreventiva}                          
                  onChange={() => {setObjetivoSaudePreventiva(!objetivoSaudePreventiva)}}
                  color="primary"
                />
              }label="Saúde preventiva(sem indicação médica)"                                
            />   
          </>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoIndicacaoMedica}                          
              onChange={() => {setObjetivoIndicacaoMedica(!objetivoIndicacaoMedica)}}
              color="primary"
            />
          }label="Indicação médica"
        />           
        <FormControlLabel
          control={
            <Checkbox
              checked={objetivoIncentivarPraticaAtividadeFisica}                          
              onChange={() => {setObjetivoIncentivarPraticaAtividadeFisica(!objetivoIncentivarPraticaAtividadeFisica)}}
              color="primary"
            />
          }label={
            <div style={{ width: '150px', top: '10px' }}>
              "Incentivar a prática de atividade física"
            </div>
            
          }
        />                     

      </div>

  
      </>
        
    )
  }

  const dataUltimaAvaliacaoMedica = () => {        
    return (
    
          <div style={{marginTop: 40}}>
            <MDTypography style={{alignSelf: 'flex-end'}}>
              Data da última avaliação médica
            </MDTypography>    
            <Grid item xs={12} sm={2}>
              <TextField
                label=""
                name="date"
                value={historicoDataUltimaAvaliacaoMedica}
                onChange={(event) => {setHistoricoDataUltimaAvaliacaoMedica(formatDtNasc(event.target.value))}}
                placeholder="DD/MM/YYYY" 
                fullWidth                                      
              />
            </Grid> 
          </div>


    )
  }  

  const intervencaoCirurgica = () => {    
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Já sofreu alguma intervenção cirurgica ? 
        </MDTypography>
        <MDBox display={'flex'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={historicoAlunoSofreuIntervencaoCirurgica}                          
                onChange={() => {{handleCheckIntervencaoCirurgica('sim')}}}
                color="primary"
              />
            }label="Sim"
          />             
          <FormControlLabel
            control={
              <Checkbox
                checked={!historicoAlunoSofreuIntervencaoCirurgica}                          
                onChange={() => {{handleCheckIntervencaoCirurgica('nao')}}}
                color="primary"
              />
            }label="Não"
          />  
            {historicoAlunoSofreuIntervencaoCirurgica && (
              <TextField
                style={{marginTop: 20}}
                id="nomeField"
                focused={true}            
                label="Qual ?"
                name="historicoQualIntervencaoCirurgica"
                value={historicoQualIntervencaoCirurgica}
                onChange={(event) => {setHistoricoQualIntervencaoCirurgica(event.target.value)}}
                fullWidth          
              />  
            )}
        </MDBox>
      </div>
    )
    
    
  }

  const lesaoOsteomuscular = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Já teve alguma lesão Osteomuscular ? 
        </MDTypography>
        <MDBox display={'flex'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={historicoAlunoSofreuLesaoOsteomuscular}                          
                onChange={() => {setHistoricoAlunoSofreuLesaoOsteomuscular(!historicoAlunoSofreuLesaoOsteomuscular)}}
                color="primary"
              />
            }label="Sim"
          />             
          <FormControlLabel
            control={
              <Checkbox
                checked={!historicoAlunoSofreuLesaoOsteomuscular}                          
                onChange={() => {setHistoricoAlunoSofreuLesaoOsteomuscular(!historicoAlunoSofreuLesaoOsteomuscular)}}
                color="primary"
              />
            }label="Não"
          />  
          {historicoAlunoSofreuLesaoOsteomuscular && (            
            <TextField
              style={{marginTop: 20}}
              id="nomeField"
              focused={true}            
              label="Qual ?"
              name="historicoQualLesaoOsteomuscular"
              value={historicoQualLesaoOsteomuscular}
              onChange={(event) => {setHistoricoQualLesaoOsteomuscular(event.target.value)}}
              fullWidth          
            />  
          )}
        </MDBox>
      </div>
    )
  }

  const descricaoMedicamentoUtilizados = () => {
    return(
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Descreva o(s) medicamento(s) utilizado(s) nos últimos tempos: 
        </MDTypography>
        <TextField
          style={{marginTop: 20}}
          id="nomeField"
          focused={true}            
          label="Medicamentos: "
          name="name"
          value={historicoMedicamentosUtilizadosUltimosTempos}
          onChange={(event) => {setHistoricoMedicamentosUtilizadosUltimosTempos(event.target.value)}}
          fullWidth          
         />  
      </div>
    )
  }

  const condicoesDiagnosticadas = () => {
    return(
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Marque a(s) condiçõe(s) abaixo que tenham sido diagnosticadas ou tratadas por um médico:
        </MDTypography>
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoDiabetes}                          
              onChange={(event) => {{setDiagnosticadaTratadaMedicoDiabetes(!diagnosticadaTratadaMedicoDiabetes)}}}
              color="primary"
            />
          }label="Diabetes"
        />  
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoColesterolAlto}                          
              onChange={(event) => {{setDiagnosticadaTratadaMedicoColesterolAlto(!diagnosticadaTratadaMedicoColesterolAlto)}}}
              color="primary"
            />
          }label="Colesterol Alto"
        />  
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoHipertensao}                          
              onChange={() => {setDiagnosticadaTratadaMedicoHipertensao(!diagnosticadaTratadaMedicoHipertensao)}}
              color="primary"
            />
          }label="Hipertenção"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoDoencaCardiorrespiratoria}                          
              onChange={() => {setDiagnosticadaTratadaMedicoDoencaCardiorrespiratoria(!diagnosticadaTratadaMedicoDoencaCardiorrespiratoria)}}
              color="primary"
            />
          }label="Doenças cardiorrespiratória"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoAsma}                          
              onChange={() => {setDiagnosticadaTratadaMedicoAsma(!diagnosticadaTratadaMedicoAsma)}}
              color="primary"
            />
          }label="Asma"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica}                          
              onChange={() => {setDiagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica(!diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica)}}
              color="primary"
            />
          }label={
            <div style={{ width: '150px', top: '10px' }}>
              "Epilepsia ou outra condição neurológica"
            </div>                          
          }          
        />                        
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoObesidade}                          
              onChange={() => {setDiagnosticadaTratadaMedicoObesidade(!diagnosticadaTratadaMedicoObesidade)}}
              color="primary"
            />
          }label="Obesodade"          
        />                        
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoAnemia}                          
              onChange={() => {setDiagnosticadaTratadaMedicoAnemia(!diagnosticadaTratadaMedicoAnemia)}}
              color="primary"
            />
          }label="Anemia"          
        />              
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoTDAH}                          
              onChange={() => {setDiagnosticadaTratadaMedicoTDAH(!diagnosticadaTratadaMedicoTDAH)}}
              color="primary"
            />
          }label="TDAH"          
        />              
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoTEA}                          
              onChange={() => {setDiagnosticadaTratadaMedicoTEA(!diagnosticadaTratadaMedicoTEA)}}
              color="primary"
            />
          }label="TEA"          
        />                                 
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaTratadaMedicoTOD}                          
              onChange={() => {setDiagnosticadaTratadaMedicoTOD(!diagnosticadaTratadaMedicoTOD)}}
              color="primary"
            />
          }label="TOD"          
        />   
        <FormControlLabel
          control={
            <Checkbox
              checked={diagnosticadaOutrasChk}                          
              onChange={() => {setDiagnosticadaOutrasChk(!diagnosticadaOutrasChk)}}
              color="primary"
            />
          }label="Outras"          
        />      
        {diagnosticadaOutrasChk && (
          <TextField
            style={{marginTop: 20}}
            id="diagnosticadaTratadaMedicoOutras"
            focused={true}            
            label="Por favor, descreva a outra condição diagnosticada."
            name="diagnosticadaTratadaMedicoOutras"
            value={diagnosticadaTratadaMedicoOutras}
            onChange={(event) => {setDiagnosticadaTratadaMedicoOutras(event.target.value)}}
            fullWidth          
          /> 
        )}                      
      </div>
    )
  }

  const infoPeso = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Qual o peso ?
        </MDTypography>
        <Grid item xs={12} sm={2}>
        <IconButton onClick={decPeso}>
          <RemoveIcon />
        </IconButton>
          <TextField
            style={{marginTop: 0, marginLeft: 8}}
            id="nomeField"
            focused={true}      
            type='number'      
            label=""
            name="peso"
            value={peso}
            onChange={handlePesoChange}
            fullWidth          
          /> 
        <IconButton onClick={incPeso} style={{marginTop: 8}}>
          <AddIcon />
        </IconButton> 
        </Grid>
      </div>
    )

  }

  const infoEstatura = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Qual a estatura ?
        </MDTypography>
        <Grid item xs={12} sm={2}>
          <TextField
            style={{marginTop: 0}}
            id="nomeField"
            focused={true}            
            label=""
            name="estatura"
            value={estatura}
            type='number'
            onChange={(event) => handleEstaturaChange(event.target.value.replace(/,/g, '.').replace(/\.{2,}/g, "."))}
            fullWidth          
          />  
        </Grid>
      </div>
    )
  }

  const infoAdicionais = () => {
    return (
      <div style={{marginTop: 40}}>
        <MDTypography style={{alignSelf: 'flex-end'}}>
          Informações Adicionais
        </MDTypography>
        <Grid item xs={12} sm={12}>
          <TextField
            style={{marginTop: 0}}
            id="informacoesAdicionais"
            focused={true}            
            label="Informações: "
            name="informacoesAdicionais"  
            value={informacoesAdicionais}
            onChange={(event) => setInformacoesAdicionais(event.target.value)}
            fullWidth          
          />  
        </Grid>
      </div>
    )
  }

  const possuiProblemaCoracao = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Possui problema de coração ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude}                          
                  onChange={() => {{handleCheckPossuiProblemaCoracao('sim')}}}
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude}                          
                  onChange={() => {{handleCheckPossuiProblemaCoracao('nao')}}}
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
      )
  }

  const senteDoresPeito = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Sente dores no peito ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={senteDoresPeitoRealizaAtividadeFisica}                          
                  onChange={() => {setSenteDoresPeitoRealizaAtividadeFisica(true)}}
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!senteDoresPeitoRealizaAtividadeFisica}                          
                  onChange={() => {{setSenteDoresPeitoRealizaAtividadeFisica(false)}}}
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
      )
  }

  const desequilibrioPerdaConsciencia = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Apresenta desequilibrio devido a tontura ou perda de conciência ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia}                          
                  onChange={() => {setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia(true)}}
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia}                          
                  onChange={() => {{setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia(false)}}}
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
    )
  }

  const tomaMedicamentoPressaoArterial = () => {
    return (
        <div style={{marginTop: 40}}>
          <MDTypography style={{alignSelf: 'flex-end'}}>
            Toma medicamento de pressão arterial ?
          </MDTypography>
          <MDBox display={'flex'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tomaMedicamentoPressaoArterialOuCoracao}                          
                  onChange={() => {setTomaMedicamentoPressaoArterialOuCoracao(true)}}
                  color="primary"
                />
              }label="Sim"
            />             
            <FormControlLabel
              control={
                <Checkbox
                  checked={!tomaMedicamentoPressaoArterialOuCoracao}                          
                  onChange={() => {{setTomaMedicamentoPressaoArterialOuCoracao(false)}}}
                  color="primary"
                />
              }label="Não"
            />                                         
          </MDBox>
        </div>
    )
  }  

  /**
  const [possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude, setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude] = useState(false);
  const [senteDoresPeitoRealizaAtividadeFisica, setSenteDoresPeitoRealizaAtividadeFisica] = useState(false);
  const [apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia, setApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia] = useState(false);
  const [possuiProblemaOsseoOuArticularPodePiorarAtividadeFisica, setPossuiProblemaOsseoOuArticularPodePiorarAtividadeFisica] = useState(false);
  const [tomaMedicamentoPressaoArterialOuCoracao, TomaMedicamentoPressaoArterialOuCoracao] = useState(false);
  const [algumaOutraRazaoNaoPraticarAtividadeFisica, setAlgumaOutraRazaoNaoPraticarAtividadeFisica] = useState(false);
  const [termoResponsabilidadeParaPraticaAtividadeFisicaAssinado, setTermoResponsabilidadeParaPraticaAtividadeFisicaAssinado] = useState(false);
  const [assinaturaResponsavel , setAssinaturaResponsavel] = useState('');
  */

  const handleCheckPossuiProblemaCoracao = (value) => {
    if (value === 'sim'){
        setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude(true)
      }else{
        setPossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude(false)
      }                
  }

  const handleMouseEnterBtContinue = () => {    
    setOnHoverBtContinue(!onHoverBtContinue);    
  };

  const handleMouseLeaveBtContinue = () =>{    
    setOnHoverBtContinue(!onHoverBtContinue);
  }

  const handleMouseEnterBtGoBack = () => {    
    setOnHoverBtGoBack(!onHoverBtGoBack);    
  };

  const handleMouseLeaveBtGoBack = () =>{    
    setOnHoverBtGoBack(!onHoverBtGoBack);
  }

  const handlePostAnamnese = async () => {    
    var objPost = {            
      ClienteId : clienteId,
      TitularId : titularId,                                     
      Nome : nome,       
      Genero: 'Masculino',                
      DataNascimento: dataNascimento,
      NomeResponsavel1: nomeResponsavel,  
      EmailResponsavel1: emailResponsavel1, 
      TelefoneResponsavel1: telefoneResponsavel1,     
      
      ObjetivoEmagrecimento: objetivoEmagrecimento,       
      ObjetivoOcupacaoTempoLivre: objetivoOcupacaoTempoLivre,
      ObjetivoGastoEnergia: objetivoGastoEnergia,       
      ObjetivoDesenvolvimentoPsicomotor: objetivoDesenvolvimentoPsicomotor,
      ObjetivoSaudePreventiva: objetivoSaudePreventiva,        
      ObjetivoIndicacaoMedica: objetivoIndicacaoMedica,        
      ObjetivoIncentivarPraticaAtividadeFisica: objetivoIncentivarPraticaAtividadeFisica,               

      Peso: peso,                
      Estatura: estatura,
      
      
      HistoricoDataUltimaAvaliacaoMedica: historicoDataUltimaAvaliacaoMedica === '' ? '1991-01-01' : formatDateInfos(historicoDataUltimaAvaliacaoMedica),                               
      HistoricoAlunoSofreuIntervencaoCirurgica: historicoAlunoSofreuIntervencaoCirurgica,
      HistoricoQualIntervencaoCirurgica: historicoQualIntervencaoCirurgica,        
      HistoricoAlunoSofreuLesaoOsteomuscular: historicoAlunoSofreuLesaoOsteomuscular,
      HistoricoQualLesaoOsteomuscular: historicoQualLesaoOsteomuscular,
      HistoricoMedicamentosUtilizadosUltimosTempos: historicoMedicamentosUtilizadosUltimosTempos,
      
      DiagnosticadaTratadaMedicoDiabetes: diagnosticadaTratadaMedicoDiabetes,        
      DiagnosticadaTratadaMedicoColesterolAlto: diagnosticadaTratadaMedicoColesterolAlto,        
      DiagnosticadaTratadaMedicoHipertensao: diagnosticadaTratadaMedicoHipertensao,         
      DiagnosticadaTratadaMedicoDoencaCardiorrespiratoria: diagnosticadaTratadaMedicoDoencaCardiorrespiratoria,          
      DiagnosticadaTratadaMedicoAsma: diagnosticadaTratadaMedicoAsma,         
      DiagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica: diagnosticadaTratadaMedicoEpilepsiaCondicaoNeurologica,
      DiagnosticadaTratadaMedicoObesidade : diagnosticadaTratadaMedicoObesidade,        
      DiagnosticadaTratadaMedicoAnemia: diagnosticadaTratadaMedicoAnemia,        
      DiagnosticadaTratadaMedicoTDAH: diagnosticadaTratadaMedicoTDAH,        
      DiagnosticadaTratadaMedicoTEA: diagnosticadaTratadaMedicoTEA,        
      DiagnosticadaTratadaMedicoTOD: diagnosticadaTratadaMedicoTOD,      
      DiagnosticadaOutrasChk : diagnosticadaOutrasChk,
      DiagnosticadaTratadaMedicoOutras: diagnosticadaTratadaMedicoOutras, 
      
      PossuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude: possuiProblemaCoracaoAtividadeFisicaSupProfissionalSaude,    
      SenteDoresPeitoRealizaAtividadeFisica: senteDoresPeitoRealizaAtividadeFisica,                            
      ApresentaDesequilibrioDevidoTonturaOuPerdaConsciencia: apresentaDesequilibrioDevidoTonturaOuPerdaConsciencia,         
      PossuiProblemaOsseoOuArticularPodePiorarAtividadeFisica: possuiProblemaOsseoOuArticularPodePiorarAtividadeFisica,         
      TomaMedicamentoPressaoArterialOuCoracao: tomaMedicamentoPressaoArterialOuCoracao, 
      
      AlgumaOutraRazaoNaoPraticarAtividadeFisica: false,
      OutraRazaoNaoPraticarAtividadeFisica: '',
      
      TermoResponsabilidadeParaPraticaAtividadeFisicaAssinado: false,
      
      InformacoesAdicionais: informacoesAdicionais,        
      AssinaturaResponsavel: 'Assinado',                        
    }           

    try {      
      await clienteService.postAnamnese(objPost)
        .then((result) => {  
          if (result.data.sucesso){
            handleNextAndPriorSteps('+');
          }
        })
        .catch(async (error) => {
          
          let objInsertLogCatch1 = {
            mensagem: 'Erro ao concluir anamnese.',
            detalhes: JSON.stringify(objPost),
            local: 'Cadastro de Anamnese'
          };          

          await clienteService.postLogAnamnese(objInsertLogCatch1)
            .then((result) => {              
            })
        })
    } catch (error) {
      let objInsertLogCatch2 = {
        mensagem: 'Erro ao concluir anamnese.',
        detalhes: JSON.stringify(objPost),
        local: 'Cadastro de Anamnese'
      };      

      await clienteService.postLogAnamnese(objInsertLogCatch2)
        .then(() => {          
        })
    }
  }  
  
 
  return (        
    <MDBox padding={8}>
      {invalidPermission ? (
        <Alert severity="error">
          Você não tem permissão para acessar esse recurso.
        </Alert>
      ):(
        <>
          <Grid item xs={12} sm={12} style={{}}>  
            <img src={imgLogo} style={{marginLeft: -16, width: 300, height: 120}}/>  
            <MDTypography style={{fontWeigth: '700', fontSize: 24}}>Questionário Anamnese</MDTypography>  
            <Transition nodeRef={nodeRef} in={activeAnimation} timeout={duration}>
              {state => (
              <div ref={nodeRef} style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}>
            
                {step === 1 && (<>{objetivosQuestion()}</>)}
                {step === 2 && (<>{dataUltimaAvaliacaoMedica()}</>)}
                {step === 3 && (<>{infoPeso()}</>)}
                {step === 4 && (<>{infoEstatura()}</>)}
                {step === 5 && (<>{intervencaoCirurgica()}</>)}
                {step === 6 && (<>{lesaoOsteomuscular()}</>)}
                {step === 7 && (<>{descricaoMedicamentoUtilizados()}</>)}
                {step === 8 && (<>{condicoesDiagnosticadas()}</>)}          
                {step === 9 && (<>{possuiProblemaCoracao()}</>)}          
                {step === 10 && (<>{senteDoresPeito()}</>)}          
                {step === 11 && (<>{desequilibrioPerdaConsciencia()}</>)}          
                {step === 12 && (<>{tomaMedicamentoPressaoArterial()}</>)}          
                {step === 13 && (<>{infoAdicionais()}</>)}
            
                </div>
            )}
            </Transition>  
            {(stepErrorEstatura) && (
              <>
                <Alert severity="error">
                  Por favor, informe a estatura.
                </Alert>
              </>
            )}    
            {(stepErrorPeso) && (
              <>
                <Alert severity="error">
                  Por favor, informe o peso.
                </Alert>
              </>
            )}
          </Grid>

          {step === 14 ? (        
            <Grid item xs={12} sm={4} style={{}}> 
              <MDTypography style={{alignSelf: 'flex-end'}}>
                Questionário de Anamenese concluído !!!
              </MDTypography>        
            </Grid>
          ):(
            <>
              <Grid item xs={12} sm={4} style={{}}>      
                {step === 13 ? (
                  <Button fullWidth color={'info'} style={{marginTop: 26, borderRadius: 8, height: 40, backgroundColor: '#7b0b99'}}
                    onMouseEnter={handleMouseEnterBtContinue}  
                    onMouseLeave={handleMouseLeaveBtContinue}   
                    onClick={() => {handlePostAnamnese()}}       
                  >
                    <MDTypography style={{alignSelf: 'center', color:'#FFF', fontWeigth: '700'}}>        
                      Concluir
                    </MDTypography>      
                  </Button>
                ):(
                  <Button fullWidth color={'info'} style={{marginTop: 26, borderRadius: 8, height: 40, backgroundColor: '#7b0b99'}}
                    onMouseEnter={handleMouseEnterBtContinue}  
                    onMouseLeave={handleMouseLeaveBtContinue}   
                    onClick={() => {handleNextAndPriorSteps('+')}}       
                  >
                    <MDTypography style={{alignSelf: 'center', color: '#FFF', fontWeigth: '700'}}>        
                      Continuar
                    </MDTypography>        
                  </Button>
                )}
              </Grid>
              {(step != 1) && (
                <>
                  <Grid item xs={12} sm={4} style={{}}>      
                    <Button fullWidth style={{marginTop: 26, borderRadius: 8, height: 40, backgroundColor: '#7b0b99'}}                       
                      onClick={() => {handleNextAndPriorSteps('-')}}            
                    >
                      <MDTypography style={{alignSelf: 'center', color: '#FFF', fontWeigth: '700'}}>        
                        Voltar
                      </MDTypography>        
                    </Button>
                  </Grid>
                </>
              )}   
            </> 
                   
          )}
        </>

      )}

    </MDBox>
  );
}

/**
          * {dataUltimaAvaliacaoMedica()}
          {infoPeso()}
          {infoEstatura()}
          {intervencaoCirurgica()}
          {lesaoOsteomuscular()}
          {descricaoMedicamentoUtilizados()}
          {condicoesDiagnosticadas()}
 */

export default Anamenese;

