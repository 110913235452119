import { forwardRef } from "react";

import PropTypes from "prop-types";

import MDButtonRoot from "components/MDButton/MDButtonRoot";
import { useMaterialUIController } from "context";
import MDModalRoot from "./MDModalRoot";

const MDModalQuestion = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDModalRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={"contained"}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </MDModalRoot>
    );
  }
);

// Setting default values for the props of MDButton
MDModalQuestion.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

// Typechecking props for the MDButton
MDModalQuestion.propTypes = {
 
};

export default MDModalQuestion;
