import MDTypography from '../MDTypography';
import MDBox from '../MDBox';
import * as React from 'react';
import { ContainerTitulo, Content } from './TitleScreenCrud.styled';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

interface TitleScreenCrudProps {
  title: string
  children: React.ReactNode;
}

export default function TitleScreenCrud({ title, children}: TitleScreenCrudProps) {  
  return (
    <ContainerTitulo>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              flexDirection="row"
            >
              <MDTypography variant="h6" color={"white"} flexDirection={"column"} ml={"10px"}>
                {title}
              </MDTypography>                 
            </MDBox>
            <Content>
              {children}
            </Content>
          </Card>          
        </Grid>
      </Grid>
    </ContainerTitulo>
  );
}
