import styled from 'styled-components'

interface ContainerHorariosProps {
  isAzul: boolean;  
}

export const TituloTeste = styled.h1`
  font-size: 35px;
`;

export const FilterInput = styled.input`
    margin-top: 16px;
    width: 80%;
    height: 32px;
    padding: 12px; 
    border-width: 4px;
    align-self: center;
    margin-left: 12px;
    border-radius: 8px;    
    border-color: purple;
    
    &:focus, &:active {
        border-color: purple;  
    }
`;

export const ContainerTableInfo = styled.div<ContainerHorariosProps>`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin-top: -26px;
`

export const ContainerHorariosDias = styled.div`
  width: 100%;  
  background-color: ${({ isAzul } : ContainerHorariosProps) => (isAzul ? 'blue' : 'white')};
  flex-direction: row;
  display: flex;
`

export const TextTitleHorarios = styled.h1`
  font-size: 16px;
  color: #343a40;
  font-weight: 700;
  margin-left: 28px;
`;

export const TextTitleDias = styled.h1`
  font-size: 16px;
  color: #343a40;
  font-weight: 700;
  margin-left: 8px;
`;


export const TextDescDias = styled.h1`
  font-size: 14px;
  color: #495057;  
  margin-left: 8px;
  margin-top: 3px ;
  font-weight: 100;
`;

export const TextDescHorario = styled.h1`
  font-size: 14px;
  color: #495057;
  font-weight: 700;
  margin-left: 8px;
  margin-top: 3px ;
`;


export const TextDesc = styled.h1`
  font-size: 14px;
  color: #495057;
  font-weight: 300;
  margin-left: 8px;
  margin-top: 3px ;
`;

export const Line = styled.div`
  width: 100%;
  border-width: 1px;
  border-top-color: #240046;
  border-bottom-color: white;
  border-left-color: white;
  border-right-color: white;

`  