import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
  z-index: 999;
`;

export const ContainerTitulo = styled.div`
  padding-top: 48px;
`;

export const Content = styled.div`
  padding: 16px;
`;