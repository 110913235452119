/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDTypography from "../../../shared/MDTypography";
import * as React from 'react';


import { getAll } from '../../ContratacaoService';
import { IContratacaoGetType } from "../../../../layouts/contratacao/ContratacaoType";
import ConvertDataPadrao from "../../../../infrastructure/convertDataPadrao";
import MDBox from '../../../shared/MDBox';
import { Tooltip } from '@material-ui/core';
import MDButton from '../../../shared/MDButton';
import IconEdit from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconInativa from '@mui/icons-material/PersonRemove';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

export default function data(valueSearch, typeBotton, objFull) {  
  const [rowsInfo, setRowsInfo] = React.useState([]);
  const [columns, setColummns] = React.useState([]);
  const [dataReturn, setDataReturn] = React.useState<IContratacaoGetType[]>([]);


 /* const handleFilterData = (search, infoReturn) => {    

    //console.log('data setada ', infoReturn[0]?.dataFimContratacao);
    

    if (search.startsWith('venc')) {
      const match = search.match(/-([0-9]+)/);
        if (match) {
            const numero = parseInt(match[1]);
            console.log('numer venc ', numero);
        }
    }
    let newInfoReturn = infoReturn.filter(x => x.nomeCliente.toLowerCase().includes(search.toLowerCase()));    
    if (newInfoReturn.length === 0){
      newInfoReturn = infoReturn.filter(x => x.email.toLowerCase().includes(search.toLowerCase()));    
      if (newInfoReturn.length === 0){
        newInfoReturn = infoReturn.filter(x => x.telefoneCelular.toLowerCase().includes(search.toLowerCase()));      
      }
    }   
    objFull(newInfoReturn); 
    handlePrepareRows(newInfoReturn);
  }*/

  const handleFilterData = (search, infoReturn) => {
    if (search.startsWith('venc')) {
        console.log('entrou venc');
        
        const dias = parseInt(search.split('-')[1]);

        if (!isNaN(dias)) {
            console.log('entrou dias');
            const dataAtual = new Date();
            const dataLimite = new Date(dataAtual);
            dataLimite.setDate(dataAtual.getDate() + dias);

            // Subtrai 8 dias da dataLimite
            dataLimite.setDate(dataLimite.getDate());

            const ano = dataLimite.getFullYear();
            const mes = String(dataLimite.getMonth() + 1).padStart(2, '0'); // Adicionando 1 porque os meses são zero indexados
            const dia = String(dataLimite.getDate()).padStart(2, '0');
            const hora = '00';
            const minuto = '00';
            const segundo = '00';

            const dataLimiteFormatada = `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}`;

            console.log('dataLimite ', dataLimiteFormatada);
            
            const newInfoReturn = infoReturn.filter(item => {
                const dataFimContratacao = new Date(item.dataFimContratacao);
                dataFimContratacao.setDate(dataFimContratacao.getDate() - 8);
                return dataFimContratacao < dataLimite;
            });

            objFull(newInfoReturn); 
            handlePrepareRows(newInfoReturn);
        }
    }else{
      const newInfoReturn = infoReturn.filter(x => x.nomeCliente.toLowerCase().includes(search.toLowerCase()));
      objFull(newInfoReturn); 
      handlePrepareRows(newInfoReturn);
    }
  }

  React.useEffect(() => {    
    setTimeout(() => {  
      if (valueSearch != null){
        handleFilterData(valueSearch, dataReturn);
      }             
    }, 2000);   
    
  }, [valueSearch]) 

  const handlePrepareRows = (infoReturn) => {    
    const arrayRows = [];
    const arrayListaContratacao = [];
    infoReturn.map((result, index) => {  
      {console.log('result ', result);
      }          

      const dataInicioContratacao = ConvertDataPadrao(result.dataInicioContratacao);
      const dataFimContratacao = ConvertDataPadrao(result.dataFimContratacao);      
      const totalLiquido = Number(result.totalLiquido).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const totalDesconto = Number(result.totalDesconto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

      const nome = (
        <>
          {result !== null && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {result.nomeCliente}          
              </MDTypography>
              {result.alunoInfo !== null && (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="false">                
                  {`Aluno: ${result?.alunoInfo[0]?.nomeCliente}`}          
                </MDTypography>
              )}
            </div>
          )}
        </>
      )       

      const telefone = (
        <>
          {result !== null && (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="light">
              {result.telefoneCelularCliente}
            </MDTypography>
          )}
        </>
      )

      const totDesconto = (
        <>
          {result !== null && (
            <>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" textAlign="right">
                {totalDesconto}
              </MDTypography>
            </>
          )}
        </>
      )

      const total = (
        <>
          {result !== null && (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {totalLiquido}
            </MDTypography>
          )}
        </>
      )

      const dataInicio = (
        <>
          {result !== null && (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {dataInicioContratacao}
            </MDTypography>
          )}
        </>
      )

      const dataFim = (
        <>
          {result !== null && (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {dataFimContratacao}
            </MDTypography>
          )}
        </>
      )   

      const status = (                                    
        <>
          {result !== null && (
            <MDBox ml={-1}>
              {!result.cancelada ? (
                <PersonRoundedIcon fontSize="medium" style={{color: 'green'}}/>              
              ):(
                <PersonRoundedIcon fontSize="medium" style={{color: 'red'}}/>              
              )}            
            </MDBox>                                
          )}
        </>
      )      
      
      const action = (
        <>
          {result !== null && (
            <MDBox>
              <Tooltip title="Clique para editar" arrow placement="left">
                <MDButton color={'info'} circular={true} size={'small'} 
                  onClick={() => {typeBotton('Editar')}}
                >                
                  <WhatsAppIcon />          
                </MDButton>            
              </Tooltip>
              <div style={{marginTop: 5}} />
              <Tooltip title="Clique para inativar" arrow placement="left">
                <MDButton color={'info'} circular={true} size={'small'} ml={1}                  
                  onClick={() => {typeBotton('Inativar')}}
                >                
                    <IconInativa />            
                </MDButton>
              </Tooltip>
            </MDBox> 
          )}
        </>
      )

      const itemObj = {
        nomeCliente: nome,
        telefoneCelularCliente: telefone,
        totDesconto: totDesconto,
        totalLiquido: total,
        dataInicioContratacao: dataInicio,
        dataFimContratacao: dataFim,  
        status: status,     
        action: action 
      }                               

      arrayRows.push(itemObj);    
      arrayListaContratacao.push(result);                 
    
    });
    
    setRowsInfo(arrayRows);
    objFull(arrayListaContratacao);       
  }

  const handlePrepareColumms = () => {
    let columns = [];    

    columns = [
      { Header: "Nome Cliente", accessor: "nomeCliente"},      
      { Header: "Telefone", accessor: "telefoneCelularCliente"}, 
      { Header: "Total Desconto.", accessor: "totDesconto", align: "center"},           
      { Header: "Total Liq.", accessor: "totalLiquido", align: "center"},      
      { Header: "Data Inicio", accessor: "dataInicioContratacao"},      
      { Header: "Data Fim", accessor: "dataFimContratacao"},                        
      { Header: "Status", accessor: "status"},    
      { Header: "Ação", accessor: "action", align: "center" }
    ]            
    
    setColummns(columns);
  }

  
  const handleGetAll = async () => {
    try {
      const result = await getAll();
      const data = result;
      setDataReturn(data);
  
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }
  

  React.useEffect(() => {        
    handleGetAll();
  }, [])

  React.useEffect(() => {  
    console.log('dataReturn ', dataReturn);
                    
    if (dataReturn.length > 0)  {                  
      handlePrepareRows(dataReturn);
      handlePrepareColumms();
    }    
  }, [dataReturn])

  return {    
    columns: columns,
    rows: rowsInfo
  };
}
