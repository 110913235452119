import styled from 'styled-components'

export const ContainerResumoContrato = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  margin-top: 4px;
`;

export const ContainerResumoIconDetail = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  margin-top: 4px;
  align-items: flex-end;
  justify-content: flex-end;    
  margin-right: 16px;
`;


export const ContainerResumoChecked = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;  
  align-items: flex-start;
  justify-content: flex-start;    
  margin-right: 16px;
`;

export const ContainerResumoContratoValor = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  margin-top: 4px;
  align-items: flex-end;
  justify-content: flex-end;  
`;

export const FieldTituloHora = styled.h1`
  font-size: 14px;
  font-weight: 700;
`;

export const TituloResumoGeralContratacao = styled.h1`
  font-size: 18px;
  color: #240046; 
  margin-left: 16px;
  text-align: right;    
`;

export const TextResumoGeralContratacao = styled.h1`
  font-size: 18px;
  color: #240046; 
  margin-left: 16px;
  font-weight: 400;
  text-align: right;     
  margin-right: 32px;  
`;

export const TituloResumoContratacao = styled.h1`
  font-size: 18px;
  color: #240046; 
  margin-left : 16px;  
`;

export const FieldTitulo = styled.h2`
  font-size: 16px;
  font-weight: 700;
`;

export const FieldTituloValor = styled.h2`
  font-size: 16px;
  font-weight: 700;
  //margin-top : 32px ;
`;

export const FieldInfoValor = styled.h2`
  margin-left: 8px;
  font-size: 14px;  
  align-self: center;
  font-weight: 400;
  color: purple; 
  margin-right: 16px;
  //margin-top : 32px ;
`;

export const FieldInfo = styled.h2`
  margin-left: 8px;
  font-size: 14px;  
  align-self: center;
  font-weight: 400;
  color: purple;
  margin-right: 16px;
`;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px dashed #240046;
`;

