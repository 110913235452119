/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import { getAllAtividades  } from './SearchAtividadesServices';
import { IAtividadesGetTypeSearch } from './SearchAtividadesType';

interface SearchAtividadesProps {
  isOpen: boolean,
  onClose: any,
  callBackInfos: (infoAtividade: IAtividadesGetTypeSearch[]) => void, 
}

export default function SearchAtividades({ isOpen, onClose, callBackInfos}: SearchAtividadesProps) {
  const autocompleteRef = useRef(null);

  const [searchText, setSearchText] = useState('');  
  const [options, setOptions] = useState([]);  
  const [callAtividadeLoad, setCallAtividadeLoad] = useState(false);  

  const handleSearch = async () => {                  
    const atividades = await getAllAtividades();  
    const infoCallBack = atividades.filter(a => a.descricao === searchText);
    callBackInfos(infoCallBack);        
  };

  const handleCallAtividade = async () => {
    try {
      const result = await getAllAtividades();
      console.log('result get all atividade ', result);
      
      const arrayDataFullAtividade: IAtividadesGetTypeSearch[] = [];
      const arrayInfoAtividade = [];
  
      result.map((data) => {                  
        arrayDataFullAtividade.push(data);
        arrayInfoAtividade.push(data.descricao);
      });
      
      setCallAtividadeLoad(true);      
  
      return arrayInfoAtividade;
    } catch (error) {
      console.error('Error in handleCallAtividade:', error);
      throw error;
    }
  };
  

  useEffect(() => {
    let isMounted = true;      

    handleCallAtividade()
      .then((result) => {
        if (isMounted) {          
          setOptions(result);          
        }
      })     

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Consulta Atividades</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Filtro a Atividade
        </DialogContentText>
        
        <div style={{width: "500px", marginTop:"14px"}}>
        </div>
          {callAtividadeLoad && (
            <Autocomplete
              id='autocom'                
              ref={autocompleteRef}
              value={searchText}
              onChange={(event, newValue) => {setSearchText(newValue ? newValue : '')}}            
              options={options ? options : []}
              renderInput={(params) => <TextField {...params} label="Atividade" id="autocom" />}
            />
          )}                  
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSearch} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}