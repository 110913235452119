/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import { getAllPlanos, getByNomePlano } from './SearchPlanosServices';
import { PlanosAtividades } from './SearchPlanosType';

interface SearchPlanosProps {
  isOpen: boolean,
  onClose: any,
  infoPlanoSelecionado: (infoPlano) => void,  
}

export default function SearchPlanos({ isOpen, onClose, infoPlanoSelecionado}: SearchPlanosProps) {
  const autocompleteRef = useRef(null);

  const [searchText, setSearchText] = useState('');  
  const [options, setOptions] = useState([]);  
  const [callPlanoLoad, setCallPlanoLoad] = useState(false);
  const [allPlanos, setAllPlanos] = useState<PlanosAtividades[]>([]);

  const handleSearch = async () => {      

    const duracaoMatch = searchText.match(/Duração: (\d+) Meses/);    
    
    const duracao = duracaoMatch ? duracaoMatch[1] : null;    

    const planoMatch = searchText.match(/Plano: (.+)/);
    const plano = planoMatch ? planoMatch[1] : null;    
    
    const parteFinal = plano.split('-');    
    
    const planoByName = await getByNomePlano(parteFinal[0].trim());       
    console.log('planoByName ', planoByName);
       
    const objInfoPlano = {
      idPlano: planoByName[0].id,
      duracao: planoByName[0].duracao,
      nomePlano: planoByName[0].descricao, 
      atividadeId: planoByName[0].listaAtividades[0].id,     
      valor:  planoByName[0].valor,
      frequenciaDias: planoByName[0].frequenciaDias
    }        

    console.log('valor PLANO ', planoByName[0].valor);
    

    infoPlanoSelecionado(objInfoPlano);          
  };


  const handleCallPlanos = async () => {
    try {
      const result = await getAllPlanos();
      const arrayDataFullPlano = [];
      const arrayInfoPlano = [];
  
      result.map((data) => {                  
        arrayDataFullPlano.push(data);
        if (data.duracao === 9999){
          arrayInfoPlano.push(`Duração: Ilimitado - Plano: ${data.descricao} -  Preço: ${data.valor}`);  
        } else {
          arrayInfoPlano.push(`Duração: ${data.duracao} Meses - Plano: ${data.descricao} -  Preço: ${data.valor}`);
        }
      });

      setAllPlanos(arrayDataFullPlano);
      setCallPlanoLoad(true);      
  
      return arrayInfoPlano;
    } catch (error) {
      console.error('Error in handleCallPlanos:', error);
      throw error;
    }
  };
  

  useEffect(() => {
    let isMounted = true;
  
    if (autocompleteRef.current) {
      autocompleteRef.current.querySelector('#autocom').click();
    }
  
    handleCallPlanos()
      .then((result: []) => {
        if (isMounted) {
          
          setOptions(result);          
        }
      })
      .catch(erro => {
                    
      });  
    
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Consulta Planos</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Filtre por duração ou nome do Plano
        </DialogContentText>
        
          <div style={{width: "500px", marginTop:"14px"}}>
          </div>
            {callPlanoLoad && (
              <Autocomplete
                id='autocom'                
                ref={autocompleteRef}
                value={searchText}
                onChange={(event, newValue) => {setSearchText(newValue ? newValue : '')}}            
                options={options ? options : []}
                renderInput={(params) => <TextField {...params} label="Plano" id="autocom" />}
              />
            )}
          
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSearch} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}