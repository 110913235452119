/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel } from '@material-ui/core';
import { useLocation, useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { CallCepInfo } from "services/Utils/callCepInfo";
import { Alert, Autocomplete, ButtonBase, Snackbar, Tooltip } from "@mui/material";
import { ReAuth } from "layouts/authentication/reAuth";
import CpfValidation from "../../../services/Utils/validationCpf";
import { verifyRolesAcess } from "../../../services/Utils/verifyRolesAcess" ;
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import planoService from '../../../services/PlanoService';
import atividadeService from "services/AtividadeService";
import { bool } from "yup";

function CadPlanos() {  
  const autocompleteRef = useRef(null);        

  const [diaFrequencia, setDiaFrequencia] = useState('2');
  const [duracao, setDuracao] = useState('1 Ano');
  const [duracaoName, setDuracaoName] = useState(12);  
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState([]);
  const [dataAtividades, setDataAtividades] = useState([]);  
  const [valor, setValor] = useState(0.00);              
  const [alertError, setAlertError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [alertEditSuccess, setAlertEditSuccess] = useState(false);      
  const [tokenExpired, setTokenExpired] = useState(false);          
  const [invalidPermission, setInvalidPermission] = useState(false);  
  const [descricao, setDescricao] = useState('')
  const [atividadePlano, setAtividadePlano] = useState([]);
  const [loadAtividade, setLoadAtividade] = useState(false);
  const [arrExpandLess, setArrExpandLess] = useState([]);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorContainAtividade, setErrorContainAtividade] = useState(false);    
  const [mouseOnDelete, setMouseOnDelete] = useState(false);
  const [indexOnDelete, setIndexOnDelete] = useState(-1);
  const [errorFrequencia, setErrorFrequencia] = useState(false);  
  const [errorValor, setErrorValor] = useState(false); 
  const [renderListaAtividadeTemp, setRenderListaAtividadeTemp] = useState(false);    

  const navigate = useNavigate();  
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const idPlano = queryParams.get('id')  

  const handleMouveEnterDelete = (index) => {    
    setMouseOnDelete(true)
    setIndexOnDelete(index)
  }
  
  const handleMouveLeaveDelete = () => {
    setMouseOnDelete(false)
  }

  function formatDDMMYYYY(data) {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
    const ano = data.getFullYear();  
    return `${dia}/${mes}/${ano}`;
  }

  const handlePrepareInputEditar = async (idPlano) => {
    try {
      const result = await planoService.getByID(idPlano);
  
      const promises = result.data.listaAtividades.map(async (resultListaAti) => {
        const resultData = await atividadeService.getByID(resultListaAti.id);
        setDescricao(result.data.descricao);
        setValor(result.data.valor);
        setDiaFrequencia(result.data.frequenciaDias);
        handleSetDuracaoName(result.data.duracao);
        setAtividadePlano((prevAtividades) => [...prevAtividades, resultData.data]);
        return resultData.data;
      });
  
      const resultArray = await Promise.all(promises);
      setLoadAtividade(true);
      setSearchText('');      
    } catch (error) {            
    }
  };   

  function handleClose (option){
    navigate(`/planos/`);    
  }

  useEffect(() => {
    setErrorDescricao(false);
  }, [descricao])

  useEffect(() => {    
    setDataAtividades(options.sort());
  }, [options])

  useEffect(() => {
    if (duracaoName === '3 Meses'){
      setDuracao(3);
    }else if (duracaoName === '6 Meses'){
      setDuracao(6);
    }else if (duracaoName === '1 Ano'){
      setDuracao(12);    
    }else if (duracaoName === '2 Anos'){
      setDuracao(24);          
    }else if (duracaoName === 'Ilimitado'){
      setDuracao(9999);       
    }
  }, [duracaoName]);

  useEffect(() => {
    setErrorValor(false);
  }, [valor])

  useEffect(async () => {       

    handleCallAtividades().then((result) => {       
      setOptions(result)      
    }).catch((erro) => {            
    })

    if (autocompleteRef.current) {
      //autocompleteRef.current.querySelector('#autocom').click();
    }

    var invalidPermissionInfo = await verifyRolesAcess('funcionarios');        
    setInvalidPermission(!invalidPermissionInfo);
    
    if (idPlano != null){               
      handlePrepareInputEditar(idPlano);   
    }  

    var isExpiredInfo = isExpired(localStorage.getItem('token'));    
    setTokenExpired(isExpiredInfo);
  }, [])

  const arrDiasFrequencia = [    
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },    
    { id: 4, name: '4' },  
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' }      
  ];

  const arrDuracaoMeses = [        
    { id: 0, name: '3 Meses'},    
    { id: 1, name: '6 Meses'},    
    { id: 2, name: '1 Ano' },    
    { id: 3, name: '2 Anos' },        
    { id: 5, name: 'Ilimitado'},    
    { id: 6, name: 'Outros'},        
  ];


  const handleCallAtividades = async () => {    
    return new Promise(async (resolve, reject) => {
      await atividadeService.getAll().then((result) => {                                                                     
        let arrHorariosDias = []     
        let infoinputFilter = [];   
        result.data.map((data) => {  
          infoinputFilter.push(`${data.descricao}`)                                                    
        })                   
        resolve(infoinputFilter);        
      })      
    }).catch((error) => {      
    })        
  }  
 
  const handleReAuthInfo = (value) => {
    var isExpiredInfo = isExpired(localStorage.getItem('token'))
    setTokenExpired(isExpiredInfo);         
  }    

  useEffect(async () => {      
    atividadePlano.length > 0 ? setRenderListaAtividadeTemp(true) : setRenderListaAtividadeTemp(false)
  }, [atividadePlano])

  const handleInsertAtividades = async (value) => {    
    var containAtividade = atividadePlano.some(x => x.descricao === value);
    
    if (containAtividade){
      setErrorContainAtividade(true);  
    }else if (descricao === ''){
      setErrorDescricao(true);  
    }else{
      setErrorDescricao(false);
      setLoadAtividade(false);      

      let nameAtividade = value;            
      
      try {
        const result = await atividadeService.getByName(nameAtividade.trim().replace(/"/g, ''));        
      
        setAtividadePlano((prevAtividades) => [...prevAtividades, result.data[0]]);
        setLoadAtividade(result.data[0].sucesso);
        setSearchText('');
        setAlertError(false);
        setErrorContainAtividade(false);
        setMsgError('');        
      } catch (error) {
        // Handle errors here
        console.error('Error:', error);
      }
    }
  }

  const handleExpandAtividades = (indexLess) => {
    setArrExpandLess([...arrExpandLess, indexLess]);
  }

  useEffect(async () => {               
  }, [arrExpandLess])

  const handleSetDuracaoName = (duracaoMonth) => {       
    if (duracaoMonth === 3){
      setDuracaoName('3 Meses');
    }else if (duracaoMonth === 6){
      setDuracaoName('6 Meses');
    }else if (duracaoMonth === 12){
      setDuracaoName('1 Ano');
    }else if (duracaoMonth === 24){
      setDuracaoName('2 Anos');
    }else if (duracaoMonth === 9999){
      setDuracaoName('Ilimitado');
    }            
  }

  const handleCallDeleteHorarioAtividade = async (obj) => {        
    let newObjeto = atividadePlano.filter(x=> x.id != obj.id);       
    setAtividadePlano(newObjeto);
  }

  async function validationInfos(){    
    let result = true
    if (descricao === ''){
      result = false
      setErrorDescricao(true)
    }else if (valor <= 0){
      result = false
      setErrorValor(true)      
    }  
    return result
  }  

  const handleSubmit = async () => {    

    validationInfos().then(async (result) => {
      var atividadePlanoNew = atividadePlano;   
      
      var duracaoTemp = '';
      if (duracao === 3){
        duracaoTemp = '3 Meses';
      }else if (duracao === 6){
        duracaoTemp = '6 Meses';
      }else if (duracao === 12){
        duracaoTemp = '1 Ano';        
      }else if (duracao === 24){
        duracaoTemp = '2 Anos'        
      }else if (duracao === 9999){
        duracaoTemp = 'Ilimitado';
      }            

      //var descricaoTemp = `${descricao} freq-${diaFrequencia} duracao-${duracaoTemp} valor-${valor.toString().replace(',', '.')}`
      var descricaoTemp = `${descricao} | freq-${diaFrequencia} | dc-${duracaoTemp}}`
  
      const convertedObject = [];
      atividadePlanoNew.forEach((innerArray, index) => {
        convertedObject.push(innerArray[0]);
      });

      if (idPlano != null){  
        let valorVirgula = valor;
        let valorReplace = 0;                
       

        if (valorVirgula.toString().includes(',')) {
          valorReplace = valorVirgula.toString().replace(',', '.');
        } else {
          valorReplace = valorVirgula;
        }                               

        let objPlano = {
          Id: idPlano,
          Descricao: descricao,
          FrequenciaDias: diaFrequencia,
          Duracao: duracao,
          Valor: valorReplace,
          ListaAtividades : atividadePlano,
          ativo: true
        }                  
        
        planoService.putPlano(objPlano, idPlano)
        .then((result) => {        
          setAlertSuccess(true)
          setMsgSuccess(result.data.mensagemRetorno)
        })
        .catch((error) => {   
          console.log('error ', error);
          setAlertError(true)
          setMsgError(error.response.data.mensagemRetorno);                    
        })

      }else{
        let valorVirgula = valor;
        let valorReplace = valorVirgula.toString().replace(',', '.');

        let objPlano = {
          Descricao: descricaoTemp,
          FrequenciaDias: diaFrequencia,
          Duracao: duracao,
          Valor: valorReplace,
          ListaAtividades : atividadePlano
        }                   

        planoService.postPlano(objPlano)
          .then((result) => {        
            setAlertSuccess(true)
            setMsgSuccess(result.data.mensagemRetorno)
          })
          .catch((error) => {   
            console.log('error ', error);
            setAlertError(true)                
            setMsgError(error.response.data.mensagemRetorno);    
          })
            
        
      }          
    })

    
  }

  const handleCloseSnak = () => {
    setTimeout(() => {
      setDescricao('')
      setAlertError(false)
      setAlertSuccess(false)
      setMsgError('')   
      setMsgSuccess('')
      navigate(`/planos`)
    }, 1200);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />   
      
      {alertSuccess && (
        <>
          <Snackbar open={{}} autoHideDuration={1500} onClose={()=> {handleCloseSnak()}}>
            <Alert onClose={()=> {handleCloseSnak()}} severity="success" sx={{ width: '100%' }}>
              {msgSuccess}
            </Alert>
          </Snackbar>
        </>
      )}    

      {tokenExpired ? (
        <>                
          <ReAuth handleAuth={handleReAuthInfo} />        
        </>
      ):(                        
        <>
          {invalidPermission ? (
            <>
              <Alert severity="error">
                Você não tem permissão para acessar esse recurso.
              </Alert>
            </>
          ):(
            <>
             
                <>
                  {alertEditSuccess && (
                    <Snackbar open={{}} autoHideDuration={1500} onClose={()=>{handleClose()}}>
                      <Alert onClose={()=>{handleClose()}} severity="success" sx={{ width: '100%' }}>
                        Atualização realizada com sucesso !
                      </Alert>
                    </Snackbar>
                  )}
                </>
                   
              <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white" flexDirection={"column"}>
                          Planos                                                                        
                        </MDTypography>                
                      </MDBox>  

                      <form>
                        <Grid container spacing={2} p={4}> 
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              label="Descricao"
                              name="Descricao"
                              value={descricao}
                              onChange={(event) => {setDescricao(event.target.value)}}
                              fullWidth                      
                              required
                            />
                          </Grid>  
                          {errorDescricao && (
                            <>
                            <Grid item xl={12} sm={12}>
                              <Alert severity="error">
                                Informe a descrição do plano.
                              </Alert>                  
                            </Grid>
                            </>
                          )}      
                          <Grid item xl={4} sm={4}>
                            <MDBox
                              variant="gradient"
                              bgColor="info"
                              borderRadius="lg"
                              coloredShadow="info"
                            >
                              <MDTypography variant="h6" color="white" flexDirection={"column"} style={{marginLeft: 8}} p={1}>
                                Escolha as atividades do planos                                                                       
                              </MDTypography>     
                              {errorContainAtividade && (
                              <>
                                <Grid item xl={12} sm={12}>
                                  <Alert severity="error">
                                    Atividade já cadastrada.
                                  </Alert>                  
                                </Grid>
                              </>
                          )}                 
                            </MDBox>                                                            
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <Autocomplete
                              key={(option) => option.index}
                              ref={autocompleteRef}
                              value={searchText}
                              onChange={(event, newValue) => {setSearchText(newValue ? newValue : '')}}            
                              options={((dataAtividades != "") && (dataAtividades.length > 0)) ? dataAtividades : []}
                              renderInput={(params) => <TextField {...params} label="Atividades disponíveis" id="autocom"/>}                                                                                          
                            />
                          </Grid> 
                          <Grid item xs={6} sm={2}>
                            <ButtonBase 
                              onClick={() => {handleInsertAtividades(searchText)}}
                            >
                              <DownloadDoneIcon fontSize="large" style={{color: 'green', marginTop: 16}} />
                            </ButtonBase>
                          </Grid> 

                          {loadAtividade && (                            
                            <Grid item xs={12} sm={12}>                                
                              {renderListaAtividadeTemp && (
                                <>
                                  {atividadePlano?.map((data, indexBase) => {                                
                                    return (   
                                      <>                                        
                                      
                                        <MDBox style={{display: 'flex', flexDirection: 'row'}}>
                                          <ButtonBase
                                            onClick={() => {handleExpandAtividades(indexBase)}}
                                          >
                                            <ExpandLessIcon style={{marginTop: 32}}/>
                                          </ButtonBase>
                                          <MDTypography style={{marginLeft: 0, marginRigth: 16, marginTop: 32}}>
                                            {data.descricao}
                                          </MDTypography>

                                          <MDBox style={{alignItens: 'center', display: 'flex', marginLeft: 24, cursor: 'pointer', marginTop: 36}}
                                            onClick={() => {handleCallDeleteHorarioAtividade(data)}}
                                          >
                                            <DeleteIcon 
                                              onMouseEnter={(event) => {handleMouveEnterDelete(indexBase)}}
                                              onMouseLeave={handleMouveLeaveDelete}
                                              style={{color: mouseOnDelete && indexBase === indexOnDelete ? 'red' : 'gray' }} />                                                                                                                                                                                                
                                          </MDBox> 
                                        </MDBox>
                                        {data?.horarios?.map((data, index) => {
                                          return (
                                            <>
                                              {arrExpandLess.filter(x => x != indexBase) && (

                                                <Card style={{marginTop: 8}}>                                                                                   
                                                  <Grid item xl={12} xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
                                                    <Grid item xs={12} sm={6} md={2} lg={2} xl={4} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                      <MDTypography style={{marginLeft: 16, marginRigth: 16, fontSize: 16}}>{data.horarioDe} - {data.horarioAte}</MDTypography>                      
                                                    </Grid> 

                                                    {data.domingo && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Domingo</MDTypography>
                                                        
                                                      </Grid>
                                                    )}                                                      

                                                    {data.segundaFeira && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Segunda</MDTypography>
                                                        
                                                      </Grid>
                                                    )}

                                                    {data.tercaFeira && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Terça</MDTypography>
                                                        
                                                      </Grid>
                                                    )}
                                                    
                                                    {data.quartaFeira && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Quarta</MDTypography>
                                                        
                                                      </Grid>
                                                    )}

                                                    {data.quintaFeira && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Quinta</MDTypography>
                                                        
                                                      </Grid>
                                                    )}

                                                    
                                                    {data.sextaFeira && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Sexta</MDTypography>
                                                        
                                                      </Grid>
                                                    )}

                                                    {data.sabado && (
                                                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                                                        <MDTypography style={{marginLeft: 16, fontSize: 16}}>Sábado</MDTypography>
                                                        
                                                      </Grid>
                                                    )}                                                                                                                     
                                                  </Grid> 
                                                </Card>                     
                                              )}
                                            </>
                                          )
                                        })}                                                                                
                                      
                                      </>
                                    )
                                  })}  
                                </>
                              )}                            
                            </Grid> 
                          )}

                                                                            
                          <Grid item xs={12} sm={12}>
                            <FormControl fullWidth style={{marginTop: 26}}>
                              <InputLabel id="city-label">Frequência dias</InputLabel>
                              <Select
                                labelId="city-label"
                                id="diaFrequencia"
                                name="diaFrequencia"
                                value={diaFrequencia}
                                onChange={(event) => {setDiaFrequencia(event.target.value)}}                                  
                              >
                                {arrDiasFrequencia.map((dp) => (
                                  <MenuItem key={dp.id} value={dp.name}>
                                    {dp.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          {errorDescricao && (
                            <>
                              <Alert severity="error">
                                Informe a descrição da atividade
                              </Alert>                  
                            </>
                          )}                                                  
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel id="city-label">Duração</InputLabel>
                              <Select
                                labelId="city-label"
                                id="duracaoName"
                                name="duracaoName"
                                value={duracaoName}
                                onChange={(event) => {setDuracaoName(event.target.value)}}                                  
                              >
                                {arrDuracaoMeses.map((dp) => (
                                  <MenuItem key={dp.id} value={dp.name}>
                                    {dp.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>                                             
                          <Grid item xs={12} sm={6}>                                                          
                            <TextField
                              label="Valor (R$)"
                              name="numero"
                              value={valor}
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                const numericValue = inputValue.replace(/[^0-9,]/g, ''); // Permite apenas números e ponto
                            
                                setValor(numericValue);
                              }}
                              required
                              fullWidth   
                              type="text"                           
                              inputProps={{
                                pattern: "[0-9]*",
                              }}
                            />  
                            {errorValor && (
                            <>
                            <Grid item xl={12} sm={12}>
                              <Alert severity="error">
                                Informe o valor do plano.
                              </Alert>                  
                            </Grid>
                            </>
                          )}                           
                          </Grid>

                          <Grid item xl={12} pt={4} pb={4}>                      
                            <Button variant="contained" color="primary" fullWidth
                              onClick={() => {handleSubmit()}}
                            >
                              Salvar
                            </Button> 
                          </Grid> 
                          {alertError && (
                          <>                            
                            <Alert onClose={()=> {handleCloseSnak()}} severity="error" sx={{ width: '100%' }}
                              action={
                                <>                                                        
                                    <Button color="inherit" size="small"
                                      onClick={() => {setAlertError(false), setMsgError('')}}
                                      style={{marginRight: 60}}
                                    >
                                      OK
                                    </Button>
                                </>
                                } 
                            >
                              {msgError}
                            </Alert>                            
                          </>
                        )}
                        </Grid>   

              
                      </form>            
                    </Card>           

                  </Grid>

                  

                </Grid>
              </MDBox>      
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default CadPlanos;
