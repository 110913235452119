import { Alert, Box, Button, Snackbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContainerMain } from './InativarModal.Styled';
import MDTypography from '../../shared/MDTypography'
import { inativar } from '../../../layouts/contratacao/ContratacaoService';

interface InativarModalProps {
  returnInativar: (value: boolean) => void;
  screen: string; 
  idContratacao: any; 
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFF',
  border: '4px solid #8E3295',
  boxShadow: 24,
  p: 4,
};



export default function InativarModal({returnInativar, screen, idContratacao}: InativarModalProps) {  

  const handleClose = () => {
    returnInativar(false);          
  }
    
  const handleConfirmInativar = async () => {              
    if (screen === 'Contratação'){      
      await inativar(idContratacao)
        .then((result) => {
          returnInativar(true);
        })
        .catch((error) => {
          returnInativar(false);          
        })  
    }      
  }

  return (
    <Box sx={style}>
      <MDTypography id="modal-modal-title" variant="h6" component="h2">
        Deseja inativar {screen} ?
      </MDTypography>
      <Button id="modal-modal-description" sx={{ mt: 2 }}
        onClick={() => {handleConfirmInativar()}}
      >
        Sim
      </Button>
      <Button id="modal-modal-description" sx={{ mt: 2 }}
        onClick={() => {handleClose()}}
      >
        Não
      </Button>
    </Box>
    
  )
}
