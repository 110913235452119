import * as React from 'react';
import DashboardLayout from '../../../shared/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../shared/Navbars/DashboardNavbar';
import { getAlunosByTurmaId, getQtdAlunoTurma, getObterTodosAlunosPorTurma } from '../../DetalheTurmaService';
import  BoxDetalheTurma from '../../componentes/BoxDetalheTurma'
import { IResultDTO, MovimentacaoTurmaViewModel } from '../../DetalheType';
import { ContainerGoCadastro, DivButton } from './DetalheTurma.styled';
import { Button } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Link, useNavigate   } from 'react-router-dom';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import  MDTypography  from '../../../shared/MDTypography';
import SearchTurmas from '../../../../layouts/shared/SearchTurmas';

export default function DetalheTurma() {  
  const queryParams = new URLSearchParams(location.search);
  const idTurma = queryParams.get('id');

  const [listAlunosTurma, setAlunosTurma] = React.useState<IResultDTO>(null);
  const [qtdAlunoTurma, setQtdAlunoTurma] = React.useState(0);
  const [openPesquisaTurmas, setOpenPesquisaTurmas] = React.useState(false);
  const [alunosSelecionados, setAlunosSelecionados] = React.useState<MovimentacaoTurmaViewModel>(null);
  const [habilitaOpcoesAlunos, setHabilitaOpcoesAlunos] = React.useState(false);

  const alignEndItem = {   
    width: 'auto',
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: window.window.innerWidth < 768 ? 38 : 48,
    marginBottom: 12,
    marginTop: window.window.innerWidth < 768 ? -70 : 0
  }; 


  const handleCallTurmaById = async (id: string) => {
    await getAlunosByTurmaId(id.replace(/"/g, ''))
      .then((dataResult) => {                
        setAlunosTurma(dataResult);
      })
    
   await getQtdAlunoTurma(id.replace(/"/g, ''))   
      .then((dataQtd) => {        
        setQtdAlunoTurma(dataQtd.data[0].horarios[0].qtdAlunos);               
      })
  }

  const handleCallTodasTurmas = async () => {
    await getObterTodosAlunosPorTurma()
      .then((dataAllTurma) => {
        setAlunosTurma(dataAllTurma);
      })    
  }

  React.useEffect(() => {
    (idTurma !== null) 
    ?handleCallTurmaById(idTurma)
    :handleCallTodasTurmas(); 
  }, [])  

  React.useEffect(() => {
    if (alunosSelecionados !== null && alunosSelecionados.AlunosMovimentacao != undefined)      
      alunosSelecionados.AlunosMovimentacao.length > 0 ? setHabilitaOpcoesAlunos(true) : setHabilitaOpcoesAlunos(false);    
  }, [alunosSelecionados])  
  
  
  const callBackInfoTurmaSelecionado = async (infoTurmaSelecionada) => {    
    handleModalCloseTurmas(); 
  }
  
  const handleModalCloseTurmas = () => {        
      setOpenPesquisaTurmas(false);
      window.location.reload();    
  };

  const handleSelecaoAluno = (infoAlunosSelecionados) => {        
    setAlunosSelecionados({
      ...infoAlunosSelecionados,
      IdTurmaAtual: idTurma.replace(/"/g, ''),      
    }); 
  }
  
  return (    
    <DashboardLayout>      
      <DashboardNavbar />      
      <BoxDetalheTurma          
        listaTurma={listAlunosTurma}    
        qtdAlunos={qtdAlunoTurma}      
        byId={idTurma !== null ? true : false}
        selecaoAluno={handleSelecaoAluno}
      />   

      <ContainerGoCadastro>      
        <SearchTurmas 
          isOpen={openPesquisaTurmas}
          onCloseScreen={handleModalCloseTurmas}
          arrAlunos={alunosSelecionados}
          infoTurmaSelecionado={callBackInfoTurmaSelecionado}                                                       
        />   

        
        {habilitaOpcoesAlunos && (
          <>
            <Button style={{ width: 50, height: 50, backgroundColor: '#FFF', borderRadius: 50, marginLeft: 16 }}
              onClick={() => {setOpenPesquisaTurmas(!openPesquisaTurmas)}}
            >
              <CompareArrowsIcon fontSize={'large'} style={{width: 40, height: 40}}/>            
            </Button>
            <MDTypography mt={1} style={{fontSize: 16, alignSelf: 'center', marginLeft: 16}}>
              Movimentar para outra turma.
            </MDTypography>
          </>
        )}  
        
      </ContainerGoCadastro>                                 

    </DashboardLayout>    
  );
}