import { isExpired, useJwt } from "react-jwt"
import baseService from "../services/BaseService"
import authService from "./authService";

const clienteService = {
    getAll: async () => {            
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  
        
      const response = await baseService. get(`/Cliente/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);                                
      return response;
    },
    getByID: async (id) => {          
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Cliente/ObterPorId?id=${id}`, customConfig);                    
        return response;
    },
    getAllWithTitu: async () => {          
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Cliente/ObterTodosComTitular`, customConfig);                    
        return response;         
    },

    getAllClienteDependentes: async (id) => {       
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Cliente/ObterTodosDependentes`, customConfig);                    
        return response;         
    },
    getByCpf: async (cpf) => {     

        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Cliente/ObterPorCPF?cpf=${cpf}`, customConfig);                    
        return response;       
    },
    getByName: async (name) => {              
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Cliente/ObterPorNome?nome=${name}`, customConfig);                    
        return response;       
    },
    putCliente: async (obj, id) => {         
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };          

        const response = await baseService.put(`/Cliente/Atualizar/${id}`, obj, customConfig);                    
        return response;             
    },
    postCliente: async (obj) => {            
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };  
      
      const response = await baseService.post(`/Cliente/Cadastrar`, obj, customConfig);                    
      return response;                 
        
        
    },
    postInativeCliente: async (id) => {                  
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };          

        const response = await baseService.put(`/Cliente/Inativar/${id}`, customConfig);                        
        return response;         
             
    },
    postAnamnese: async (obj) => {                        
      const response = await baseService.post(`/AnamneseProntidaoAtividadeFisica/Cadastrar`, obj);                        
      return response;         
           
    },
    getAnamneseClientById: async (id) => {
      const customConfig = {            
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        },
      }; 

      const response = await baseService.get(`/AnamneseProntidaoAtividadeFisica/ObterPorClienteId?clienteId=${id}`, customConfig);                        
      return response;    
    },
    getAnamneseById: async (id) => {      
      const customConfig = {            
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        },
      }; 

      const response = await baseService.get(`/AnamneseProntidaoAtividadeFisica/ObterPorId?id=${id}`, customConfig);                        
      return response;    
    },

    postLogAnamnese: async (obj) => {      
      const response = await baseService.post(`/Log/DebugLog`, obj);
      return response;
    }

  };
  
  export default clienteService;