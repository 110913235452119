/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard';
import Tables from 'layouts/tables';
import Billing from 'layouts/billing';
import RTL from 'layouts/rtl';
import Notifications from 'layouts/notifications';
import Profile from 'layouts/profile';
import SignIn from 'layouts/authentication/sign-in';
import SignUp from 'layouts/authentication/sign-up';
import Clientes from 'layouts/clientes';
import AtividadesNew from 'layouts/atividadesNew';
import Funcionarios from 'layouts/funcionarios';
import Anamnese from 'layouts/anamnese';
import AnamneseList from '../src/layouts/clientes/cadClientes/anamnese'; //layouts/anamnese

// @mui icons
import Icon from '@mui/material/Icon';
import CadClientes from 'layouts/clientes/cadClientes';
import CadFuncionarios from 'layouts/funcionarios/cadFuncionarios';
import CadAtividadesNew from 'layouts/atividadesNew/cadAtividadesNew';
import { React } from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ClassIcon from '@mui/icons-material/Class';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ListaAnamnese from 'layouts/clientes/cadClientes/ListaAnamnese';
import Planos from 'layouts/planos';
import CadPlanos from 'layouts/planos/cadPlanos';
import PoolIcon from '@mui/icons-material/Pool';
import BusinessIcon from '@mui/icons-material/Business';
import Contratacoes from '../src/layouts/contratacao/screens/ListScreen/index.ts';
import CadContratacoes from '../src/layouts/cadContratacao/screens/ListScreen';
import Turmas from './layouts/turma/screens/Listscreen';
import CadTurma from './layouts/cadTurma/screens/ListScreen';
import DetalheTurma from '../src/layouts/detalheTurma/screens/ListScreen'

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Clientes',
    key: 'clientes',
    icon: <GroupsIcon />,
    route: '/clientes',
    component: <Clientes />,
  },
  {
    type: 'collapse',
    name: 'Cadastro Cliente',
    key: 'cadClientes',
    icon: <PersonAddIcon />,
    route: '/clientes/cadClientes',
    component: <CadClientes />,
    hidden: true
  },
  {
    type: 'collapse',
    name: 'Funcionários',
    key: 'Funcionarios',
    icon: <PeopleSharpIcon />,
    route: '/funcionarios',
    component: <Funcionarios />,
  },  
  {
    type: 'collapse',
    name: 'Cadastro Funcionario',
    key: 'cadFuncionarios',
    icon: <PersonAddAlt1Icon />,
    route: '/funcionarios/cadFuncionarios',
    component: <CadFuncionarios />,
    hidden: true
  },    
  {
    type: 'collapse',
    name: 'Sign Out',
    key: 'sign-Out',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/authentication/sign-in',
    component: <SignIn />,
    hidden: true
  },
  {
    type: 'collapse',
    name: 'Anamenese',
    key: 'anamenese',
    icon: <Icon fontSize="small">Anamenese</Icon>,
    route: '/anamnese',
    component: <Anamnese />,
    hidden: true
  },
  {
    type: 'collapse',
    name: 'Anamenese',
    key: 'anamenese',
    icon: <Icon fontSize="small">Anamenese</Icon>,
    route: '/clientes/cadClientes/anamnese',
    component: <AnamneseList />,
    hidden: true
  },
  {
    type: 'collapse',
    name: 'ListaAnamnese',
    key: 'listaAnamnese',
    icon: <Icon fontSize="small">ListaAnamnese</Icon>,
    route: '/clientes/cadClientes/ListaAnamnese',
    component: <ListaAnamnese />,
    hidden: true
  },
  {
    type: 'collapse',
    name: 'Atividades',
    key: 'atividades',
    icon: <PoolIcon fontSize="small">Atividades</PoolIcon>,
    route: '/atividadesNew',
    component: <AtividadesNew />,        
  },
  {
    type: 'collapse',
    name: 'Cadastro Atividades',
    key: 'cadAtividades',
    icon: <PersonAddAlt1Icon />,
    route: '/atividadesNew/cadAtividadesNew',
    component: <CadAtividadesNew />,  
    hidden: true  
  },    
  {
    type: 'collapse',
    name: 'Planos',
    key: 'planos',
    icon: <BusinessIcon />,
    route: '/planos',
    component: <Planos />,        
  },
  {
    type: 'collapse',
    name: 'Cadastro Planos',
    key: 'cadPlanos',
    icon: <PersonAddAlt1Icon />,
    route: '/planos/cadPlanos',
    component: <CadPlanos />, 
    hidden: true   
  },  
  {
    type: 'collapse',
    name: 'Contratações',
    key: 'Contratacoes',
    icon: <ClassIcon />,
    route: '/contratacoes',
    component: <Contratacoes />,        
  },
  {
    type: 'collapse',
    name: 'Cadastro Contratações',
    key: 'Cadastro Contratacoes',
    icon: <ClassIcon />,
    route: '/contratacoes/cadContratacao',
    component: <CadContratacoes />,        
    hidden: true   
  },
  {
    type: 'collapse',
    name: 'Turmas',
    key: 'Turmas',
    icon: <WorkspacesIcon />,
    route: '/turmas',
    component: <Turmas />,        
  },
  {
    type: 'collapse',
    name: 'Cadastro Turmas',
    key: 'Cadastro Turmas',
    icon: <ClassIcon />,
    route: '/Cadturmas',
    component: <CadTurma />,        
    hidden: true
  },
  {
    type: 'collapse',
    name: 'Detalhes Turmas',
    key: 'Detalhes Turmas',
    icon: <ClassIcon />,
    route: '/DetalhesTurma',
    component: <DetalheTurma />,        
    hidden: false
  },
  
];

export default routes;
