import axios from 'axios';
import Config from '../../Config/config.js';

const BaseServicesAxios = axios.create({
  baseURL: Config.params.apiBase,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }  
});


export default BaseServicesAxios;