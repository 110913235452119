import * as React from 'react';
import DashboardLayout from '../../../shared/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../shared/Navbars/DashboardNavbar';
import ContratacaoForm from './CadContratacaoForm';
import TitleScreenCrud from '../../../../layouts/shared/TitleScreenCrud';
import CardResumoContratacao from './CardResumoContratacao'
import { IResultDTOContratacao } from '../../../../layouts/cadContratacao/CadContratacaoType';
import AvisoSnack from '../../../../layouts/shared/AvisoSnack';

function ListScreen() {    
  const[dataInfoCadContratacao, setDataInfoCadContratacao] = React.useState<IResultDTOContratacao>(null); 

  const callBackContratacaoForm = (info: IResultDTOContratacao) => {
   console.log('info return contratacao ', info);    
   setDataInfoCadContratacao(info);
  } 

  React.useEffect(() => {
    console.log('dataInfoCadContratacao ', dataInfoCadContratacao);        
  }, [dataInfoCadContratacao])
  return (    
    <DashboardLayout>
      <DashboardNavbar /> 
      <TitleScreenCrud title='Cadastro de Contratação'>     
        <ContratacaoForm
          onRetornoSalvar={callBackContratacaoForm} 
        />                 
      </TitleScreenCrud>
      {dataInfoCadContratacao != null && <AvisoSnack goBackScreen='contratacoes' sucesso={dataInfoCadContratacao[0].sucesso} mensagem={dataInfoCadContratacao[0].mensagem} />}
    </DashboardLayout>    
  );
}

export default ListScreen;

//onReturnContratacao={callBackContratacaoForm}