import { isExpired, useJwt } from "react-jwt"
import baseService from "../services/BaseService"
import authService from "./authService";

const PlanoService = {
    getAll: async () => {            
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };  
      
      const response = await baseService. get(`/Plano/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);                                
      return response;
    },
    getByID: async (id) => {          
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };  
      
      var newId = decodeURIComponent(id);      
      const response = await baseService.get(`/Plano/ObterPorId?id=${newId}`, customConfig);                    
      return response;
    },
    getByName: async (name) => {              
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };  

      const response = await baseService.get(`/Plano/ObterPorDescricao?descricao=${name}`, customConfig);                    
      return response;       
    },
    putPlano: async (obj, id) => {         
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };          

      const response = await baseService.put(`/Plano/Atualizar/${id}`, obj, customConfig);                    
      return response;             
    },
    postPlano: async (obj) => {            
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };  
      
      const response = await baseService.post(`/Plano/Cadastrar`, obj, customConfig);                    
      return response;                 
        
        
    },
    putInativePlano: async (id) => {                  
      const customConfig = {            
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          },
      };          

      const response = await baseService.put(`/Plano/Inativar/${id}`, customConfig);                        
      return response;                      
    },
  };
  
  export default PlanoService;