import { Alert, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import TitleScreenCrud from '../../../shared/TitleScreenCrud';
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { IAlunoTurma, IContratacaoPostType, IHorariosAtividadeGetType, 
  IHorariosTurmaGetType, 
  IRelacaoPlanoClientePostType, 
  IResultDTO, IResumoContratacao, 
  ITurmaGetType, 
  Planos,
  ITotalizador, 
  IResultDTOContratacao} from '../../../../layouts/cadContratacao/CadContratacaoType';
import { MDSearch } from '../../../shared/MDSearch';
import { ContainerHorariosDias, TextDescDias, TextTitleHorarios, TextTitleDias, TextDesc, Line, TextDescHorario } from './CadContratacao.styled';
import SearchPlanos from '../../../../layouts/shared/SearchPlanos';
import SearchAlunos from '../../../../layouts/shared/SearchAlunos';
import MDBox from '../../../shared/MDBox'
import MDTypography from '../../../shared/MDTypography';
import { Button } from '@material-ui/core';
import CardResumoContratacao from './CardResumoContratacao';
import { getTurmasByAtividadesId, getById } from '../../../../layouts/cadContratacao/CadContratacaoService';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GridAtividadesSelecao from '../../../../layouts/shared/GridAtividadesSelecao';
import SomarMesesDataAtual from '../../../../infrastructure/SomarMesesDataAtual';
import { addMonths, format } from 'date-fns';
import clienteService from '../../../../services/ClienteService';

const handleSubmitContratacao = async (event) => {
  event.preventDefault(); 
  //console.log('objPost ', objPost);  
};

interface ContratacaoFormProps {  
  onRetornoSalvar: (result : IResultDTOContratacao) => void;
}

export default function ContratacaoForm({onRetornoSalvar}: ContratacaoFormProps) {   
  const [titularId, setTitularId] = React.useState('');
  const [titularName, setTitularName] = React.useState('');
  const [duracao, setDuracao] = React.useState(0);
  const [totalBruto, setTotalBruto] = React.useState(0.00);
  const [totalDesconto, setTotalDesconto] = React.useState(0.00);
  const [totalLiquido, setTotalLiquido] = React.useState(0.00);  
  const [dataPrimeiroVencimento, setDataPrimeiroVencimento] = React.useState(null);
  const [dataInicioContratacao, setDataInicioContratacao] = React.useState('');
  const [dataFimContratacao, setDataFimContratacao] = React.useState('');
  const [dataCancelamento, setDataCancelamento] = React.useState(null);
  const [cancelada, setCancelada] = React.useState(false);  
  const [telefoneCelularCliente, setTelefoneCelularCliente] = React.useState('');
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isModalOpenPlano, setModalOpenPlano] = React.useState(false);
  const [isModalOpenAluno, setModalOpenAluno] = React.useState(false);
  const [planoId, setPlanoid] = React.useState('');
  const [planoNome, setPlanoNome] = React.useState('');
  const [duracaoPlano, setDuracaoPlano] = React.useState(0);
  const [motivoCancelamento, setMotivoCancelamento] = React.useState('');
  const [mensagemRetorno, setMensagemRetorno] = React.useState('');    
  
  const [alunoId, setAlunoId] = React.useState('');
  const [alunoName, setAlunoName] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [showResumoContratacao, setShowResumoContracao] = React.useState(false);
  const [valor, setValor] = React.useState(0);  
  const [valorDesconto, setValorDesconto] = React.useState('');

  const [objTurmas, setObjTurmas] = React.useState<ITurmaGetType[]>(null);
  const [obj, setObj] = React.useState<IContratacaoPostType>(null);

  const [resultDTO, setResultDTO] = React.useState<IResultDTO>(null);

  const [expanded, setExpanded] = React.useState(null);
  const [idAtividade, setIdAtividade] = React.useState('');
  const [resultGridAtividadeData, setResultGridAtividadeData] = React.useState<IHorariosTurmaGetType[]>();
  const [alunoTurma, setAlunoTurma] = React.useState([]);
  const [resumoContratacao, setResumoContratacao] = React.useState<IResumoContratacao[]>()
  const [objResumoContratacao, setObjResumoContratacao] = React.useState([]);
  const [frequenciaDias, setFrequenciaDias] = React.useState(0);
  const [totalizadoresResumo, setTotalizadoresResumo] = React.useState<ITotalizador>();  
  const [resumoReturnContratacao, setReturnContratacao] = React.useState<IResultDTOContratacao>();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');  

  const cleanParaProximoAdicionar = () => {
    setPlanoid('');
    setPlanoNome('');
    setAlunoId('');
    setAlunoName('');
    setAlunoTurma([]);
    setValorDesconto('');
    setValor(0);
    setFrequenciaDias(0);
  };

  const adicionarRelacaoPlanoCliente = (objContratacaoPostType : IContratacaoPostType) => {
    console.log('objeto contratacao add ', objContratacaoPostType);
    
    setObj({
      ...objContratacaoPostType,
      relacaoPlanoCliente: [   
        { clienteId: titularId, planoId: planoId },
      ],
    });

    cleanParaProximoAdicionar();
  };  

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : null);
  };

  const callBackInfoPlanoSelecionado = (infoPlano) => {    
    setModalOpenPlano(false);     
    
    setIdAtividade(infoPlano.atividadeId);  
    
    console.log('infoPlano.atividadeId ', infoPlano.atividadeId);
    
    
    getTurmasByAtividadesId(infoPlano.atividadeId.replace(/"/g, ''))
      .then(async (result) => {  
        console.log('result turmas atividades ', result);
                    
        setObjTurmas(result.data);
        setPlanoid(infoPlano.idPlano);
        setPlanoNome(infoPlano.nomePlano);        
        setDuracao(infoPlano.duracao);                

        await clienteService.getByID(titularId).then((dataResult) => {
          console.log('dataResult ', dataResult);
          
          const dataAtual = addMonths(new Date(), 0);    
          const anoDataAtual = dataAtual.getFullYear();
          const mesDataAtual = String(dataAtual.getMonth() + 1).padStart(2, '0');
          const diaDataAtual = String(dataAtual.getDate()).padStart(2, '0');  
  
          const dataPrimeiraParcela = addMonths(new Date(), 1);  
          const anoPrimeiraParc = dataPrimeiraParcela.getFullYear();
          const mesPrimeiraParc = String(dataPrimeiraParcela.getMonth()).padStart(2, '0');        
          const diaPrimeiraParc = String(dataPrimeiraParcela.getDate()).padStart(2, '0');                                                
          
          setDataPrimeiroVencimento(`${anoDataAtual}-${mesDataAtual}-${dataResult.data.melhorDiaPagamento}`);
          setDataInicioContratacao(`${anoPrimeiraParc}-${mesPrimeiraParc}-${dataResult.data.melhorDiaPagamento}`);
          const dataFimContratacao = SomarMesesDataAtual(infoPlano.duracao);
          setDataFimContratacao(dataFimContratacao);        
          
          setValor(infoPlano.valor);
          setFrequenciaDias(infoPlano.frequenciaDias);                

        })
    })
  }

  const handleModalClosePlano = () => {
    setModalOpenPlano(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };  

  const handleIdTitular = (infoIdTitular) => {             
    setTitularId(infoIdTitular);    
  };  

  const handleNameTitular = (infoNameTitular) => {       
    setTitularName(infoNameTitular);
  }
  
  const handleDeleteTitular = () => {    
    setTitularId('');
    setTitularName('');
  }

  const handleDeletePlano = () => {
    setPlanoid('');
    setPlanoNome('');
  }

  const handleModalCloseAluno = () => {
    setModalOpenAluno(false);
  };

  const handleIdAluno = (infoIdTitular) => {             
    setAlunoId(infoIdTitular);    
  };  

  const handleNameAluno = (infoNameTitular) => {       
    setAlunoName(infoNameTitular);
  }

  const handleDeleteAluno = () => {    
    setAlunoId('')
    setAlunoName('')
  }

  const handleAdicionarContratacao = () => {   
    /*const totalValues = objResumoContratacao.reduce((accumulator, current) => {
      accumulator.valor += current.valor;
      accumulator.totalDesconto += current.totalDesconto;
      return accumulator;
    }, { valor: 0, totalDesconto: 0 });*/          
    const valorDescontoTemp = parseFloat(valorDesconto.replace(',', '.'));
    console.log('isNan valorDesconto ', !Number.isNaN(valorDesconto) );
    

    console.log('isNan valorDescontoTemp ', !Number.isNaN(valorDescontoTemp));

    const objContracaoPostType: IContratacaoPostType = {     
      titularId: titularId,
      nomeContratante: titularName,
      planoId: planoId,
      nomePlano: planoNome,
      duracao: duracao,
      relacaoPlanoCliente: [],
      totalBruto: Number(valor.toFixed(2)), 
      totalDesconto: !isNaN(valorDescontoTemp) ? Number(valorDescontoTemp.toFixed(2)) : 0,
      totalLiquido: !isNaN(valorDescontoTemp) ? Number((valor - valorDescontoTemp).toFixed(2)) : Number(valor.toFixed(2)), 
      dataPrimeiroVencimento: dataPrimeiroVencimento,
      dataInicioContratacao: dataInicioContratacao,
      dataFimContratacao: dataFimContratacao, 
      alunoInfo: alunoTurma, 
      valor: valor - (!isNaN(valorDescontoTemp) ? valorDescontoTemp : 0), 
      desconto: !isNaN(valorDescontoTemp) ? valorDescontoTemp : 0, 
      frequenciaDias: frequenciaDias      
    };            

    adicionarRelacaoPlanoCliente(objContracaoPostType);            
  }

  const handleContratacaoEdit = (result) => {
    console.log('result ', result);    
  }

  const atualizarResultGridAtividade = (value : IHorariosTurmaGetType[]) => {

    if (value !== null) {
      const objInfo = {
        nomeAluno: alunoName,
        turmaId: value[0].turmaId,
        nomeTurma: "",
        horarios: value,                
        clienteId : alunoId,        
        nomeCliente : alunoName, 
        horariosIds: value.map((item) => item.horaId),                                 
      }   
      
      console.log('objInfo ', objInfo);      

      setAlunoTurma((prevAlunoTurma) => {
        if (!prevAlunoTurma) {          
          return [objInfo];
        }  
        
        const turmaExistente = prevAlunoTurma.find((aluno) => aluno.turmaId === objInfo.turmaId);

        if (turmaExistente) {          
          const novoAlunoTurma = prevAlunoTurma.map((aluno) =>
            aluno.turmaId === objInfo.turmaId ? { ...aluno, horarios: objInfo.horarios } : aluno
          );
          return novoAlunoTurma;
        } else {          
          return [...prevAlunoTurma, objInfo];
        }                

      });
      
      setResultGridAtividadeData(value);
    }
  }

  const renderAtividades = (idAtividade: string, idTurma: string) => {             
    if (idAtividade !== ''){      
      return (
         <GridAtividadesSelecao           
            id={idTurma} 
            idAtividades={idAtividade}
            callBackAtividadesSelecionadas={atualizarResultGridAtividade}                     
            typeCadastroTurma={false}
         />   
      );
    }
  }

  React.useEffect(() => {
    console.log('INFO ALUNO TURMA ', alunoTurma);    
  }, [alunoTurma])

  React.useEffect(() => {   
    if(obj !== null) {
      setObjResumoContratacao((prevArray) => [...prevArray, obj]);
    } 
  }, [obj])

  React.useEffect(() => {    
    console.log('objResumoContratacao obj', JSON.stringify(objResumoContratacao));
    console.log('objResumoContratacao stringfy', JSON.stringify(objResumoContratacao));    
    
  }, [objResumoContratacao])


  const callBacKReturn = (result: IResultDTOContratacao) => {
    onRetornoSalvar(result);  
  }

  React.useEffect(() => {       
    if (id != null && id != ''){      
      getById(id.replace(/"/g, ''))
        .then((result) => {                
          handleContratacaoEdit(result);
        })      
    }    
  }, [])  

  return(    
    <form onSubmit={(event) => {handleSubmitContratacao(event)}}>                            
      <Grid container spacing={2} p={4}>
        {isModalOpen && (
          <MDSearch
            isOpen={isModalOpen}
            onClose={handleModalClose}                              
            infoIdTitular={handleIdTitular}
            infoNameTitular={handleNameTitular}
          />   
        )}
        <Grid item xs={6} sm={8}>
          <TextField
            label="Cliente"
            name="Cliente"
            value={titularName}              
            fullWidth                              
            disabled
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} mt={0} style={{display: 'flex', flexDirection: 'row'}}>
          <Button
            variant="contained" color={'default'}
            onClick={() => {setModalOpen(!isModalOpen)}}
          >
            <SearchIcon fontSize="small"/>  
          </Button> 
          <Button
            style={{marginLeft: 16}}
            variant="contained" color={'default'}
            onClick={handleDeleteTitular}
          >
            <DeleteIcon fontSize="small"/>
          </Button>                               
        </Grid>                     
      </Grid>   
      {titularId !== '' && (
        <Grid container spacing={2} p={4}>
          {isModalOpenPlano && (
            <SearchPlanos 
              isOpen={isModalOpenPlano}
              onClose={handleModalClosePlano}
              infoPlanoSelecionado={callBackInfoPlanoSelecionado}                                             
            /> 
          )}
          <Grid item xs={6} sm={8}>
            <TextField
              label="Plano"
              name="Plano"
              value={planoNome}              
              fullWidth     
              disabled                                       
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} mt={0} style={{display: 'flex', flexDirection: 'row'}} >
            <Button                
                variant="contained" color={'default'}
                onClick={() => {setModalOpenPlano(!isModalOpenPlano)}}
              >
              <SearchIcon fontSize="small"/>  
            </Button>                                
            <Button
              style={{marginLeft: 16}}
              variant="contained" color={'default'}
              onClick={handleDeletePlano}
            >
              <DeleteIcon fontSize="small"/>
            </Button>
          </Grid>  
          <Grid item xs={6} sm={8}>
            <TextField
              label="Aluno"
              name="Aluno"
              value={alunoName}              
              fullWidth                              
              disabled
            />
          </Grid>
          <Grid 
            item xs={1} sm={1} md={1} mt={0} 
            style={{display: 'flex', flexDirection: 'row'}} 
          >
            <Button
              variant="contained" color={'default'}
                onClick={() => {setModalOpenAluno(!isModalOpenAluno)}}
              >
              <SearchIcon fontSize="small"/>  
            </Button>                                
            <Button
              style={{marginLeft: 16}}
              variant="contained" color={'default'}
              onClick={handleDeleteAluno}
            >
              <DeleteIcon fontSize="small"/>
            </Button>
            </Grid>           

            {(alunoId !== '' && objTurmas?.length > 0) ? (
              <>
                <Grid item xl={12}>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" flexDirection={"column"} style={{marginLeft: 8}} p={1}>
                      Turmas Disponíveis                                                                    
                    </MDTypography>                
                  </MDBox> 

                  {objTurmas?.map((data, index) => (
                    <>                      
                      <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleAccordionChange(index)}
                        style={{marginTop: 16, borderRadius: 8}}
                      >
                        <AccordionSummary style={{marginLeft: 8}} expandIcon={<ExpandMoreIcon />}>
                          <MDTypography>{data.descricao}</MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          { renderAtividades(idAtividade, data.id) }
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ))}                      
                </Grid> 
              </>                            
            ):(
              <>
                {alunoId !== '' && (
                  <Grid item xl={12}>
                    <Alert severity="error" style={{marginLeft: 0}}>
                      Nenhuma Turma disponível para o plano
                    </Alert>  
                  </Grid>

                )}
              </>
            )}            
        </Grid>  
      )}
      {planoId !== '' && (
        <Grid container spacing={2} px={4}>
          {isModalOpenAluno && (
            <SearchAlunos
              isOpen={isModalOpenAluno}
              onClose={handleModalCloseAluno}                              
              infoIdTitular={handleIdAluno}
              infoNameTitular={handleNameAluno}
            />   
          )}           
        </Grid>   
      )}

      {alunoId !== '' && (        
        <>  
        <Grid container spacing={2} px={4}>
          {objTurmas?.length > 0 && (
            <Grid item xs={3} sm={3} mt={4}>
              <TextField
                label="Desconto (R$)"
                name="numero"
                value={valorDesconto}
                onChange={(event) => {              
                  const inputValue = event.target.value;
                  const numericValue = inputValue.replace(/[^0-9,]/g, '');
                  console.log('numericValue ', numericValue);                                      
                  setValorDesconto(numericValue);
                }}                            
                type="text"                                       
              />   
            </Grid>
          )}

          {objTurmas?.length > 0 && (
            <Grid item xs={3} sm={3} mt={4}>
              <TextField
                style={{fontSize: 26}}
                label="Valor (R$)"
                name="numero"
                value={valor.toFixed(2)}                                      
                type="text"      
                disabled                                 
              />   
            </Grid>
          )}
          
        </Grid>             
      
        <Grid item xl={12} pt={4} pb={4}>                      
          <Button variant="contained" color="primary" fullWidth
            onClick={() => {handleAdicionarContratacao()}}
          >
            Adicionar
          </Button> 
        </Grid>
        </>

      )}
      {objResumoContratacao.length > 0 && (        
        <CardResumoContratacao
          objResumoContratacao={objResumoContratacao}  
          onRetornoSalvar={callBacKReturn}        
        /> 
      )}
    </form>                             
  )
}


/*

 {objTurmas.map((data, index) => (
                data.horarios.map((result) => (
                  <>
                    {(result.qtdAlunosDisponiveis < result.qtdAlunos) && (
                      <Grid key={data.id} item xs={12} sm={12} md={12} lg={2} xl={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <MDBox style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', marginTop: 8, borderRadius: 8 }}>                                        
                          <ContainerHorariosDias isAzul={false}>
                            <Checkbox />                    
                            <TextTitleHorarios>
                              {result.horarioDe} - {result.horarioAte} |
                            </TextTitleHorarios>
                            <TextDesc>
                                {result.domingo && ' - Domingo' } 
                                {result.segundaFeira && ' - Segunda' } 
                                {result.tercaFeira && ' - Terça' } 
                                {result.quartaFeira && ' - Quarta' } 
                                {result.quintaFeira && ' - Quinta' } 
                                {result.sextaFeira && ' - Sexta' } 
                                {result.sabado && ' - Sabado' } 
                            </TextDesc>
                            <TextTitleHorarios>
                              Qtde. Alunos inscritos :
                            </TextTitleHorarios>  
                            <TextDesc>
                              {result.qtdAlunosDisponiveis}
                            </TextDesc>                      
                          </ContainerHorariosDias>                  
                        </MDBox>                    
                      </Grid>
                    )}
                  </>
                ))
              ))}
*/              





