import BaseService from '../../infrastructure/services';
import  { IContratacaoGetType } from './ContratacaoType';

export const getAll = async (): Promise<IContratacaoGetType[]> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
  };  
  
  const response = await BaseService.get(`/Contratacao/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);
  const responseData: IContratacaoGetType[] = response.data;

  return responseData;
}

export const getByID = async (id) => {          
  const customConfig = {            
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`, 
      },
  };  

  const response = await BaseService.get(`/Cliente/ObterPorId?id=${id}`, customConfig);                    
  return response;
}

export const inativar = async (idContratacao): Promise<boolean> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
    
  };  

  console.log('idContratacao ', idContratacao);
  
  
  const response = await BaseService.put(`/Contratacao/cancelar?id=${idContratacao}`, null, customConfig);
  const responseData: IContratacaoGetType = response.data;

  return responseData.sucesso;
}
