import styled from 'styled-components'

export const DivButton = styled.div`  
  padding: 4px;
  //display: flex;
  //align-items: flex-end;
  //justify-content: flex-end;
`;

export const ContainerGoCadastro = styled.div`    
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 32px;
`;