import { ButtonBase, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MDBox from '../../shared/MDBox';
import MDTypography from '../../shared/MDTypography';
import { IHorariosAtividadeGetType, IHorariosTurmaGetType, IResultDTO, IResultGridAtividadesSelecao } from './GridAtividadesSelecaoType';
import { getTurmaById, getByIdAtividade, VerificaHorariosDisponiveis } from '../../../layouts/cadTurma/CadTurmaService';
import { IResultDTOHorarioDisp } from '../../../layouts/cadTurma/CadTurmaType';

interface GridAtividadesSelecaoProps {  
  id: string;  
  idAtividades: string;
  typeCadastroTurma: boolean;
  callBackAtividadesSelecionadas : (value: IHorariosTurmaGetType[]) => void   
} 

export default function GridAtividadesSelecao({ id, idAtividades, typeCadastroTurma, callBackAtividadesSelecionadas} : GridAtividadesSelecaoProps){
  const [horarioSelecionados, setHorariosSelecionados] = React.useState<IHorariosTurmaGetType[]>(null);    
  const [resultAtividade, setResultAtividade] = useState([]);  
  const [resultHorariosDispCadatro, setResultHorariosDispCadastro] = useState([]);

  const handleSelectHorario = (horario: any) => {
    setHorariosSelecionados((horariosAnteriores) => {                  
      const horariosAnterioresValidos = horariosAnteriores || [];  
      
      const horarioExiste = horariosAnterioresValidos.some(h => h.id === horario.id);
  
      if (horarioExiste) {  
        console.log('horariosAnterioresValidos maior ', horariosAnterioresValidos);
             
        callBackAtividadesSelecionadas(horariosAnterioresValidos.filter(h => h.id !== horario.id))
        return horariosAnterioresValidos.filter(h => h.id !== horario.id);
      } else {   
        console.log('horariosAnterioresValidos maior que 0 ', horariosAnterioresValidos.length > 0);   
        callBackAtividadesSelecionadas([...horariosAnterioresValidos, horario]);
        return [...horariosAnterioresValidos, horario];
      }
    });
  }; 

  const handleSelectHorarioEdit = (horario: any) => {    
    
    if (horarioSelecionados === null) {   
      console.log('horariosAnterioresValidos maior que 0 ', horario.length > 0);   
      callBackAtividadesSelecionadas(horario);
      setHorariosSelecionados(horario)      
    }else {      
      const novoArray = [...horarioSelecionados, horario];  
      console.log('horariosAnterioresValidos maior que 0 ', novoArray.length > 0);   
      callBackAtividadesSelecionadas(novoArray);
      setHorariosSelecionados(novoArray);      
    }
  }

  const handleFiltroHorariosSelecionadosEditar = (atividadesCadastradasTurma: IResultDTO) => {        
    const arrAtividadeSelecionada = [];    
  
    atividadesCadastradasTurma.data.forEach(turma => {
      turma.horarios.forEach(horarioTurma => {         
               
        const horarioAtividadeCorrespondente = turma.horariosAtividade.find(horarioAtividade => horarioAtividade.id === horarioTurma.horaId);
  
        if (horarioAtividadeCorrespondente) {  
          arrAtividadeSelecionada.push(horarioAtividadeCorrespondente);
        }
      });
    });            
    
    const arrAtividadeSelecionadaOrdenado = arrAtividadeSelecionada.sort((a, b) => {
      const dateA = new Date(`2000-01-01T${a.horarioDe}`).getTime();
      const dateB = new Date(`2000-01-01T${b.horarioDe}`).getTime();
      return dateA - dateB;
    });  
    
    handleSelectHorarioEdit(arrAtividadeSelecionadaOrdenado);
  };

  async function processResults(resultDTO : IResultDTO) {  
    console.log('ENTROU processResults');
          
    await Promise.all(resultDTO.data.map(async (result) => {
      await Promise.all(result.horariosAtividade.map(async (result) => {
        setResultAtividade(existingList => {
          const horariosToAdd = result || [];
          const combinedList = [...existingList, horariosToAdd];          

          const sortedList = combinedList.sort((a, b) => {
            const timeA = new Date(`1970-01-01T${String(a.horarioDe)}`);
            const timeB = new Date(`1970-01-01T${String(b.horarioDe)}`);
        
            return timeA.getTime() - timeB.getTime();
          });
        
          return sortedList;
        });
      }));
  
      handleFiltroHorariosSelecionadosEditar(resultDTO);      
    }));      
  }

  async function getHorariosTurma(idAtividades : string, horariosDisponiveis) {

    getByIdAtividade(idAtividades.replace(/['"]/g, ''))
      .then((result) => {          
        
        /*const horarioDisponivelData = [];

        const resultOrderByData = result?.horarios.sort((a, b) => {
          const dateA = new Date(`2000-01-01T${a.horarioDe}`).getTime();
          const dateB = new Date(`2000-01-01T${b.horarioDe}`).getTime();
          
          return dateA - dateB;
        });
        
        
        resultOrderByData.map((horario) => {
          if (horariosDisponiveis.data.includes(horario.id)){
            horarioDisponivelData.push(horario);
          }
        })*/        

        setResultAtividade(result?.horarios);                  

      })
      .catch((error) => {
        console.error('Erro ao obter atividade:', error);        
      });  
  }  

  const handleExibeTurmaDisponivel = async (horarios: IHorariosTurmaGetType[]) => {    
    await Promise.all(horarios.map(async (horario) => {                              
      if (horario.qtdAlunos > horario.qtdAlunosDisponiveis) {          
        setResultAtividade((prevArray) => [...prevArray, horario]);
      }
    }));    
  }

  const handleVerificaHorariosDisponivelCadastro = async (atividadeId: string) => {
    await VerificaHorariosDisponiveis(atividadeId)
      .then((result) => {  
        console.log('RESULT ', result);
        
        getHorariosTurma(atividadeId, result);
                      
        setResultHorariosDispCadastro(result);
      })
      
  } 

  React.useEffect(() => {       
    if (id != null && id != ''){  
      if (typeCadastroTurma == false){
        getTurmaById(id.replace(/"/g, ''))
          .then((result) => {  
            handleExibeTurmaDisponivel(result.data[0].horarios)                        
          })      

      } else {
        getTurmaById(id.replace(/"/g, ''))
          .then(async (result) => {             
            processResults(result);          
          })      
      }  
    } else {  
      handleVerificaHorariosDisponivelCadastro(idAtividades);
    }    
  }, [])  
  

  React.useEffect(() => {  
    console.log('resultHorariosDispCadatro ', resultHorariosDispCadatro);
       
  
  }, [resultHorariosDispCadatro]) 

  React.useEffect(() => { 
    console.log('horarioSelecionados ', horarioSelecionados);
     
    callBackAtividadesSelecionadas(horarioSelecionados);    
  
  }, [horarioSelecionados])            

  return (
    <Grid item xs={12} sm={12}>                                                          
      <MDBox>  
        {resultAtividade?.map((horario, index) => (
          <ButtonBase 
            key={index}
            style={{ 
              marginTop: 4,
              marginLeft: 4,
              borderRadius: 8,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
              padding: 4,
              backgroundColor: horarioSelecionados && horarioSelecionados.some(h => h.id === horario.id) ? '#bae5b5' : 'white'
            }}
            onClick={() => {handleSelectHorario(horario)}}
          >
            <Grid item xl={12} xs={12} sm={12} style={{marginTop: 8}}>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                <MDTypography style={{marginLeft: 16, marginRight: 16, fontSize: 16, fontWeight: 700}}>
                  {`${horario.horarioDe} ${horario.horarioAte}`}
                </MDTypography>                      
              </Grid>  
              <Grid item xs={12} sm={6} md={2} lg={2} xl={4} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>                           
                <MDTypography style={{display: 'flex', flexDirection: 'row', marginLeft: 16, marginRight: 16, fontSize: 14, marginTop: 16}}>
                  {horario.domingo && ' Domingo' } 
                  {horario.segundaFeira && ' Segunda' } 
                  {horario.tercaFeira && ' Terça' } 
                  {horario.quartaFeira && ' Quarta' } 
                  {horario.quintaFeira && ' Quinta' } 
                  {horario.sextaFeira && ' Sexta' } 
                  {horario.sabado && ' Sabado' } 
                </MDTypography>                                                
              </Grid>                                                                                                                
            </Grid> 
            <Grid item xl={12} xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
            </Grid>
          </ButtonBase>
        ))}
      </MDBox>           
    </Grid>
  );
}