/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import React, { useEffect, useState } from "react";

import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import IconInativa from '@mui/icons-material/PersonRemove';
import IconEdit from '@mui/icons-material/Edit';
import clienteService from '../../../services/ClienteService';
import baseService from "../../../services/BaseService";
import BaseService from "../../../services/BaseService";
import FuncionarioService from "services/FuncionarioService";
import { isExpired } from "react-jwt";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

export default function data(valueSearch, typeBotton, objFull) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowsInfo, setRowsInfo] = useState([]);
  const [columns, setColummns] = useState([]);
  const [objListRender, setObjListRender] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [dataReturn, setDataReturn] = useState([]);
  

  const [teste, setTeste] = useState(valueSearch);
  
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Step 3
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );  

    
  const handleFilterData = (search, infoReturn) => {    
    let newInfoReturn = infoReturn.filter(x => x.nome.toLowerCase().includes(search.toLowerCase()));    
    if (newInfoReturn.length === 0){
      newInfoReturn = infoReturn.filter(x => x.email.toLowerCase().includes(search.toLowerCase()));    
      if (newInfoReturn.length === 0){
        newInfoReturn = infoReturn.filter(x => x.telefoneCelular.includes(search.toLowerCase()));      
      }
    }   
    objFull(newInfoReturn); 
    handlePrepareRows(newInfoReturn);
  }
  
  const handlePrepareRows = (infoReturn) => {
    let arrayRows = [];
    infoReturn.map((result) => {
      let itemObj = {
        funcionario:  (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.nome}
          </MDTypography>
        ),                
        email: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.email}
          </MDTypography>
        ),
        telefone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {result.telefoneCelular}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            {result.ativo ? (
              <PersonRoundedIcon fontSize="medium" style={{color: 'green'}}/>              
            ):(
              <PersonRoundedIcon fontSize="medium" style={{color: 'red'}}/>              
            )}
            
          </MDBox>
        ),
        action: (
          <>     
            {window.innerWidth < 768 ?
            (
              <MDBox>
                <MDButton color={'info'} circular={true} size={'small'} iconOnly={true}
                  onClick={() => {typeBotton('Editar')}}
                >                
                    <IconEdit />
                </MDButton>            
                <div style={{marginTop: 5}} />
                <MDButton color={'info'} circular={true} size={'small'} ml={1}
                  iconOnly={true}
                  onClick={() => {typeBotton('Inativar')}}
                >                
                    <IconInativa size={'large'} />            
                </MDButton>
              </MDBox> 
            ):(
              <MDBox>
                <Tooltip title="Clique para editar" arrow placement="left">
                  <MDButton color={'info'} circular={true} size={'small'} 
                    onClick={() => {typeBotton('Editar')}}
                  >                
                      <IconEdit />          
                  </MDButton>            
                </Tooltip>
                <div style={{marginTop: 5}} />
                <Tooltip title="Clique para inativar" arrow placement="left">
                  <MDButton color={'info'} circular={true} size={'small'} ml={1}                  
                    onClick={() => {typeBotton('Inativar')}}
                  >                
                      <IconInativa />            
                  </MDButton>
                </Tooltip>
              </MDBox> 
            )
            }
           
          </>
        ),
      }

      arrayRows.push(itemObj);
    })

    setRowsInfo(arrayRows);
  }

  const handlePrepareColumms = () => {
    var columns = [];

    if (window.innerWidth < 768){
      columns = [
        { Header: "funcionario", accessor: "funcionario", width: "10%", align: "left" },                           
        { Header: "Ação", accessor: "action", align: "center" }
      ]      
    }else{      
      columns = [
        { Header: "funcionario", accessor: "funcionario", width: "20%", align: "left" },           
        { Header: "e-mail", accessor: "email", align: "left" },
        { Header: "telefone", accessor: "telefone", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "Ação", accessor: "action", align: "center" }
      ]
    }
    
    setColummns(columns);
  }

  useEffect(() => {     
    if (dataReturn.length > 0)  {
      handlePrepareRows(dataReturn);
      handlePrepareColumms();
    }    
  }, [dataReturn])

  function updateTokenInHeaders(newToken) {
    baseService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  }


  useEffect(async () => {    
    var isExpiredInfo = isExpired(localStorage.getItem('token'));
    if (!isExpiredInfo){
      await FuncionarioService.getAll().then((result) => {        
        setDataReturn(result.data); 
        objFull(result.data);  
      }).catch((error) => {      
        
      })
    }

    
  }, [])

  useEffect(() => {    
    setTimeout(() => {  
      if (valueSearch != null){
        handleFilterData(valueSearch, dataReturn);
      }             
    }, 2000);   
    
  }, [valueSearch]) 


  return {
    columns: columns,

    rows: rowsInfo
  };
}
