import { IContratacaoGetType } from '../../layouts/contratacao/ContratacaoType';
import BaseService from '../../infrastructure/services';
import  { IContratacaoPostType, IResultDTO, IResultDTOContratacao, ITurmaGetType } from './CadContratacaoType';

export const Cadastrar = async (obj : IContratacaoPostType): Promise<IResultDTOContratacao> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },
    body: obj
  };  
  
  const response = await BaseService.post(`/Contratacao/Cadastrar`, obj, customConfig);
  console.log('response.data ', response.data);  
  const responseData: IResultDTOContratacao = response.data;

  return responseData;
}

export const getTurmasByAtividadesId = async (atividadeId: string): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },    
  };  
  
  const response = await BaseService.get(`/Turma/ObterPorAtividadeId?atividadeId=${atividadeId}`, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}


export const getById = async (Id: string): Promise<IResultDTO> => {
  const customConfig = {            
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
    },    
  };  
  
  const response = await BaseService.get(`/Contratacao/ObterPorId?id=${Id}`, customConfig);
  const responseData: IResultDTO = response.data;

  return responseData;
}
