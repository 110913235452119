/* eslint-disable no-empty */
import { isExpired, useJwt } from "react-jwt"
import baseService from "../services/BaseService"
import authService from "./authService";

const FuncionarioService = {
    getAll: async () => {            
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  
        
      const response = await baseService.get(`/Funcionario/ObterTodos?pageNumber=1&pageSize=1000`, customConfig);                                
      return response;
    },
    getByID: async (id) => {  
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Funcionario/ObterPorId?id=${id}`, customConfig);                    
        return response;
    },   
    getByCPF: async (cpf) => {             
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Funcionario/ObterPorCNPJ?cnpj=${cpf}`, customConfig);                    
        return response;       
    },
    getByName: async (name) => {              
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  

        const response = await baseService.get(`/Funcionario/ObterPorCPF?nome=${name}`, customConfig);                    
        return response;       
    },    
    putFuncionario: async (obj, id) => {        
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  
        
        const response = await baseService.put(`/Funcionario/Atualizar/${id}`, obj, customConfig);                    
        return response;             
    },
    postFuncionario: async (obj) => {            
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };          
        
        try {
          const response = await baseService.post(`/Funcionario/Cadastrar`, obj, customConfig);                    
          return response;  
        } catch (error) {
          console.log('erro', error);  
          return error;        
        }  
                                
          
         
        
    },
    postInativeFuncionario: async (id) => {              
        const customConfig = {            
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
        };  
        
        try {
            const response = await baseService.put(`/Funcionario/Inativar/${id}`, customConfig);                        
            return response;         
        } catch (error) {                      
        }
        
        
    },
    sendEmail: async (email) => {
      const customConfig = {            
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        },        
      };  

      try {
        const response = await baseService.post(`/Funcionario/EnviarEmailRelembrarSenha?email=${email}`, customConfig);                        
        return response;         
      } catch (error) {                      
      }

    }
  };
  
  export default FuncionarioService;