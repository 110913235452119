/* eslint-disable no-async-promise-executor */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import Grid from "@mui/material/Grid";
import clienteService from '../../../services/ClienteService'; 

export function MDSearch({ isOpen, onClose, infoIdTitular, infoNameTitular }) {
  const autocompleteRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [callClienteLoad, setCallClienteLoad] = useState(false);

  const handleSearch = async () => {       
      await getInfoCombo(searchText).then(async (result) =>{                          
      await clienteService.getByCpf(result).then((resultClient) => {                    
        infoIdTitular(resultClient.data.id);        
        console.log('resultClient.data.id ', resultClient.data.id);  
        infoNameTitular(resultClient.data.nomeCliente);
        console.log('resultClient.data.nomeCliente ', resultClient.data.nomeCliente);  
        onClose();
      })
    }).catch((error) => {
      setSearchText('');
      infoIdTitular('');
      onClose();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch((error) => {})
  };


  const handleCallClientes = async () => {    
    return new Promise(async (resolve, reject) => {
      await clienteService.getAll().then((result) => {  
        console.log('result.data ', result.data);
                
        setData(result.data);      
        const arrayInfoTitular = [];
        result.data.map((result, index) => {        
          arrayInfoTitular.push(`${result.cpf} - ${result.nomeCliente}`)
        })
        setCallClienteLoad(true);
        resolve(arrayInfoTitular);
      })      
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch((error) => {      
    })        
  }    

  useEffect(() => {
    let isMounted = true;
  
    if (autocompleteRef.current) {
      autocompleteRef.current.querySelector('#autocom').click();
    }
  
    handleCallClientes()
      .then((result: []) => {
        if (isMounted) {
          const cpfRegex = /\d{3}\.\d{3}\.\d{3}-\d{2}/;
          const cpfs = result?.filter(item => cpfRegex.test(item));
          setOptions(cpfs);
          setShowInput(true);
        }
      })
      .catch(erro => {
        if (isMounted) {
          setShowInput(false);
        }
      });  
    
    return () => {
      isMounted = false;
    };
  }, []);

  function getInfoCombo(infoSelected){          
    return new Promise(async (resolve, reject) => {
      try {                       

        const parts = infoSelected.split('-');
        if (parts.length > 0) {
            const cpf = `${parts[0].trim()}-${parts[1].trim()}`;            
            resolve(cpf);
        }else{
          reject([]);
        }
      } catch (error) {
        reject([]);
      }
    })

    
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Consulta Titular</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Filtre por código ou nome do Títular
        </DialogContentText>
        
          <div style={{width: "500px", marginTop:"14px"}}>
          </div>
            {callClienteLoad && (
              <Autocomplete
                id='autocom'                
                ref={autocompleteRef}
                value={searchText}
                onChange={(event, newValue) => {setSearchText(newValue ? newValue : '')}}            
                options={options ? options : []}
                renderInput={(params) => <TextField {...params} label="Name" id="autocom" />}
              />
            )}
          
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSearch} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}