/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import React, { useEffect, useState } from "react";

import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import IconInativa from '@mui/icons-material/PersonRemove';
import IconEdit from '@mui/icons-material/Edit';
import clienteService from '../../../services/ClienteService';
import baseService from "../../../services/BaseService";
import BaseService from "../../../services/BaseService";
import FuncionarioService from "services/FuncionarioService";
import { isExpired } from "react-jwt";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PlanoService from "services/PlanoService";

export default function data(valueSearch, typeBotton, objFull) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowsInfo, setRowsInfo] = useState([]);
  const [columns, setColummns] = useState([]);
  const [objListRender, setObjListRender] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [dataReturn, setDataReturn] = useState([]);
  

  const [teste, setTeste] = useState(valueSearch);
  
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Step 3
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );  

    
  const handleFilterData = (search, infoReturn) => {    
    let newInfoReturn = infoReturn.filter(x => x.descricao.toLowerCase().includes(search.toLowerCase()));            
    objFull(newInfoReturn); 
    handlePrepareRows(newInfoReturn);
  }
  
  const handlePrepareRows = (infoReturn) => {
    let arrayRows = [];
    //descricao, frequenciaDias, duracao, valor, ativo
    infoReturn.map((result) => {
      let itemObj = {
        descricao:  (
          <>
            {result !== null && (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {result.descricao}
              </MDTypography>
            )}
          </>
        ),                
        frequenciaDias: (
          <>
            {result !== null && (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {result.frequenciaDias}
              </MDTypography>
            )}
          </>
        ),
        duracao: (
          <>
            {result !== null && (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {result.duracao}
              </MDTypography>
            )}
          </>
        ),
        valor: (
          <>
          {result !== null && (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {result.valor.toFixed(2).replace('.', ',')}
            </MDTypography>
          )}
          </>
        ),
        status: (
          <>
          {result !== null && (
            <MDBox ml={-1}>
              {result.ativo ? (
                <PersonRoundedIcon fontSize="medium" style={{color: 'green'}}/>              
              ):(
                <PersonRoundedIcon fontSize="medium" style={{color: 'red'}}/>              
              )}
              
            </MDBox>            
          )}
          </>
        ),
        action: (
          <>  
            {result !== null && (
              <>
                {window.innerWidth < 768 ?
                (
                  <MDBox>
                    <MDButton color={'info'} circular={true} size={'small'} iconOnly={true}
                      onClick={() => {typeBotton('Editar')}}
                    >                
                        <IconEdit />
                    </MDButton>            
                    <div style={{marginTop: 5}} />
                    <MDButton color={'info'} circular={true} size={'small'} ml={1}
                      iconOnly={true}
                      onClick={() => {typeBotton('Inativar')}}
                    >                
                        <IconInativa size={'large'} />            
                    </MDButton>
                  </MDBox> 
                ):(
                  <MDBox>
                    <Tooltip title="Clique para editar" arrow placement="left">
                      <MDButton color={'info'} circular={true} size={'small'} 
                        onClick={() => {typeBotton('Editar')}}
                      >                
                          <IconEdit />          
                      </MDButton>            
                    </Tooltip>
                    <div style={{marginTop: 5}} />
                    <Tooltip title="Clique para inativar" arrow placement="left">
                      <MDButton color={'info'} circular={true} size={'small'} ml={1}                  
                        onClick={() => {typeBotton('Inativar')}}
                      >                
                        <IconInativa />            
                      </MDButton>
                    </Tooltip>
                  </MDBox> 
                )}
              </>
            )}   
           
          </>
        ),
      }

      arrayRows.push(itemObj);
    })

    setRowsInfo(arrayRows);
  }

  const handlePrepareColumms = () => {
    var columns = [];

    if (window.innerWidth < 768){
      columns = [
        { Header: "descricao", accessor: "descricao", width: "10%", align: "left" },                           
        { Header: "valor", accessor: "valor", align: "center" }
      ]      
    }else{      
      columns = [
        { Header: "descricao", accessor: "descricao", width: "20%", align: "left" },           
        { Header: "frequenciaDias", accessor: "frequenciaDias", align: "left" },
        { Header: "duracao", accessor: "duracao", align: "left" },
        { Header: "valor", accessor: "valor", align: "center" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "Ação", accessor: "action", align: "center" }
      ]
    }

    //descricao, frequenciaDias, duracao, valor, ativo
    
    setColummns(columns);
  }

  useEffect(() => {     
    if (dataReturn.length > 0)  {
      handlePrepareRows(dataReturn);
      handlePrepareColumms();
    }    
  }, [dataReturn])

  const handleGetClientes = async () => {


  }

  function updateTokenInHeaders(newToken) {
    baseService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  }


  useEffect(async () => {    
    var isExpiredInfo = isExpired(localStorage.getItem('token'));
    if (!isExpiredInfo){
      await PlanoService.getAll().then((result) => {  
        console.log('result plano service ', JSON.stringify(result));      
        setDataReturn(result.data); 
        objFull(result.data);  
      }).catch((error) => {      

      })
    }

    
  }, [])

  useEffect(() => {    
    setTimeout(() => {  
      if (valueSearch != null){
        handleFilterData(valueSearch, dataReturn);
      }             
    }, 2000);   
    
  }, [valueSearch])


  /* 
    {
        cliente: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,        
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="ativo" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        datacadastro: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        action: (
          <>
          <div>
            <IconButton
              aria-label="Menu"
              aria-controls="three-dots-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="three-dots-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose} style={{fontWeight: "700", fontSize: "14px"}}>Editar</MenuItem>
              <MenuItem onClick={handleMenuClose} style={{fontWeight: "700", fontSize: "14px"}}>Planos</MenuItem>
              <MenuItem onClick={handleMenuClose} style={{fontWeight: "700", fontSize: "14px"}}>Alunos Vinculados</MenuItem>
              <MenuItem onClick={handleMenuClose} style={{fontWeight: "700", fontSize: "14px"}}>Deletar</MenuItem>
            </Menu>
          </div>

          </>
        ),
      },

  */    


  return {
    columns: columns,

    rows: rowsInfo
  };
}
